<template>
  <b-alert v-if="discountRules.length" show>
    <!-- Show discount rules if any are available -->
    Există <strong>{{discountRules.length}} reguli de discount</strong> care se pot aplica pentru această comandă! <b-link @click="toggleDiscountRules()"><span v-if="discountRulesVisible">[ ascunde ]</span><span v-else>[ vezi ]</span></b-link>
    <b-collapse v-model="discountRulesVisible" id="collapse-client-order-discount-rules-alert-contents">
      <b-table-simple
        small
        responsive
        borderless
        class="mb-0">
        <b-thead>
          <b-tr>
            <b-th>Cod discount</b-th>
            <b-th>Tip</b-th>
            <b-th>Prag</b-th>
            <b-th>Tip campanie</b-th>
            <b-th>Data sfârșit</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="discountRule in discountRules" v-bind:key="discountRule.RegulaDiscountID">
            <b-td class="py-0">{{ discountRule.CodRegulaDiscount }}</b-td>
            <b-td class="py-0">
              <span v-if="discountRule.TipDiscount === 'PCT'">(p) <strong>{{ discountRule.ValoareDiscount | displayAsPercentage }}</strong></span>
              <span v-else-if="discountRule.TipDiscount === 'VAL'">(v) <strong>{{ discountRule.ValoareDiscount | displayAsDecimal(2) }} RON</strong></span>
              <span v-else-if="discountRule.TipDiscount === 'QTY'">(c) <strong>{{ discountRule.ValoareDiscount | displayAsDecimal(2) }} unități</strong></span>
            </b-td>
            <b-td class="py-0">
              <span v-if="discountRule.TipPragAplicare !== 'NUL'">
                <span v-if="discountRule.TipPragAplicare === 'QTY'">(c) min. <strong>{{ discountRule.ValoarePragAplicare | displayAsDecimal(2) }} unități</strong></span>
                <span v-else-if="discountRule.TipPragAplicare === 'VAL'">(v) min. <strong>{{ discountRule.ValoarePragAplicare | displayAsDecimal(2) }} RON</strong></span>
              </span>
              <span v-else>
                fără
              </span>
            </b-td>
            <b-td class="py-0">
              pentru<span v-if="discountRule.CodClient">&nbsp;acest client</span>
              <span v-if="discountRule.CategorieProdusID">&nbsp;la categoria de produse <strong>{{ discountRule.DenumireCategorieProdus }}</strong></span>
              <span v-if="discountRule.CodProdus">&nbsp;la produsul <strong>{{ discountRule.CodProdus }}</strong>
                <b-button
                  variant="link"
                  size="sm"
                  class="ml-1"
                  v-b-tooltip.hover="'Copiază codul de produs'"
                  v-clipboard:success="clipboardSuccessHandler"
                  v-clipboard:error="clipboardErrorHandler"
                  v-clipboard="() => discountRule.CodProdus"><i class="far fa-copy" />
                </b-button>
                <br/>
                <em>{{ discountRule.DenumireProdus }}</em>
              </span>
            </b-td>
            <b-td class="py-0">
              {{ discountRule.DataSfarsitAplicare | displayAsDate }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-collapse>
  </b-alert>
</template>

<script>
import BigNumber from 'bignumber.js';

import toasts from '@/services/toasts';

const { DateTime } = require('luxon');

export default {
  name: 'ClientOrderDiscountRulesAlert',
  props: ['discountRules'],
  data() {
    return {
      discountRulesVisible: false,
    };
  },
  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
    displayAsPercentage(value, decimals = 0) {
      return `${new BigNumber(value).times(100).decimalPlaces(decimals).toString()} %`;
    },
  },
  methods: {
    toggleDiscountRules() {
      this.discountRulesVisible = !this.discountRulesVisible;
    },

    clipboardSuccessHandler() {
      toasts.success('Textul a fost copiat');
    },

    clipboardErrorHandler() {
      toasts.error('Textul nu a putut fi copiat');
    },
  },
};
</script>
