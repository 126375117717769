<template>
  <b-container fluid>
    <div v-if="isLoaded && clientOrderDetails">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>Vizualizare comandă</h2>
        <b-button-toolbar class="pb-3">
          <b-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="lastRoute || { name: 'clientOrder' }"><i class="fas fa-caret-left"/><span class="d-none d-md-inline ml-1">Înapoi</span></b-button>

          <b-dropdown class="mx-1" toggle-class="p-2" v-if="clientOrderDetails.statusCode !== ClientOrderStatus.CANCELED">
            <template v-slot:button-content>
              <i class="fas fa-list-alt"/><span class="d-none d-md-inline ml-1">Formular comandă</span>
            </template>
            <b-dropdown-item id="downloadPdfToolbarButton" :href="pdfDownloadLink">
              <i class="fas fa-file-pdf mr-1"/>Format PDF
            </b-dropdown-item>
            <b-dropdown-item id="downloadXlsxToolbarButton" :href="xlsxDownloadLink">
              <i class="fas fa-file-excel mr-1"/>Format Excel
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown class="mx-1" toggle-class="p-2" v-if="clientOrderDetails.statusCode !== ClientOrderStatus.CANCELED && clientOrderDetails.statusCode !== ClientOrderStatus.SUBMITTED">
            <template v-slot:button-content>
              <i class="fas fa-clipboard-list"/><span class="d-none d-md-inline ml-1">Formular magazie</span>
            </template>
            <b-dropdown-item-button id="downloadInventoryFormPdfToolbarButton" @click="validateInventoryFormDownload('pdf')">
              <i class="fas fa-file-pdf mr-1"/>Format PDF
            </b-dropdown-item-button>
            <b-dropdown-item-button id="downloadInventoryFormToolbarButton" @click="validateInventoryFormDownload('xlsx')">
              <i class="fas fa-file-excel mr-1"/>Format Excel
            </b-dropdown-item-button>
          </b-dropdown>

          <b-button-group class="mx-1" v-if="canCreateClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.SUBMITTED">
            <b-button class="p-2" id="rollBackSubmittedClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="rollBackSubmittedClientOrder" variant="secondary"><i class="fas fa-undo-alt"/><span class="d-none d-md-inline">&nbsp;Redeschide<span class="d-none d-xxl-inline">&nbsp;pentru editare</span></span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canPrepareClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.SUBMITTED">
            <b-button class="p-2" id="beginPreparingClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="beginPreparingClientOrder" variant="primary"><i class="fas fa-dolly-flatbed"/><span class="d-none d-md-inline">&nbsp;Preia<span class="d-none d-xxl-inline">&nbsp;pentru culegere</span></span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canPrepareClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.PREPARING">
            <b-button class="p-2" id="rollBackPreparingClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="rollBackPreparingClientOrder" variant="secondary"><i class="fas fa-undo-alt"/><span class="d-none d-md-inline">&nbsp;Redeschide<span class="d-none d-xxl-inline">&nbsp;pentru trimitere</span></span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canPrepareClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.PREPARING">
            <b-button class="p-2" id="finishPreparingClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="finishPreparingClientOrder" variant="primary"><i class="fas fa-check"/><span class="d-none d-md-inline">&nbsp;Încheie<span class="d-none d-xxl-inline">&nbsp;culegerea produselor</span></span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canPrepareClientOrder && (!invoices || invoices.every((inv) => inv.isCanceled))&& clientOrderDetails.statusCode === ClientOrderStatus.PREPARED">
            <b-button class="p-2" id="rollBackPreparedClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="rollBackPreparedClientOrder" variant="secondary"><i class="fas fa-undo-alt"/><span class="d-none d-md-inline">&nbsp;Redeschide<span class="d-none d-xxl-inline">&nbsp;culegerea produselor</span></span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canIssueClientInvoice && (!invoices || invoices.every((inv) => inv.isCanceled)) && clientOrderDetails.statusCode === ClientOrderStatus.PREPARED">
            <b-button class="p-2" id="issueInvoiceForClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="issueInvoiceForClientOrder" variant="primary"><i class="fas fa-file-invoice-dollar"/><span class="d-none d-md-inline ml-2">Emite&nbsp;factură</span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canIssueClientDeliveryNote && (!invoices || invoices.every((inv) => inv.isCanceled)) && clientOrderDetails.statusCode === ClientOrderStatus.PREPARED">
            <b-button class="p-2" id="deliveryNoteForClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="issueDeliveryNoteForClientOrder" variant="primary"><i class="fas fa-file-invoice"/><span class="d-none d-md-inline ml-2">Emite&nbsp;aviz</span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canCompleteClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.PREPARED && invoices && invoices.length">
            <b-button class="p-2" id="completeClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="completeClientOrder" variant="primary"><i class="fas fa-check-double"/><span class="d-none d-md-inline">&nbsp;Finalizează</span></b-button>
          </b-button-group>

          <b-button-group class="mx-1" v-if="canReopenClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.COMPLETED">
            <b-button class="p-2" id="reopenClientOrderToolbarButton" :disabled="xhrRequestRunning" @click="reopenClientOrder" variant="primary"><i class="fas fa-folder-open"/><span class="d-none d-md-inline">&nbsp;Redeschide</span></b-button>
          </b-button-group>

          <b-button
            class="mx-1 p-2"
            id="cancelOrderToolbarButton"
            :disabled="xhrRequestRunning"
            @click="cancelClientOrder"
            variant="danger"
            v-if="clientOrderDetails.statusCode !== ClientOrderStatus.CANCELED && clientOrderDetails.statusCode !== ClientOrderStatus.PREPARED && clientOrderDetails.statusCode !== ClientOrderStatus.COMPLETED && (!invoices || invoices.every((inv) => inv.isCanceled))"
          >
            <i class="fas fa-times"/><span class="d-none d-md-inline">&nbsp;Anulează<span class="d-none d-xxl-inline">&nbsp;comanda</span></span>
          </b-button>
        </b-button-toolbar>

        <b-tooltip target="navigateBackToolbarButton" placement="topleft" custom-class="d-md-none">Înapoi</b-tooltip>
        <b-tooltip target="rollBackSubmittedClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Redeschide pentru editare</b-tooltip>
        <b-tooltip target="beginPreparingClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Preia pentru culegere</b-tooltip>
        <b-tooltip target="finishPreparingClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Încheie culegerea produselor</b-tooltip>
        <b-tooltip target="rollBackPreparedClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Redeschide culegerea produselor</b-tooltip>
        <b-tooltip target="issueInvoiceForClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Emite factură</b-tooltip>
        <b-tooltip target="deliveryNoteForClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Emite aviz</b-tooltip>
        <b-tooltip target="completeClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Finalizează comanda</b-tooltip>
        <b-tooltip target="reopenClientOrderToolbarButton" placement="topleft" custom-class="d-md-none">Redeschide comanda</b-tooltip>
        <b-tooltip target="cancelOrderToolbarButton" placement="topleft" custom-class="d-md-none">Anulează comanda</b-tooltip>
      </div>

      <!-- Order details card -->
      <b-card>
        <div slot="header">
          Detalii comandă <strong>{{ clientOrderDetails.clientOrderCode }}</strong>&nbsp;<client-order-status-badge :status-code="clientOrderDetails.statusCode" /> din <strong>{{ clientOrderDetails.createdAt | displayAsDate }}</strong> creată de <user-name-badge :user-name="clientOrderDetails.createdByFullName" />
          <b-button class="mx-1" size="sm" @click="showEventLogModal">
            <i class="fas fa-history mr-1" /><span>Istoric</span>
          </b-button>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <label class="col-12">Client</label>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientName">
              <strong>{{ clientOrderDetails.clientName }}</strong>
              <router-link :to="{ name: 'clientDetails', params: { clientCode: clientOrderDetails.clientCode } }">
                <i class="fas fa-link ml-1 text-smaller" v-b-tooltip.hover.bottom="'Vezi detalii partener'"/>
              </router-link>
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientCode">
              {{clientOrderDetails.clientCode}}
            </blockquote>
            <blockquote class="col-12 blockquote" v-if="clientOrderDetails.clientRegistrationNo">
              {{clientOrderDetails.clientRegistrationNo}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientStreetAddress">
              {{clientOrderDetails.clientStreetAddress}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="actualAddressLocationDisplay">
              {{actualAddressLocationDisplay}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientPostalCode">
              {{clientOrderDetails.clientPostalCode}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientCountryAddress">
              {{clientOrderDetails.clientCountryAddress}}
            </blockquote>
          </div>

          <div class="col-sm-12 col-md-6">
            <label class="col-12">Adresa livrare</label>
            <div v-if="clientOrderDetails.clientDeliveryAddressId">
              <blockquote class="col-12 blockquote mb-0">
                <strong>{{clientOrderDetails.clientDeliveryAddressName}}</strong>
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientDeliveryAddressPartnerInternalCode">
                Cod: {{clientOrderDetails.clientDeliveryAddressPartnerInternalCode}}
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientDeliveryAddress">
                {{clientOrderDetails.clientDeliveryAddress}}
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="actualDeliveryAddressLocationDisplay">
                {{actualDeliveryAddressLocationDisplay}}
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientDeliveryAddressCountryName">
                {{clientOrderDetails.clientDeliveryAddressCountryName}}
              </blockquote>
            </div>
            <div v-else>
              <blockquote class="col-12 blockquote mb-0"> - </blockquote>
            </div>
          </div>
        </div>

        <div class="row" v-if="clientOrderDetails.clientSystemOrderIdentifier">
          <div class="col">
            <label>Cod comandă în sistemul clientului</label>
            <blockquote class="blockquote">{{ clientOrderDetails.clientSystemOrderIdentifier }}</blockquote>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="row" v-if="clientOrderDetails.createdByFullName">
              <b-form-group label="Agent vânzări" class="col-12">
                <user-name-badge :user-name="clientOrderDetails.createdByFullName" />
              </b-form-group>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Observații ag. vânzări</label>
              </div>
              <div class="col-12">
                <blockquote class="blockquote preserve-newlines" v-if="clientOrderDetails.salesRemarks">{{clientOrderDetails.salesRemarks}}</blockquote>
                <blockquote class="blockquote" v-else><em>Fără observații</em></blockquote>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="row" v-if="clientOrderDetails.inventoryHandledByGuid">
              <b-form-group label="Gestionar" class="col-12">
                <user-name-badge :user-name="clientOrderDetails.inventoryHandledByFullName" />
              </b-form-group>
            </div>
            <div class="row">
              <b-form-group label="Observații gestionar" class="col-md-12 col-lg-12" v-if="warehouseRemarksBeingEdited">
                <b-form-textarea v-model="warehouseRemarksUpdateText" @blur="submitWarehouseRemarks"></b-form-textarea>
              </b-form-group>
              <label class="col-md-12 col-lg-12" v-if="!warehouseRemarksBeingEdited">Observații gestionar</label>
              <blockquote class="col-md-12 col-lg-12 blockquote preserve-newlines" v-if="!warehouseRemarksBeingEdited && clientOrderDetails.warehouseRemarks">{{clientOrderDetails.warehouseRemarks}}</blockquote>
              <blockquote class="col-md-12 col-lg-12 blockquote" v-if="!warehouseRemarksBeingEdited && !clientOrderDetails.warehouseRemarks"><em>Fără observații</em></blockquote>
              <div class="col-md-12 col-lg-12 text-center" v-if="clientOrderDetails.statusCode === ClientOrderStatus.PREPARING && canPrepareClientOrder">
                <super-button @click="editWarehouseRemarks" v-if="!warehouseRemarksBeingEdited && !clientOrderDetails.warehouseRemarks">Adaugă observații</super-button>
                <super-button @click="editWarehouseRemarks" v-if="!warehouseRemarksBeingEdited && clientOrderDetails.warehouseRemarks">Modifică observațiile</super-button>
              </div>
            </div>
          </div>
        </div>
        <file-attachments-list
          :files="fileAttachments"
          @addAttachmentFile="addAttachmentFile"
          @deleteAttachmentFile="deleteAttachmentFile"/>
      </b-card>

      <div>
        <b-card no-body header="Facturi și avize emise" class="mt-3" v-if="invoices && invoices.length">
            <b-list-group>
              <b-list-group-item v-for="entry in invoices" :key="entry.issuedInvoiceId">
                <b-badge v-if="entry.documentType === 'AVZ'" variant="info" class="mx-1">Aviz</b-badge>
                <b-badge v-if="entry.documentType === 'FAC'" variant="info" class="mx-1">Factură</b-badge>
                <span>{{ entry.seriesAndNumber }} din {{ entry.issuedAt | displayAsDate }} ({{ entry.grandTotal | displayAsDecimal(2) }} RON, TVA inclus)</span>
                <b-badge v-if="entry.isExported" variant="info" class="mx-1" v-b-tooltip.hover="'Document descărcat'"><i class="fas fa-download"/></b-badge>
                <b-badge v-if="entry.isCanceled" variant="warning" class="mx-1">Anulat</b-badge>
                <b-button
                  size="sm"
                  :to="{ name: 'issuedInvoiceDetails', params: { issuedInvoiceId: entry.issuedInvoiceId }, query: { isDeliveryNote: entry.isDeliveryNote } }"
                  class="mx-1">
                  <i class="fas fa-eye mr-1" /><span>Detalii</span>
                </b-button>
                <b-button
                  v-if="entry.documentType === 'FAC'"
                  class="mx-1"
                  size="sm"
                  @click="showDownloadInvoicePdfModal(entry)">
                  <i class="fas fa-file-invoice-dollar mr-1" /><span>Descarcă PDF</span>
                </b-button>
                <b-button class="mx-1" size="sm" v-if="entry.documentType === 'AVZ'" @click="showDownloadInvoicePdfModal(entry)">
                  <i class="fas fa-file-invoice mr-1" />Descarcă PDF
                </b-button>
                <b-button class="mx-1" size="sm" v-if="!entry.isCanceled && entry.isExported && entry.documentType === 'FAC'" @click="showDownloadDispatchNotePdfModal(entry)">
                  <i class="fas fa-file-invoice mr-1" />Descarcă aviz
                </b-button>
                <b-dropdown class="mx-1" size="sm" v-if="!entry.isCanceled && entry.isExported">
                  <template v-slot:button-content>
                    <font-awesome-icon :icon="['fas', 'file-certificate']" class="mr-1"/>Decl. conform.
                  </template>
                  <b-dropdown-item :href="'/api/client-order/declaration-of-conformity/' + entry.documentType + '/' + entry.issuingYear + '/' + entry.number">PDF</b-dropdown-item>
                  <b-dropdown-item :href="'/api/client-order/declaration-of-conformity/' + entry.documentType + '/' + entry.issuingYear + '/' + entry.number + '?fileFormat=xlsx'">Excel</b-dropdown-item>
                  <b-dropdown-item :href="'/api/client-order/declaration-of-conformity/' + entry.documentType + '/' + entry.issuingYear + '/' + entry.number + '?fileFormat=docx'">Word</b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="mx-1" size="sm" v-if="canAdminister && clientOrderDetails.statusCode === ClientOrderStatus.PREPARED">
                  <template v-slot:button-content><i class="fas fa-toolbox mr-1"/>Admin</template>
                  <b-dropdown-item-button @click="cancelInvoice(entry)" v-if="!entry.isCanceled">anulează factura</b-dropdown-item-button>
                  <b-dropdown-item-button @click="revertInvoiceCancellation(entry)" v-if="entry.isCanceled && invoices.every((inv) => inv.isCanceled)">de-anulează factura</b-dropdown-item-button>
                </b-dropdown>
                <b-button class="mx-1" size="sm" @click="showSendInvoiceEmailModal(entry)">
                  <i class="fas fa-envelope mr-1" /><span>Trimite email</span>
                </b-button>
              </b-list-group-item>
            </b-list-group>
        </b-card>

      </div>

      <br>

      <client-order-discount-rules-alert :discount-rules="discountRules" v-if="clientOrderDetails.statusCode === ClientOrderStatus.SUBMITTED"/>

      <b-alert v-if="doesOrderContainRows && !isEntireRequestedQuantityAssigned && clientOrderDetails.statusCode !== ClientOrderStatus.SUBMITTED && clientOrderDetails.statusCode !== ClientOrderStatus.CANCELED" show variant="warning">
        <span>Există poziții la care nu este alocat stoc pentru întreaga cantitate comandată</span>
        <div v-if="canPrepareClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.PREPARING">
          <b-button-group class="pt-2 pb-1" size="md">
            <super-button id="performAutomaticStockSelection" @click="handleAutomaticStockSelection" variant="primary">
              <span><i class="fas fa-magic"/>&nbsp;Selectează automat stocurile</span>
            </super-button>
          </b-button-group>
        </div>
      </b-alert>

      <b-alert v-if="isTooMuchQuantityAssigned" show variant="danger">
        <span>Există poziții care au alocate poziții de stoc cu o cantitate mai mare decât cea comandată</span>
      </b-alert>

      <b-alert v-if="canPrepareClientOrder && doesOrderContainRows && isEntireRequestedQuantityAssigned && !isTooMuchQuantityAssigned && clientOrderDetails.statusCode !== ClientOrderStatus.PREPARED" show variant="success">
        <span>Toate pozițiile din comandă au alocate poziții de stoc pentru întreaga cantitate comandată</span>
      </b-alert>

      <div class="m-3" v-if="canAccessWms && doesOrderContainRows && (clientOrderDetails.statusCode === ClientOrderStatus.PREPARING || clientOrderDetails.statusCode === ClientOrderStatus.PREPARED)">
        <super-button id="performAutomaticStockSelection" @click="openPicklistPopulationModal" variant="primary">
          <span><font-awesome-icon :icon="['far', 'box-open']" class="mr-1"/>&nbsp;Trimite spre culegere în depozit</span>
        </super-button>
      </div>

      <!-- Order rows table -->
      <b-table
        show-empty
        small
        striped
        no-footer-sorting
        ref="orderLinesTable"
        empty-text="Comanda nu are poziții"
        :foot-clone="true"
        :items="items"
        :fields="tableFields"
        :busy.sync="tableBusy"
        class="tweaked-table">

        <template v-slot:cell(rowIndex)="row">
          {{ row.index + 1 }}.
        </template>

        <template v-slot:cell(catalogItemCode)="row">
          <b-badge
            variant="info"
            class="badge-lg">
            <i class="fas fa-tag mr-1" />
            <samp>{{ row.value }}</samp>
          </b-badge>

          <span>&nbsp;</span>

          <b-button
            variant="link"
            size="sm"
            v-b-tooltip.hover="'Copiază codul de produs'"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
            v-clipboard="() => row.value"><i class="far fa-copy" />
          </b-button>

          <span v-if="row.item.isService">
            &nbsp;
          </span>

          <b-badge
            v-if="row.item.isService"
            variant="primary"
            class="badge-lg">
            <i class="fas fa-briefcase mr-1" />
            Serviciu
          </b-badge>

          <span v-if="row.item.catalogItemBarCode">
            &nbsp;
          </span>

          <b-badge variant="secondary" class="badge-lg" v-if="row.item.catalogItemBarCode">
            <i class="fas fa-barcode mr-1"/>
            <samp>{{row.item.catalogItemBarCode}}</samp>
          </b-badge>

          <br>
          <span>{{ row.item.lineDetails }}</span>
          <span class="small" v-if="row.item.inventoryBatchCode" style="display: block;">
            Lot: {{ row.item.inventoryBatchCode }}<span v-if="row.item.inventoryBatchExpiryDate"> | Exp: {{ row.item.inventoryBatchExpiryDate | displayAsDate }}</span>
          </span>
        </template>

        <template v-slot:head(quantity)="data">
          <span v-if="clientOrderDetails.statusCode === ClientOrderStatus.DRAFT || clientOrderDetails.statusCode === ClientOrderStatus.SUBMITTED">
            {{ data.label }}
          </span>
          <span v-else>
            Cant. cerută /
            <br/>
            pregătită
          </span>
        </template>

        <template v-slot:cell(unitPrice)="row">
          {{ row.value | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(unitDiscountPercentage)="row">
         {{ row.value | displayAsPercentage(4) }}
         <br>
         {{ row.item.unitDiscountValue | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(quantity)="row">
          {{ row.value | displayAsDecimal(3) }}
          <span v-if="clientOrderDetails.statusCode !== ClientOrderStatus.DRAFT && clientOrderDetails.statusCode !== ClientOrderStatus.SUBMITTED && !row.item.isService">
            <br>
            {{ row.item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0) | displayAsDecimal(3) }}
          </span>
        </template>

        <template v-slot:cell(lineDiscountValue)="row">
          {{ row.value | displayAsDecimal(2) }}<br>
          {{ row.item.vatDiscountValue | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(vatPercentage)="row">
          {{ row.value | displayAsPercentage }}
          <br>
          {{ row.item.vatValue | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(totalValue)="row">
          {{ (row.item.lineValue - row.item.lineDiscountValue + row.item.vatValue - row.item.vatDiscountValue) | displayAsDecimal(2) }}
        </template>

        <template #row-details="row">
          <div>
            <div v-if="row.item.inventorySelectionEntries && row.item.inventorySelectionEntries.length">
              <div class="d-flex flex-row" v-for="entry in row.item.inventorySelectionEntries" :key="entry.clientOrderLineInventorySelectionId">
                <div class="inventory-selection-index-padding-col" role="cell" aria-hidden="true"></div>
                <div class="d-flex flex-row inventory-selection-entry-meta-col">
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Inventory code cell">
                    <span><strong class="mr-1">Gest.:</strong>{{ entry.inventoryCode }}</span>
                  </div>
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Inventory account number cell">
                    <span><strong class="mr-1">Cont:</strong>{{ entry.inventoryAccountNumber }}</span>
                  </div>
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Inventory batch code cell">
                    <span class="mr-1"><strong>Lot:</strong></span>
                    <span v-if="entry.inventoryBatchId">{{ entry.inventoryBatchCode }}</span>
                    <span v-else>-</span>
                  </div>
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Expiration date cell">
                    <span><strong class="mr-1">Exp.:</strong></span>
                    <span v-if="entry.inventoryBatchExpiryDate">{{ entry.inventoryBatchExpiryDate | displayAsDate }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="inventory-selection-value-col text-right" role="cell" aria-label="Inventory unit price cell">
                  <span v-if="canViewInventoryPrice">{{ entry.inventoryUnitPrice | displayAsDecimal(5) }}</span>
                  <span v-else>-</span>
                </div>
                <div class="inventory-selection-value-col" role="cell" aria-hidden="true"></div>
                <div class="inventory-selection-value-col text-right" role="cell" aria-label="Quantity cell">
                  <span>{{ entry.quantity | displayAsDecimal(3) }}</span>
                </div>
                <div class="inventory-selection-value-col text-right" role="cell" aria-label="Total value cell">
                  <span v-if="canViewInventoryPrice">{{ entry.totalValue | displayAsDecimal(2) }}</span>
                  <span v-else>-</span>
                </div>
                <div class="px-1 inventory-selection-storekeeper-col text-center" role="cell" aria-label="Chosen by cell">
                  <span class="mr-1"><strong>Sel.:</strong></span>
                  <user-name-badge :user-name="entry.selectionPerformedByFullName"/> la {{ entry.selectionPerformedAt | displayAsDate }}
                </div>
                <div class="inventory-selection-value-col text-center pl-2" role="button" aria-label="Remove inventory selection cell">
                  <b-button
                    variant="danger"
                    size="sm"
                    v-if="clientOrderDetails.statusCode !== ClientOrderStatus.CANCELED && (!invoices || invoices.every((inv) => inv.isCanceled))"
                    @click="removeInventorySelectionEntry(entry.clientOrderLineInventorySelectionId)">
                    <i class="fas fa-times"/>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:cell(actions)="row">
          <!-- Add row button -->
          <b-button
            v-if="canPrepareClientOrder && clientOrderDetails.statusCode === ClientOrderStatus.PREPARING && !row.item.isService"
            variant="primary"
            size="sm"
            :disabled="tableBusy || xhrRequestRunning"
            @click="showInventorySelectionModal(row.item)"
          >
            <span><i class="fas fa-plus-square" />&nbsp;<i class="fas fa-dolly-flatbed" /></span>
          </b-button>
        </template>

        <template v-slot:foot(catalogItemCode)>
          TOTAL
        </template>

        <template v-slot:foot(quantity)>
          {{ items.filter(i => !i.isService).reduce((s, i) => s + i.quantity, 0) | displayAsDecimal(3) }}
          <span v-if="clientOrderDetails.statusCode !== ClientOrderStatus.DRAFT && clientOrderDetails.statusCode !== ClientOrderStatus.SUBMITTED">
            <br>
            {{ items.filter(i => !i.isService).reduce((s, i) => s + i.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0), 0) | displayAsDecimal(3) }}
          </span>
        </template>

        <template v-slot:foot(lineDiscountValue)>
          {{ items.reduce((s, i) => s + i.lineDiscountValue, 0) | displayAsDecimal(2) }}<br>
          {{ items.reduce((s, i) => s + i.vatDiscountValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(lineValue)>
          {{ items.reduce((s, i) => s + i.lineValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(vatPercentage)>
          {{ items.reduce((s, i) => s + i.vatValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(totalValue)>
          {{ items.reduce((s, i) => s + i.lineValue - i.lineDiscountValue + i.vatValue - i.vatDiscountValue, 0) | displayAsDecimal(2) }}
        </template>

        <!-- The rest of the footer cells should be empty -->
        <template v-slot:foot()>&nbsp;</template>
      </b-table>

    </div>

    <b-modal
      ref="picklistPopulationModal"
      title="Pregătește listă de culegere"
      size="xl"
      hide-footer
      scrollable>
      <div class="container-fluid py-1">
        <b-form-group label="Observații">
          <b-textarea v-model="picklistSpecificInformation"></b-textarea>
        </b-form-group>
      </div>

      <div class="container-fluid text-center py-1" v-if="!picklistPopulated && !picklistIsPopulating">
        <super-button
          @click="tryPopulatePicklist"
          :disabled="picklistBusy"
          class="mx-1"
        >
          Găsește echivalențe în WMS și populează lista
        </super-button>
      </div>

      <div class="container-fluid text-center pb-2" v-if="picklistPopulated">
        <div class="row">
          <div class="col">
            <b-form-group label="Operator recepție">
              <b-form-select
                :disabled="picklistBusy"
                v-model="picklistSelectedWarehouseOperatorId"
                :options="picklistWarehouseOperators"></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <super-button
              @click="handlePicklistPopulation"
              class="mx-1"
              :disabled="!picklistSelectedWarehouseOperatorId || picklistBusy">
              Generează listă de culegere
            </super-button>

            <b-form-checkbox
              :checked="picklistCreateAsDraft">
              Crează ca ciornă
            </b-form-checkbox>
          </div>
          <div class="col">
            <super-button
              @click="handleCopyToDraftPicklist"
              class="mx-1"
              :disabled="!picklistSelectedDraftPicklist || picklistBusy">
              Adaugă la ciornă existentă
            </super-button>

            <b-form-select
              :disabled="picklistBusy"
              v-model="picklistSelectedDraftPicklist"
              :options="picklistDraftPicklists"></b-form-select>
          </div>
        </div>
      </div>

      <div class="my-1" v-if="picklistModalStatusText">
        <b-alert show variant="info" class="text-center">
          {{ picklistModalStatusText }}
        </b-alert>
      </div>

      <table class="table table-small caption-top table-sm">
        <thead>
          <th>#</th>
          <th>Cod</th>
          <th>Denumire</th>
          <th>Lot</th>
          <th class="text-right">Cantitate</th>
          <th>Alocare</th>
        </thead>
        <tbody v-for="(line, idx) in picklistSourceLines" :key="line.clientOrderLineId">
          <tr v-for="entry in line.inventorySelectionEntries" :key="entry.inventorySelectionEntryId">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              <span>{{ line.catalogItemCode }}</span>
              <br>
              <span v-if="line.catalogItemBarCode">{{line.catalogItemBarCode}}</span>
              <span v-else><i class="fas text-danger fa-exclamation-triangle"/>&nbsp;fără cod bare</span>
            </td>
            <td>
              {{ line.lineDetails}}
            </td>
            <td>
              {{ entry.inventoryBatchCode }} <span v-if="entry.inventoryBatchExpiryDate">/ {{ entry.inventoryBatchExpiryDate | displayAsDate }}</span>
            </td>
            <td class="text-right">
              <span>{{ entry.quantity | displayAsDecimal(3) }}</span>
            </td>
            <td>
              <span v-if="line.wmsProduct">Produs: {{ line.wmsProduct && line.wmsProduct.productCode }}</span>
              <span v-if="line.matchingProductError">Produs: <i class="fas text-danger fa-exclamation-triangle"/>&nbsp;fără echivalență</span>
              <br>
              <span v-if="entry.matchingBatch">Lot: {{ entry.matchingBatch && entry.matchingBatch.batchCode }} <span v-if="entry.matchingBatch && entry.matchingBatch.batchExpirationDate">/ {{ entry.matchingBatch.batchExpirationDate | displayAsDate }}</span></span>
              <span v-if="entry.matchingBatchError">Lot: <i class="fas text-warning fa-exclamation-triangle"/>&nbsp;fără echivalență</span>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <b-modal
      id="inventory-selection-modal"
      title="Alege stoc"
      size="xl"
      scrollable
      @ok="handleStockSelection">
      <b-alert show variant="danger" v-if="availableInventorySelectionClientOrderLine && parseFloat(availableInventorySelectionClientOrderLine.quantity) - availableInventorySelectionClientOrderLine.inventorySelectionEntries.reduce((acc, entry) => acc + parseFloat(entry.quantity), 0) - availableInventorySelectionEntries.reduce((acc, entry) => acc + parseFloat(entry.selectedQuantity), 0) < 0">
        A fost aleasă o cantitate prea mare!
      </b-alert>
      <b-alert show variant="danger" v-if="availableInventorySelectionClientOrderLine && availableInventorySelectionEntries.findIndex((entry) => parseFloat(entry.selectedQuantity) > entry.availableQuantity) > -1">
        Există poziții cu cantitate aleasă prea mare!
      </b-alert>
      <b-alert show variant="danger" v-if="availableInventorySelectionClientOrderLine && availableInventorySelectionEntries.findIndex((entry) => parseFloat(entry.selectedQuantity) < 0) > -1">
        Există poziții cu cantitate aleasă negativă!
      </b-alert>
      <b-alert show variant="warning" v-if="availableInventorySelectionClientOrderLine && parseFloat(availableInventorySelectionClientOrderLine.quantity) - availableInventorySelectionClientOrderLine.inventorySelectionEntries.reduce((acc, entry) => acc + parseFloat(entry.quantity), 0) - availableInventorySelectionEntries.reduce((acc, entry) => acc + parseFloat(entry.selectedQuantity), 0) > 0">
        Cantitatea aleasă nu acoperă întreaga poziție!
      </b-alert>
      <b-table-simple small caption-top>
        <caption v-if="availableInventorySelectionClientOrderLine">
          Selectie stoc pentru <strong>{{ availableInventorySelectionClientOrderLine.catalogItemCode }}</strong> ; mai este necesară o cantitate de <strong>{{ parseFloat(availableInventorySelectionClientOrderLine.quantity) - availableInventorySelectionClientOrderLine.inventorySelectionEntries.reduce((acc, entry) => acc + parseFloat(entry.quantity), 0) - availableInventorySelectionEntries.reduce((acc, entry) => acc + parseFloat(entry.selectedQuantity), 0) | displayAsDecimal(3) }}</strong> bucăți
        </caption>
        <b-thead>
          <b-tr>
            <b-th>Gestiune</b-th>
            <b-th>Cont</b-th>
            <b-th v-if="canViewInventoryPrice" class="text-right">Preț gestiune</b-th>
            <b-th>Lot</b-th>
            <b-th>Cant. disponibilă</b-th>
            <b-th>Cant. aleasă</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(entry, idx) in availableInventorySelectionEntries" :key="entry.inventoryId" :variant="parseFloat(entry.selectedQuantity) > 0 ? 'primary' : null">
            <b-td>{{ entry.inventoryCode }}</b-td>
            <b-td>{{ entry.inventoryAccountNumber }}</b-td>
            <b-td v-if="canViewInventoryPrice" class="text-right">{{ entry.inventoryUnitPrice | displayAsDecimal(5) }}</b-td>
            <b-td>
              <span v-if="entry.inventoryBatchCode">
                {{ entry.inventoryBatchCode }} <span v-if="entry.inventoryBatchExpiryDate">/ {{ entry.inventoryBatchExpiryDate | displayAsDate }}</span>
              </span>
            </b-td>
            <b-td>{{ entry.availableQuantity | displayAsDecimal(3) }}</b-td>
            <b-td>
              <b-form-input
                style="text-align: right;"
                ref="quantity"
                :state="parseFloat(availableInventorySelectionEntries[idx].selectedQuantity) === 0 ? null : (parseFloat(availableInventorySelectionEntries[idx].selectedQuantity) <= availableInventorySelectionEntries[idx].availableQuantity && parseFloat(availableInventorySelectionEntries[idx].selectedQuantity) > 0)"
                @focus="selectAll"
                v-model="availableInventorySelectionEntries[idx].selectedQuantity"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>

    <b-modal
      id="sales-agent-notes-modal"
      title="Observații agent vânzări"
      size="md"
      centered
      scrollable
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      @ok="handleSalesAgentNotesModal">

      <p class="text-center">Agentul de vânzări a lăsat următoarele observații:</p>
      <blockquote class="blockquote text-center preserve-newlines" v-if="this.clientOrderDetails && this.clientOrderDetails.salesRemarks">
        <p class="mb-0">{{clientOrderDetails.salesRemarks}}</p>
        <footer class="blockquote-footer">{{clientOrderDetails.createdByFullName}}</footer>
      </blockquote>

      <template #modal-footer="{ ok }">
        <b-form-checkbox v-model="persistHideSalesRemarks" :value="true" :unchecked-value="false">nu mai afișa din nou</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">
          OK
        </b-button>
    </template>
    </b-modal>

    <send-invoice-email-modal
      ref="sendInvoiceEmailModal"/>

    <event-log-modal
      ref="eventLogModal"/>

    <download-invoice-modal
      ref="downloadInvoiceModal"
      @downloadStarted="onInvoiceDownloadStarted"/>

  </b-container>
</template>

<style lang="scss">
.b-table-details, .b-table-details .b-table {
  background-color: rgb(245, 245, 245);
}

.multiselect-product-name {
  overflow-x: hidden;
}

.col-max-w-50px {
  max-width: 50px;
}

.col-min-w-250px {
  min-width: 250px;
}

.col-w-200px {
  width: 200px;
}

.col-numeric-value {
  width: 105px;
}
</style>

<script>
import MD5 from 'md5.js';
import BigNumber from 'bignumber.js';
import { mapState, mapGetters, mapActions } from 'vuex';

import toasts from '@/services/toasts';
import wmsSettings from '@/services/wms';

import UserNameBadge from '../components/UserNameBadge.vue';
import SendInvoiceEmailModal from '../components/SendInvoiceEmailModal.vue';
import EventLogModal from '../components/EventLogModal.vue';
import DownloadInvoiceModal from '../components/DownloadInvoiceModal.vue';

import ClientOrderDiscountRulesAlert from '../components/ClientOrderDiscountRulesAlert.vue';
import ClientOrderStatusBadge from '../components/ClientOrderStatusBadge.vue';
import FileAttachmentsList from '../components/FileAttachmentsList.vue';

import { ClientOrderStatus, EventLogSubjectType } from '../constants';

const { DateTime } = require('luxon');

const { httpClient: wmsHttpClient, warehouseId: wmsWarehouseId, fallbackStorageCellId: wmsFallbackStorageCellId } = wmsSettings;

export default {
  name: 'ViewClientOrder',
  components: {
    UserNameBadge,
    ClientOrderDiscountRulesAlert,
    ClientOrderStatusBadge,
    SendInvoiceEmailModal,
    EventLogModal,
    FileAttachmentsList,
    DownloadInvoiceModal,
  },
  props: {
    providedClientOrderId: String,
    providedClientOrderDetails: Object,
  },
  data() {
    const freshData = {
      isLoaded: false,
      performRouteChange: false,
      clientOrderId: null,
      clientOrderDetails: null,
      ClientOrderStatus,
      warehouseRemarksBeingEdited: false,
      warehouseRemarksUpdateText: null,

      picklistSpecificInformation: null,
      picklistSourceLines: [],
      picklistModalStatusText: null,
      picklistIsPopulating: false,
      picklistPopulated: false,

      picklistSelectedWarehouseOperatorId: null,
      picklistWarehouseOperators: [],

      picklistDraftPicklists: [],
      picklistSelectedDraftPicklist: null,
      picklistBusy: false,

      picklistCreateAsDraft: false,

      tableBusy: false,

      tableFields: [
        {
          key: 'rowIndex', label: '#', class: 'col-max-w-50px',
        },
        {
          key: 'catalogItemCode', label: 'Produs', thClass: 'col-min-w-250px', class: 'col-min-w-250px text-left',
        },
        {
          key: 'unitPrice', label: 'Preț', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'unitDiscountPercentage',
          label: 'Discount',
          thClass: 'col-numeric-value',
          class: 'col-numeric-value text-right',
          tdClass: (val, key, item) => {
            if (item.unitDiscountPercentage === 1) {
              return 'table-secondary';
            }
            if (item.unitDiscountPercentage !== 0) {
              return 'table-info';
            }
            return null;
          },
        },
        {
          key: 'quantity',
          label: 'Cant. cerută',
          thClass: 'col-numeric-value',
          class: 'col-numeric-value text-right',
          tdClass(value, key, item) {
            if (!freshData.clientOrderDetails || [ClientOrderStatus.SUBMITTED, ClientOrderStatus.DRAFT].includes(freshData.clientOrderDetails.statusCode)) {
              return '';
            }

            if (item.isService) {
              return 'table-success';
            }

            const totals = item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0);
            if (totals > value) return 'table-danger';
            if (totals < value) return 'table-warning';
            return 'table-success';
          },
        },
        {
          key: 'lineValue', label: 'Valoare', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'vatPercentage', label: '%/Val. TVA', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'lineDiscountValue', label: 'Val./TVA discount', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'totalValue', label: 'Val. cu TVA și discount', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'actions', label: '', thClass: 'col-numeric-value', class: 'col-numeric-value',
        },
      ],
      items: [],
      discountRules: [],
      invoices: [],
      issuedInvoices: [],
      deliveryNotes: [],

      fileAttachments: [],
      areFileAttachmentsLoaded: false,

      preventEnterJump: false,

      availableInventorySelectionClientOrderLine: null,
      availableInventorySelectionEntries: [],

      persistHideSalesRemarks: false,
    };

    return freshData;
  },
  computed: {
    actualDeliveryAddressLocationDisplay() {
      const locality = this.clientOrderDetails.clientDeliveryAddressCity?.trim();
      const county = this.clientOrderDetails.ClientDeliveryAddressCountyName?.trim();
      if (locality && county) return `${locality}, ${county}`;
      if (locality || county) return `${locality || county}`;
      return null;
    },
    actualAddressLocationDisplay() {
      const locality = this.clientOrderDetails.clientCityAddress?.trim();
      const county = this.clientOrderDetails.clientCountyAddress?.trim();
      if (locality && county) return `${locality}, ${county}`;
      if (locality || county) return `${locality || county}`;
      return null;
    },
    pdfDownloadLink() { return `/api/client-order/export/${this.clientOrderId}/pdf`; },
    xlsxDownloadLink() { return `/api/client-order/export/${this.clientOrderId}/xlsx`; },

    pdfInventoryFormDownloadLink() { return `/api/client-order/inventory-form/${this.clientOrderId}/pdf`; },
    xlsxInventoryFormDownloadLink() { return `/api/client-order/inventory-form/${this.clientOrderId}/xlsx`; },

    ...mapState(['xhrRequestRunning', 'userRoles', 'lastRoute', 'userGuid']),
    ...mapGetters(['canCreateClientOrder', 'canPrepareClientOrder', 'canViewInventoryPrice', 'canIssueClientInvoice', 'canIssueClientDeliveryNote', 'canAdminister', 'canCompleteClientOrder', 'canReopenClientOrder', 'canAccessWms']),

    doesOrderContainRows() {
      return this.items && this.items.length;
    },

    isEntireRequestedQuantityAssigned() {
      return this.items.every((item) => {
        if (item.isService) {
          return true;
        }
        const totals = item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0);
        return totals >= item.quantity;
      });
    },

    isTooMuchQuantityAssigned() {
      return this.items.length && this.items.every((item) => {
        if (item.isService) {
          return false;
        }
        const totals = item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0);
        return totals > item.quantity;
      });
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.isLoaded = false;
      // eslint-disable-next-line no-param-reassign
      vm.performRouteChange = true;
      // eslint-disable-next-line no-param-reassign
      vm.clientOrderId = to.params.clientOrderId;
      vm.loadInitialData();
    });
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    this.clientOrderId = to.params.clientOrderId;
    try {
      await this.loadInitialData();
    } finally {
      next();
    }
  },
  // Need to use this to allow Vue HMR to work
  created() {
    this.clientOrderId = this.providedClientOrderId;
    this.loadInitialData(this.providedClientOrderDetails);
  },

  filters: {
    displayAsDate(value) {
      if (!value) {
        return null;
      }
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
    displayAsPercentage(value, decimals = 0) {
      return `${new BigNumber(value).times(100).decimalPlaces(decimals).toString()} %`;
    },
  },

  methods: {
    ...mapActions([
      'performClientOrderLinesByIdQuery',
      'performClientOrderDetailsQuery',
      'performClientOrderInventorySelectionEntriesQuery',
      'performClientOrderAvailableInventoryEntriesQuery',
      'performClientOrderAvailableInventorySelection',
      'performClientOrderInventorySelectionEntryRemoval',
      'performClientOrderDiscountRulesQuery',
      'performClientOrderCancellation',
      'performClientOrderBeginPreparing',
      'performClientOrderFinishPreparing',
      'performIssueInvoiceForClientOrder',
      'performClientOrderInvoicesQuery',
      'performClientOrderDeliveryNotesQuery',
      'performRollBackClientOrderStatus',
      'performClientOrderWarehouseRemarksSubmission',
      'performCancelInvoice',
      'performRevertInvoiceCancellation',
      'performCompleteClientOrder',
      'performReopenClientOrder',
      'performFileAttachmentsListLookup',
      'performFileAttachmentsAdd',
      'performFileAttachmentDelete',
    ]),

    async onExportInvoice(invoice) {
      // eslint-disable-next-line no-param-reassign
      invoice.isExported = true;
    },

    async validateInventoryFormDownload(format) {
      if (this.doesOrderContainRows && (!this.isEntireRequestedQuantityAssigned || this.isTooMuchQuantityAssigned)) {
        if (!(await this.$bvModal.msgBoxConfirm('Nu toate pozițiile au alocată cantitatea cerută în comandă. Continui?', {
          okTitle: 'Da',
          cancelTitle: 'Nu',
        }).catch(() => false))) {
          return;
        }
      }

      if (format === 'pdf') {
        window.location.href = this.pdfInventoryFormDownloadLink;
      }
      if (format === 'xlsx') {
        window.location.href = this.xlsxInventoryFormDownloadLink;
      }
    },

    selectAll(evt) {
      evt.target.select();
    },

    async cancelInvoice(invoice) {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi anularea facturii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const outcome = await this.performCancelInvoice(invoice.issuedInvoiceId);

      if (outcome) {
        toasts.success('Factura a fost anulată');
      } else {
        toasts.error('Factura nu a putut fi anulată');
      }

      this.loadInitialData();
    },

    async revertInvoiceCancellation(invoice) {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi de-anularea facturii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const outcome = await this.performRevertInvoiceCancellation(invoice.issuedInvoiceId);

      if (outcome) {
        toasts.success('Factura a fost de-anulată');
      } else {
        toasts.error('Factura nu a putut fi de-anulată');
      }

      this.loadInitialData();
    },

    editWarehouseRemarks() {
      if (this.warehouseRemarksBeingEdited) {
        return;
      }

      this.warehouseRemarksBeingEdited = true;
      this.warehouseRemarksUpdateText = this.clientOrderDetails.warehouseRemarks;
    },

    async submitWarehouseRemarks() {
      if (!this.warehouseRemarksBeingEdited) {
        return;
      }

      this.warehouseRemarksBeingEdited = false;

      await this.performClientOrderWarehouseRemarksSubmission({ clientOrderId: this.clientOrderId, remarks: this.warehouseRemarksUpdateText });
      this.clientOrderDetails.warehouseRemarks = this.warehouseRemarksUpdateText;

      toasts.sucess('Observațiile au fost actualizate');
    },

    async handleSalesAgentNotesModal() {
      if (this.persistHideSalesRemarks) {
        localStorage.setItem(`sales_remarks_${this.clientOrderDetails.clientOrderId}_${this.userGuid}`, new MD5().update(this.clientOrderDetails.salesRemarks).digest('hex'));
      }
    },

    async openPicklistPopulationModal() {
      const linesWithInventorySelection = this.items.filter((item) => !!item.inventorySelectionEntries && item.inventorySelectionEntries.length);

      if (!linesWithInventorySelection.length) {
        await this.$bvModal.msgBoxOk('Nu există incă alocări de stoc pentru care să se poată face pregătirea unei liste de culegere');
      }

      let orderSpecificInfo = this.clientOrderDetails.clientName;
      if (this.clientOrderDetails.clientDeliveryAddressName) {
        orderSpecificInfo = `${orderSpecificInfo} | LIVRARE ${this.clientOrderDetails.clientDeliveryAddressName}`;
      }
      orderSpecificInfo = `${orderSpecificInfo} | ID ${this.clientOrderId}`;

      // Make a copy of the items, and the sub-items
      this.picklistSpecificInformation = orderSpecificInfo;
      this.picklistSourceLines = linesWithInventorySelection.map((line) => ({ sourceLine: line, ...line, inventorySelectionEntries: line.inventorySelectionEntries.map((entry) => ({ sourceEntry: entry, ...entry })) }));
      this.picklistModalStatusText = null;
      this.picklistPopulated = false;
      this.picklistSelectedWarehouseOperatorId = null;
      this.picklistCreateAsDraft = false;
      this.picklistSelectedDraftPicklist = null;
      this.picklistBusy = false;

      this.$refs.picklistPopulationModal.show();
    },

    async handleAutomaticStockSelection() {
      await this.performClientOrderAvailableInventorySelection({
        clientOrderId: this.clientOrderId,
        performAutomaticInventorySelection: true,
      });

      toasts.success('Selecția automată a stocurilor a fost realizată');

      this.loadInitialData();
    },

    async handleStockSelection(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      const matchingRows = this.availableInventorySelectionEntries.filter((entry) => parseFloat(entry.selectedQuantity) > 0).map((entry) => ({
        clientOrderLineId: this.availableInventorySelectionClientOrderLine.clientOrderLineId,
        catalogItemCode: this.availableInventorySelectionClientOrderLine.catalogItemCode,
        inventoryCode: entry.inventoryCode,
        inventoryAccountNumber: entry.inventoryAccountNumber,
        inventoryUnitPrice: entry.inventoryUnitPrice,
        inventoryBatchId: entry.inventoryBatchId,
        selectedQuantity: parseFloat(entry.selectedQuantity),
      }));

      await this.performClientOrderAvailableInventorySelection({
        clientOrderId: this.clientOrderId,
        performAutomaticInventorySelection: false,
        entries: matchingRows,
      });

      this.loadInitialData();

      this.$nextTick(() => this.$bvModal.hide('inventory-selection-modal'));
    },

    async showInventorySelectionModal(clientOrderLine) {
      this.availableInventorySelectionClientOrderLine = clientOrderLine;

      this.availableInventorySelectionEntries = (await this.performClientOrderAvailableInventoryEntriesQuery({ catalogItemCode: clientOrderLine.catalogItemCode }))
        .map((entry) => Object.assign(entry, { selectedQuantity: 0 }));

      this.$bvModal.show('inventory-selection-modal');
    },

    async showSendInvoiceEmailModal(invoiceLine) {
      this.$nextTick(() => this.$refs.sendInvoiceEmailModal.showModal(this.clientOrderDetails.clientCode,
        invoiceLine.documentType,
        invoiceLine.number,
        invoiceLine.issuingYear));
    },

    async showEventLogModal() {
      this.$nextTick(() => this.$refs.eventLogModal.showModal(this.clientOrderId, EventLogSubjectType.Order));
    },

    async rollBackSubmittedClientOrder() {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi revenirea la starea de ciornă a comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const outcome = await this.performRollBackClientOrderStatus(this.clientOrderId);

      if (outcome) {
        toasts.success('Comanda a revenit în starea de ciornă');
      } else {
        toasts.error('Comanda nu a putut fi readusă în starea de ciornă');
      }

      this.loadInitialData();

      this.$emit('statusChanged', '');
    },

    async beginPreparingClientOrder() {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi preluarea comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      await this.performClientOrderBeginPreparing(this.clientOrderId);

      this.loadInitialData();
    },

    async removeInventorySelectionEntry(entryId) {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi eliminarea selecției de stoc?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      await this.performClientOrderInventorySelectionEntryRemoval({
        clientOrderId: this.clientOrderId,
        inventorySelectionEntryId: entryId,
      });

      this.loadInitialData();
    },

    async rollBackPreparingClientOrder() {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi revenirea la starea de trimisă a comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const outcome = await this.performRollBackClientOrderStatus(this.clientOrderId);

      if (outcome) {
        toasts.success('Comanda a revenit în starea de trimisă');
      } else {
        toasts.error('Comanda nu a putut fi readusă în starea de trimisă');
      }

      this.loadInitialData();
    },

    async finishPreparingClientOrder() {
      const missingItems = this.items.find((item) => !item.isService && item.quantity !== item.inventorySelectionEntries.reduce((sum, entry) => sum + entry.quantity, 0)) !== undefined;
      if (missingItems) {
        if (!(await this.$bvModal.msgBoxConfirm('Atenție! Există poziții care nu au stocuri integral selectate! Dorești să continui?', {
          okTitle: 'Da',
          cancelTitle: 'Nu',
        }).catch(() => false))) {
          return;
        }
      }

      if (!(await this.$bvModal.msgBoxConfirm('Confirmi finalizarea pregătirii comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      await this.performClientOrderFinishPreparing(this.clientOrderId);

      this.loadInitialData();
    },

    async rollBackPreparedClientOrder() {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi revenirea la starea de pregătire a comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const outcome = await this.performRollBackClientOrderStatus(this.clientOrderId);

      if (outcome) {
        toasts.success('Comanda a revenit în starea de pregătire');
      } else {
        toasts.error('Comanda nu a putut fi readusă în starea de pregătire');
      }

      this.loadInitialData();
    },

    async issueInvoiceForClientOrder() {
      if (this.clientOrderDetails.statusCode !== ClientOrderStatus.PREPARED) {
        return;
      }

      this.$router.push({
        name: 'clientOrderIssueInvoice',
        params: {
          clientOrderId: this.clientOrderId,
        },
      });
    },

    async issueDeliveryNoteForClientOrder() {
      if (this.clientOrderDetails.statusCode !== ClientOrderStatus.PREPARED) {
        return;
      }

      this.$router.push({
        name: 'clientOrderIssueInvoice',
        params: {
          clientOrderId: this.clientOrderId,
        },
        query: {
          isDeliveryNote: true,
        },
      });
    },

    async completeClientOrder() {
      if (!await this.$bvModal.msgBoxConfirm('Confirmi finalizarea comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false)) {
        return;
      }

      await this.performCompleteClientOrder(this.clientOrderId);

      this.loadInitialData();
    },

    async reopenClientOrder() {
      if (!await this.$bvModal.msgBoxConfirm('Confirmi redeschiderea comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false)) {
        return;
      }

      await this.performReopenClientOrder(this.clientOrderId);

      this.loadInitialData();
    },

    async cancelClientOrder() {
      if (this.items.some((item) => item.inventorySelectionEntries && item.inventorySelectionEntries.length)) {
        toasts.error('Comanda nu poate fi anulată dacă are poziții de selecție stoc prezente');
        return;
      }

      if (!(await this.$bvModal.msgBoxConfirm('Confirmi anularea comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      await this.performClientOrderCancellation(this.clientOrderId);

      this.loadInitialData();
    },

    async loadAttachmentFiles() {
      if (!this.clientOrderId) {
        return;
      }

      let result;
      this.areFileAttachmentsLoaded = false;

      try {
        result = await this.performFileAttachmentsListLookup({ clientOrderId: this.clientOrderId });
      } catch {
        toasts.error('Eroare la încărcarea fișierelor');
        result = [];
      }

      this.areFileAttachmentsLoaded = true;
      this.fileAttachments = result;
    },

    async addAttachmentFile(fileAttachment, callback) {
      if (!fileAttachment.file) {
        toasts.error('Nu ai ales încă un fișier');
        return;
      }

      try {
        const response = await this.performFileAttachmentsAdd({ fileAttachment, clientOrderId: this.clientOrderId });

        if (response) {
          await this.loadAttachmentFiles();
          toasts.success('Fișierul a fost adăugat cu succes');
          callback(true);
        } else {
          toasts.error('Eroare la adăugarea fișierului');
          callback(false);
        }
      } catch {
        toasts.error('Eroare la adăugarea fișierului');
        callback(false);
      }
    },

    async deleteAttachmentFile(fileAttachmentId) {
      try {
        const result = await this.performFileAttachmentDelete({ clientOrderId: this.clientOrderId, fileId: fileAttachmentId });
        if (result) {
          await this.loadAttachmentFiles();
        } else {
          toasts.error('Eroare la eliminarea fișierului');
        }
      } catch {
        toasts.error('Eroare la eliminarea fișierului');
      }
    },

    async loadInitialData(providedClientOrderDetails) {
      if (!this.clientOrderId) {
        return;
      }

      let result;
      let errorState = false;
      try {
        result = await Promise.all([
          providedClientOrderDetails
            ? Promise.resolve(providedClientOrderDetails)
            : this.performClientOrderDetailsQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderLinesByIdQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderDiscountRulesQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderInventorySelectionEntriesQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderInvoicesQuery({ clientOrderId: this.clientOrderId }),
          this.performFileAttachmentsListLookup({ clientOrderId: this.clientOrderId }),
          this.performClientOrderDeliveryNotesQuery({ clientOrderId: this.clientOrderId }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = [];
      }

      this.clientOrderDetails = result.length && result[0];
      this.items = result.length && result[1].map((item) => Object.assign(item, { _showDetails: this.clientOrderDetails.statusCode !== ClientOrderStatus.SUBMITTED }));
      this.discountRules = result.length && result[2];
      // eslint-disable-next-line no-mixed-operators
      this.issuedInvoices = result.length && result[4] || [];
      // eslint-disable-next-line no-mixed-operators
      this.fileAttachments = result.length && result[5] || [];
      // eslint-disable-next-line no-mixed-operators
      this.deliveryNotes = result.length && result[6] || [];

      this.invoices = [
        ...this.issuedInvoices.map((x) => ({ ...x, documentType: 'FAC', isDeliveryNote: false })),
        ...this.deliveryNotes.map((x) => ({ ...x, documentType: 'AVZ', isDeliveryNote: true })),
      ];

      // eslint-disable-next-line no-mixed-operators
      const inventorySelectionEntries = result.length && result[3] || [];
      this.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.inventorySelectionEntries = inventorySelectionEntries.filter((entry) => entry.clientOrderLineId === item.clientOrderLineId);
      });

      if (errorState) {
        return;
      }

      // TODO: what if order ID invalid

      if (result) {
        this.isLoaded = true;
      }

      this.areFileAttachmentsLoaded = true;

      // If the order is in the DRAFT state, move to the appropiate route
      if (this.clientOrderDetails.statusCode === ClientOrderStatus.DRAFT && this.performRouteChange) {
        this.editClientOrder();
        return;
      }

      // Show sales remarks as modal if the current user has not yet dismissed it and is not the order creator
      // and also if they have changed (identified using MD5)
      if (this.clientOrderDetails.salesRemarks
        && this.clientOrderDetails.createdByGuid !== this.userGuid
        && localStorage.getItem(`sales_remarks_${this.clientOrderDetails.clientOrderId}_${this.userGuid}`) !== new MD5().update(this.clientOrderDetails.salesRemarks).digest('hex')) {
        try {
          this.persistHideSalesRemarks = false;
          this.$bvModal.show('sales-agent-notes-modal');
        } catch {
          this.$nextTick(() => {
            // eslint-disable-next-line no-alert
            alert(this.clientOrderDetails.salesRemarks);
          });
        }
      }
    },

    async editClientOrder() {
      if (this.clientOrderDetails.statusCode !== ClientOrderStatus.DRAFT) {
        return;
      }

      this.$router.push({
        name: 'clientOrderDetails',
        params: {
          clientOrderId: this.clientOrderId,
        },
      });
    },

    showDownloadInvoicePdfModal(item) {
      this.$nextTick(() => this.$refs.downloadInvoiceModal.showModal({ issuingYear: item.issuingYear, invoiceIndex: item.number, context: item }, item.documentType));
    },

    showDownloadDispatchNotePdfModal(item) {
      this.$nextTick(() => this.$refs.downloadInvoiceModal.showModal({ issuingYear: item.issuingYear, invoiceIndex: item.number, context: item }, 'AVX'));
    },

    onInvoiceDownloadStarted(payload) {
      if (payload.modalType === 'FAC') {
        // eslint-disable-next-line no-param-reassign
        payload.context.isExported = true;
      }
    },

    clipboardSuccessHandler() {
      toasts.success('Textul a fost copiat');
    },

    clipboardErrorHandler() {
      toasts.error('Textul nu a putut fi copiat');
    },

    async tryPopulatePicklist() {
      this.picklistModalStatusText = null;
      this.picklistPopulated = false;
      this.picklistIsPopulating = true;
      this.picklistBusy = true;

      try {
        try {
          this.picklistModalStatusText = 'Se conectează';

          this.picklistModalStatusText = 'Se preiau operatorii';
          const warehouseOperators = await wmsHttpClient.get(`/user-app/warehouse-operator/list?warehouseId=${wmsWarehouseId}&warehouseOperatorStatus=Active`);
          this.picklistWarehouseOperators = warehouseOperators.data.map((x) => Object.assign(x, { text: x.warehouseOperatorFullName, value: x.id }));

          this.picklistModalStatusText = 'Se preiau listele aflate în starea de ciornă';
          const draftPicklists = await wmsHttpClient.get(`/user-app/picklist/list?warehouseId=${wmsWarehouseId}&picklistStatuses=Draft`);
          this.picklistDraftPicklists = draftPicklists.data.map((x) => Object.assign(x, { text: x.listCode, value: x.id }));

          this.picklistModalStatusText = 'Se preiau produsele';
          const items = await wmsHttpClient.get('/user-app/product/list');
          const allProductsDict = {};
          items.data.forEach((item) => { allProductsDict[item.productCode.toLowerCase()] = item; });

          const distinctProductsDict = {};
          const distinctProducts = [];

          const unmatchedLines = [];

          const foundErrors = [];
          const foundIssues = [];

          this.picklistSourceLines.forEach((item) => {
            const wmsProduct = allProductsDict[item.catalogItemCode.toLowerCase()];

            if (!wmsProduct) {
              unmatchedLines.push(item);
              return;
            }

            Object.assign(item, { wmsProduct });

            if (!distinctProductsDict[wmsProduct.productCode.toLowerCase()]) {
              distinctProducts.push(wmsProduct);
              distinctProductsDict[wmsProduct.productCode.toLowerCase()] = wmsProduct;
            }
          });

          if (unmatchedLines.length) {
            this.picklistModalStatusText = null;
            const complaint = unmatchedLines.map((line) => line.catalogItemCode).join(', ');
            await this.$bvModal.msgBoxOk(`Unele produse nu au echivalent în WMS: ${complaint}`);
            return;
          }

          this.picklistModalStatusText = 'Se preiau loturile';

          for (let idx = 0; idx < distinctProducts.length; idx += 1) {
            const wmsProduct = distinctProducts[idx];
            this.picklistModalStatusText = `Se preiau loturile pentru produsul ${wmsProduct.description} (${wmsProduct.productCode})`;

            // eslint-disable-next-line no-await-in-loop
            const batches = await wmsHttpClient.get(`/user-app/product/batches/${wmsProduct.id}`);
            Object.assign(wmsProduct, { batches: batches.data });
          }

          this.picklistModalStatusText = 'Se corelează loturile';

          for (let ii = 0; ii < this.picklistSourceLines.length; ii += 1) {
            this.picklistModalStatusText = `Se corelează loturile pentru linia ${ii + 1}`;

            const sourceLine = this.picklistSourceLines[ii];
            const wmsProduct = distinctProductsDict[sourceLine.catalogItemCode.toLowerCase()];

            if (!wmsProduct) {
              Object.assign(sourceLine, { matchingProductError: true });

              this.picklistModalStatusText = null;
              // eslint-disable-next-line no-await-in-loop
              foundErrors.push(`Produsul ${sourceLine.lineDetails} (cod ${sourceLine.catalogItemCode}) nu are cod de produs echivalent în WMS`);
              // eslint-disable-next-line no-continue
              continue;
            }

            const batchAllocations = [];

            for (let jj = 0; jj < sourceLine.inventorySelectionEntries.length; jj += 1) {
              this.picklistModalStatusText = `Se corelează loturile pentru linia ${ii + 1}/${jj + 1}`;

              const entry = sourceLine.inventorySelectionEntries[jj];

              console.log(wmsProduct);

              const matchingBatch = entry.inventoryBatchCode
                ? wmsProduct.batches.find((b) => b.batchCode.toLowerCase() === entry.inventoryBatchCode.toLowerCase())
                : null;

              if (!matchingBatch && entry.inventoryBatchCode) {
                foundIssues.push(`Produsul ${sourceLine.lineDetails} (cod ${sourceLine.catalogItemCode}) nu are lot ${entry.inventoryBatchCode} echivalent în WMS și va fi alocat fără lot pe celula implicită`);
                Object.assign(entry, { matchingBatchError: true });
              }

              Object.assign(entry, { matchingBatch });

              batchAllocations.push({
                entry,
                matchingBatch,
              });
            }

            Object.assign(sourceLine, batchAllocations);
          }

          if (foundErrors.length) {
            await this.$bvModal.msgBoxOk([...foundErrors, ...foundIssues].join('; '));
            this.picklistModalStatusText = 'Alocarea nu a putut fi efectuată';
            this.picklistPopulated = false;
          } else if (foundIssues.length) {
            await this.$bvModal.msgBoxOk([...foundErrors, ...foundIssues].join('; '));
            this.picklistModalStatusText = 'Alocarea a fost efectuată cu anumite avertizări';
            this.picklistPopulated = true;
          } else {
            this.picklistModalStatusText = 'Alocarea a fost efectuată cu succes';
            this.picklistPopulated = true;
          }
        } catch (ex) {
          this.picklistModalStatusText = `Eroare: ${ex}`;
          this.picklistPopulated = false;
        }
      } finally {
        this.picklistIsPopulating = false;
        this.picklistBusy = false;
      }
    },

    createPicklistItemModels() {
      const populatePicklistItemModels = [];

      for (let ii = 0; ii < this.picklistSourceLines.length; ii += 1) {
        const sourceLine = this.picklistSourceLines[ii];
        if (!sourceLine.wmsProduct) {
          throw new Error(`Linia ${ii + 1} nu are un produs alocat`);
        }

        for (let jj = 0; jj < sourceLine.inventorySelectionEntries.length; jj += 1) {
          const entry = sourceLine.inventorySelectionEntries[jj];

          let specificInfo = `Linia ${ii + 1}`;
          if (entry.matchingBatchError) {
            specificInfo += `; lot inițial ${entry.inventoryBatchCode}`;
          }

          populatePicklistItemModels.push({
            productId: sourceLine.wmsProduct.id,
            productBatchId: (entry.matchingBatch && entry.matchingBatch.id) || null,
            requestedProductsCount: entry.quantity,
            specificInformation: specificInfo,
          });
        }
      }

      return populatePicklistItemModels;
    },

    async handleCopyToDraftPicklist() {
      if (!this.picklistPopulated) {
        return;
      }

      const draftPicklist = this.picklistDraftPicklists.find((x) => x.id === this.picklistSelectedDraftPicklist);
      if (!draftPicklist) {
        return;
      }

      try {
        this.picklistBusy = true;

        const populatePicklistItemModels = this.createPicklistItemModels().map((x) => Object.assign(x, {
          picklistId: this.picklistSelectedDraftPicklist,
          storageCellId: wmsFallbackStorageCellId,
          warehouseId: wmsWarehouseId,
          specificInformation: `Comanda ${this.clientOrderDetails.clientOrderCode}; ${x.specificInformation}`,
        }));

        const successLines = [];
        let lastIdx = 0;

        try {
          for (let idx = 0; idx < populatePicklistItemModels.length; idx += 1) {
            this.picklistModalStatusText = `Se adaugă linia ${idx + 1} din ${populatePicklistItemModels.length}`;

            // eslint-disable-next-line no-await-in-loop
            const picklistItem = await wmsHttpClient.post('/user-app/picklist-item', populatePicklistItemModels[idx]);
            lastIdx += 1;

            successLines.push({ index: idx + 1, line: populatePicklistItemModels[idx], picklistItemId: picklistItem.data });
          }

          try {
            this.warehouseRemarksBeingEdited = false;
            this.warehouseRemarksUpdateText = this.clientOrderDetails.warehouseRemarks
              ? `${this.clientOrderDetails.warehouseRemarks}\r\nLista de culegere: ${draftPicklist.listCode}`
              : `Lista de culegere: ${draftPicklist.listCode}`;
            await this.performClientOrderWarehouseRemarksSubmission({ clientOrderId: this.clientOrderId, remarks: this.warehouseRemarksUpdateText });
            this.clientOrderDetails.warehouseRemarks = this.warehouseRemarksUpdateText;
          } catch {
            // Could not update remarks
          }

          toasts.success('Liniile au fost adăugate cu succes');
          this.$refs.picklistPopulationModal.hide();
        } catch (ex) {
          console.error(ex);
          this.picklistModalStatusText = `Eroare la adăugarea liniei ${lastIdx + 1}`;
          toasts.error(`Au fost salvate ${successLines.length} linii dar a ${lastIdx + 1}-a linie a întâmpinat o eroare: ${ex}`);
        }
      } catch (ex) {
        console.log(ex);
        // eslint-disable-next-line no-alert
        alert(ex);
      } finally {
        this.picklistBusy = true;
      }
    },

    async handlePicklistPopulation() {
      if (!this.picklistPopulated) {
        return;
      }

      try {
        const populatePicklistItemModels = this.createPicklistItemModels();

        const postData = {
          warehouseId: wmsWarehouseId,
          orderCode: this.clientOrderDetails.clientOrderCode,
          specificInformation: this.picklistSpecificInformation,
          allowInsufficientInventoryFallback: true,
          fallbackStorageCellId: wmsFallbackStorageCellId,
          populatePicklistItemModels,
          assignedWarehouseOperatorId: this.picklistSelectedWarehouseOperatorId,
          createAsDraft: this.picklistCreateAsDraft,
        };

        console.log(postData);

        const result = await wmsHttpClient.post('/user-app/picklist/populate', postData);
        console.log(result);

        if (result && result.data) {
          await this.$bvModal.msgBoxOk(`Lista de culegere a fost creată (ID: ${result.data})`);
        } else {
          // eslint-disable-next-line no-alert
          alert(result);
        }

        this.$refs.picklistPopulationModal.hide();
      } catch (ex) {
        console.log(ex);
        // eslint-disable-next-line no-alert
        alert(ex);
      }
    },
  },
};
</script>
