<template>
  <b-container fluid>
    <div v-if="isLoaded && clientOrderDetails">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>{{ pageTitle }}</h2>
        <b-button-toolbar class="pb-3">
          <b-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="{ name: 'clientOrderDetails', params: { clientOrderId } }"><i class="fas fa-caret-left"/><span class="d-none d-lg-inline">&nbsp;Înapoi</span></b-button>
        </b-button-toolbar>

        <b-tooltip target="navigateBackToolbarButton" placement="topleft" custom-class="d-lg-none">Înapoi</b-tooltip>
      </div>

      <!-- Order details card -->
      <b-card>
        <div slot="header">
          Detalii comandă <strong>{{ clientOrderDetails.clientOrderCode }}</strong>&nbsp;<client-order-status-badge :status-code="clientOrderDetails.statusCode" /> din <strong>{{ clientOrderDetails.createdAt | displayAsDate }}</strong> creată de <strong>{{ clientOrderDetails.createdByFullName }}</strong>
        </div>
        <div class="row">
          <b-form-group label="Client (nume)" class="col-md-4">
            <b-input v-model="clientOrderDetails.clientName" readonly="readonly"></b-input>
          </b-form-group>

          <b-form-group label="Client (cod)" class="col-md-4 col-lg-4">
            <b-input v-model="clientOrderDetails.clientCode" readonly="readonly"></b-input>
          </b-form-group>

          <b-form-group label="Adresa livrare" class="col-md-12 col-lg-4" v-if="clientOrderDetails.clientDeliveryAddressId">
            <b-input v-model="clientOrderDetails.clientDeliveryAddressName" readonly="readonly"></b-input>
          </b-form-group>

          <b-form-group label="Gestionar asociat" class="col-12" v-if="clientOrderDetails.inventoryHandledByGuid">
            <b-input v-model="clientOrderDetails.inventoryHandledByFullName" readonly="readonly"></b-input>
          </b-form-group>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <label class="col-md-12 col-lg-12">Observații ag. vânzări</label>
            <blockquote class="col-md-12 col-lg-12 blockquote preserve-newlines" v-if="clientOrderDetails.salesRemarks">{{clientOrderDetails.salesRemarks}}</blockquote>
            <blockquote class="col-md-12 col-lg-12 blockquote" v-if="!clientOrderDetails.salesRemarks"><em>Fără observații</em></blockquote>
          </div>
          <div class="col-sm-12 col-md-6">
            <label class="col-md-12 col-lg-12">Observații gestionar</label>
            <blockquote class="col-md-12 col-lg-12 blockquote" v-if="clientOrderDetails.warehouseRemarks">{{clientOrderDetails.warehouseRemarks}}</blockquote>
            <blockquote class="col-md-12 col-lg-12 blockquote" v-if="!clientOrderDetails.warehouseRemarks"><em>Fără observații</em></blockquote>
          </div>
        </div>
      </b-card>

      <b-card>
        <div slot="header">
          {{ pageDetailsText }}
        </div>

        <div class="row">
          <b-form-group label="Nume delegat" class="col-md-4" for="delegateName">
            <multiselect
              ref="delegateName"
              id="delegateName"
              v-model="selectedDispatchDelegate"
              trackBy="numeDelegat"
              select-label=""
              selected-label=""
              deselect-label=""
              :custom-label="dispatchDelegateCustomLabel"
              :show-labels="false"
              :searchable="true"
              :options="dispatchDelegateOptions"
              :loading="dispatchDelegateOptionsLoading"
              :internal-search="false"
              :allow-empty="false"
              :options-limit="20"
              :limit="5"
              :limit-text="limitText"
              :tabindex="1"
              @search-change="asyncLookupDispatchDelegate"
              @select="dispatchDelegateSelected"
            >
            </multiselect>
          </b-form-group>

          <b-form-group label="Tip document" class="col-md-4 col-lg-4">
            <b-input v-model="tipActIdentitate"></b-input>
          </b-form-group>

          <b-form-group label="Seria / Nr." class="col-md-4 col-lg-4">
            <b-input-group>
              <b-input v-model="serieActIdentitate"></b-input>
              <b-input v-model="numarActIdentitate"></b-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Emitent" class="col-md-4 col-lg-4">
            <b-input v-model="emitentActIdentitate"></b-input>
          </b-form-group>

          <b-form-group label="Mijloc transport (tip/nr.)" class="col-md-4 col-lg-4">
            <b-input-group>
              <b-input v-model="mijlocTransport"></b-input>
              <b-input v-model="numarMijlocTransport"></b-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Referință client" class="col-md-8 col-lg-4">
            <b-input v-model="clientReference"></b-input>
          </b-form-group>

          <b-form-group label="Contract client" class="col-md-4 col-lg-4">
            <b-input v-model="clientContract"></b-input>
          </b-form-group>

          <b-form-group label="Comandă client" class="col-md-4 col-lg-4">
            <b-input v-model="clientOrder"></b-input>
          </b-form-group>

          <b-form-group v-if="this.isDeliveryNote" label="Aviz expediție" class="col-md-4 col-lg-4">
            <b-input v-model="despatchDocument"></b-input>
          </b-form-group>

          <b-form-group v-if="this.isDeliveryNote" label="NIR client" class="col-md-4 col-lg-4">
            <b-input v-model="clientReceiptDocument"></b-input>
          </b-form-group>

          <b-form-group label="Observații" class="col-md-12 col-lg-12">
            <b-textarea v-model="observatiiFactura"></b-textarea>
          </b-form-group>
        </div>
        <div slot="footer">
            <b-button type="button" variant="success" @click="issueAction" :disabled="xhrRequestRunning" class="mx-1 p-2"><i class="fas fa-check"/><span class="d-none d-sm-inline">&nbsp;Continuă</span></b-button>
            <b-button type="button" variant="danger" :to="{ name: 'clientOrderDetails', params: { clientOrderId } }" :disabled="xhrRequestRunning" class="mx-1 p-2"><i class="fas fa-times"/><span class="d-none d-sm-inline">&nbsp;Abandonează</span></b-button>
        </div>
      </b-card>

      <br>

      <b-alert v-if="doesOrderContainRows && !isEntireRequestedQuantityAssigned" show variant="warning">
        <span>Există poziții la care nu este alocat stoc pentru întreaga cantitate comandată</span>
      </b-alert>

      <b-alert v-if="doesOrderContainRows &&isTooMuchQuantityAssigned" show variant="danger">
        <span>Există poziții care au alocate poziții de stoc cu o cantitate mai mare decât cea comandată</span>
      </b-alert>

      <!-- Orer rows table -->
      <b-table
        show-empty
        small
        striped
        no-footer-sorting
        ref="orderLinesTable"
        empty-text="Comanda nu are poziții"
        :foot-clone="true"
        :items="items"
        :fields="tableFields"
        :busy.sync="tableBusy"
        class="tweaked-table">

        <template v-slot:cell(catalogItemCode)="row">
          <span>{{ row.value }}</span>
          <b-badge
            v-if="row.item.isService"
            variant="primary"
            class="mr-1">
            Serviciu
          </b-badge>
          <br>
          <span>{{ row.item.lineDetails }}</span>
          <span class="small" v-if="row.item.inventoryBatchCode" style="display: block;">
            Lot: {{ row.item.inventoryBatchCode }}<span v-if="row.item.inventoryBatchExpiryDate"> | Exp: {{ row.item.inventoryBatchExpiryDate | displayAsDate }}</span>
          </span>
        </template>

        <template v-slot:head(quantity)="data">
          <span v-if="clientOrderDetails.statusCode === ClientOrderStatus.DRAFT || clientOrderDetails.statusCode === ClientOrderStatus.SUBMITTED">
            {{ data.label }}
          </span>
          <span v-else>
            Cant. cerută /
            <br/>
            pregătită
          </span>
        </template>

        <template v-slot:cell(lineDiscountValue)="row">
          {{ row.value | displayAsDecimal(2) }}<br>
          {{ row.item.vatDiscountValue | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(unitPrice)="row">
          {{ row.value | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(unitDiscountPercentage)="row">
         {{ row.value | displayAsPercentage(4) }}
         <br>
         {{ row.item.unitDiscountValue | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(quantity)="row">
          {{ row.value | displayAsDecimal(3) }}
          <span v-if="clientOrderDetails.statusCode !== ClientOrderStatus.DRAFT && clientOrderDetails.statusCode !== ClientOrderStatus.SUBMITTED && !row.item.isService">
            <br>
            {{ row.item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0) | displayAsDecimal(3) }}
          </span>
        </template>

        <template v-slot:cell(vatPercentage)="row">
          {{ row.value | displayAsPercentage }}
          <br>
          {{ row.item.vatValue | displayAsDecimal(2) }}
        </template>

        <template v-slot:cell(totalValue)="row">
          {{ (row.item.lineValue - row.item.lineDiscountValue + row.item.vatValue - row.item.vatDiscountValue) | displayAsDecimal(2) }}
        </template>

        <template #row-details="row">
          <div>
            <div v-if="row.item.inventorySelectionEntries && row.item.inventorySelectionEntries.length">
              <div class="d-flex flex-row" v-for="entry in row.item.inventorySelectionEntries" :key="entry.clientOrderLineInventorySelectionId">
                <div class="inventory-selection-index-padding-col" role="cell" aria-hidden="true"></div>
                <div class="d-flex flex-row inventory-selection-entry-meta-col">
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Inventory code cell">
                    <span><strong class="mr-1">Gest.:</strong>{{ entry.inventoryCode }}</span>
                  </div>
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Inventory account number cell">
                    <span><strong class="mr-1">Cont:</strong>{{ entry.inventoryAccountNumber }}</span>
                  </div>
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Inventory batch code cell">
                    <span class="mr-1"><strong>Lot:</strong></span>
                    <span v-if="entry.inventoryBatchId">{{ entry.inventoryBatchCode }}</span>
                    <span v-else>-</span>
                  </div>
                  <div class="px-1 inventory-selection-label-col text-left" role="cell" aria-label="Expiration date cell">
                    <span><strong class="mr-1">Exp.:</strong></span>
                    <span v-if="entry.inventoryBatchExpiryDate">{{ entry.inventoryBatchExpiryDate | displayAsDate }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="inventory-selection-value-col text-right" role="cell" aria-label="Inventory unit price cell">
                  <span v-if="canViewInventoryPrice">{{ entry.inventoryUnitPrice | displayAsDecimal(5) }}</span>
                  <span v-else>-</span>
                </div>
                <div class="inventory-selection-value-col" role="cell" aria-hidden="true"></div>
                <div class="inventory-selection-value-col text-right" role="cell" aria-label="Quantity cell">
                  <span>{{ entry.quantity | displayAsDecimal(3) }}</span>
                </div>
                <div class="inventory-selection-value-col text-right" role="cell" aria-label="Total value cell">
                  <span v-if="canViewInventoryPrice">{{ entry.totalValue | displayAsDecimal(2) }}</span>
                  <span v-else>-</span>
                </div>
                <div class="px-1 inventory-selection-storekeeper-col text-center" role="cell" aria-label="Chosen by cell">
                  <span class="mr-1"><strong>Sel.:</strong></span>
                  <user-name-badge :user-name="entry.selectionPerformedByFullName"/> la {{ entry.selectionPerformedAt | displayAsDate }}
                </div>
              </div>
            </div>
          </div>
          <!--
          <b-table-simple small>
            <b-thead v-if="row.item.inventorySelectionEntries && row.item.inventorySelectionEntries.length">
              <b-tr>
                <b-th>Gestiune</b-th>
                <b-th>Cont</b-th>
                <b-th v-if="canViewInventoryPrice">Preț gest.</b-th>
                <b-th>Nr. lot</b-th>
                <b-th>Data exp.</b-th>
                <b-th>Cantitate</b-th>
                <b-th v-if="canViewInventoryPrice">Valoare</b-th>
                <b-th>Ales de</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="row.item.inventorySelectionEntries && row.item.inventorySelectionEntries.length">
              <b-tr v-for="entry in row.item.inventorySelectionEntries" :key="entry.clientOrderLineInventorySelectionId">
                <b-td>{{ entry.inventoryCode }}</b-td>
                <b-td>{{ entry.inventoryAccountNumber }}</b-td>
                <b-td v-if="canViewInventoryPrice">{{ entry.inventoryUnitPrice | displayAsDecimal(5) }}</b-td>
                <b-td><span v-if="entry.inventoryBatchId">{{ entry.inventoryBatchCode }}</span></b-td>
                <b-td><span v-if="entry.inventoryBatchId">{{ entry.inventoryBatchExpiryDate | displayAsDate }} </span></b-td>
                <b-td>{{ entry.quantity | displayAsDecimal(3) }}</b-td>
                <b-td v-if="canViewInventoryPrice">{{ entry.totalValue | displayAsDecimal(2) }}</b-td>
                <b-td>{{ entry.selectionPerformedByFullName }} la {{ entry.selectionPerformedAt | displayAsDate }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          -->
        </template>

        <template v-slot:foot(catalogItemCode)>
          TOTAL
        </template>

        <template v-slot:foot(quantity)>
          {{ items.filter(i => !i.isService).reduce((s, i) => s + i.quantity, 0) | displayAsDecimal(3) }}
          <span v-if="clientOrderDetails.statusCode !== ClientOrderStatus.DRAFT && clientOrderDetails.statusCode !== ClientOrderStatus.SUBMITTED">
            <br>
            {{ items.filter(i => !i.isService).reduce((s, i) => s + i.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0), 0) | displayAsDecimal(3) }}
          </span>
        </template>

        <template v-slot:foot(lineDiscountValue)>
          {{ items.reduce((s, i) => s + i.lineDiscountValue, 0) | displayAsDecimal(2) }}<br>
          {{ items.reduce((s, i) => s + i.vatDiscountValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(lineValue)>
          {{ items.reduce((s, i) => s + i.lineValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(vatPercentage)>
          {{ items.reduce((s, i) => s + i.vatValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(totalValue)>
          {{ items.reduce((s, i) => s + i.lineValue - i.lineDiscountValue + i.vatValue - i.vatDiscountValue, 0) | displayAsDecimal(2) }}
        </template>

        <!-- The rest of the footer cells should be empty -->
        <template v-slot:foot()>&nbsp;</template>
      </b-table>

    </div>
  </b-container>
</template>

<style lang="scss">
.multiselect-product-name {
  overflow-x: hidden;
}

.col-min-w-250px {
  min-width: 250px;
}

.col-w-200px {
  width: 200px;
}

.col-numeric-value {
  width: 105px;
}
</style>

<script>
import BigNumber from 'bignumber.js';
import { mapState, mapGetters, mapActions } from 'vuex';

import toasts from '@/services/toasts';

import UserNameBadge from '../components/UserNameBadge.vue';
import ClientOrderStatusBadge from '../components/ClientOrderStatusBadge.vue';

import { ClientOrderStatus } from '../constants';

const { DateTime } = require('luxon');
const _ = require('lodash');

export default {
  name: 'IssueInvoiceForClientOrder',
  components: {
    ClientOrderStatusBadge,
    UserNameBadge,
  },
  data() {
    const freshData = {
      ClientOrderStatus,

      selectedDispatchDelegate: null,
      dispatchDelegateOptions: [],
      dispatchDelegateOptionsLoading: false,

      numeDelegat: '',
      tipActIdentitate: '',
      serieActIdentitate: '',
      numarActIdentitate: '',
      emitentActIdentitate: '',
      observatiiFactura: '',
      mijlocTransport: '',
      numarMijlocTransport: '',

      clientReference: '',
      clientContract: '',
      clientOrder: '',
      despatchDocument: '',
      clientReceiptDocument: '',

      isDeliveryNote: false,

      isLoaded: false,
      clientOrderId: null,
      clientOrderDetails: null,

      tableBusy: false,

      tableFields: [
        {
          key: 'catalogItemCode', label: 'Produs', thClass: 'col-min-w-250px', class: 'col-min-w-250px text-left',
        },
        {
          key: 'unitPrice', label: 'Preț', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'unitDiscountPercentage',
          label: 'Discount',
          thClass: 'col-numeric-value',
          class: 'col-numeric-value text-right',
          tdClass: (val, key, item) => {
            if (item.unitDiscountPercentage === 1) {
              return 'table-secondary';
            }
            if (item.unitDiscountPercentage !== 0) {
              return 'table-info';
            }
            return null;
          },
        },
        {
          key: 'quantity',
          label: 'Cant. cerută',
          thClass: 'col-numeric-value',
          class: 'col-numeric-value text-right',
          tdClass(value, key, item) {
            if (!freshData.clientOrderDetails || freshData.clientOrderDetails.statusCode !== 'PREPARING') return '';

            const totals = item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0);
            if (totals > value) return 'table-danger';
            if (totals < value) return 'table-warning';
            return 'table-success';
          },
        },
        {
          key: 'lineValue', label: 'Valoare', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'vatPercentage', label: 'TVA', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'lineDiscountValue', label: 'Val./TVA discount', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
        {
          key: 'totalValue', label: 'Val. cu TVA și discount', thClass: 'col-numeric-value', class: 'col-numeric-value text-right',
        },
      ],
      items: [],
      invoices: [],
    };

    return freshData;
  },
  computed: {
    ...mapState(['xhrRequestRunning']),
    ...mapGetters(['canViewInventoryPrice']),

    doesOrderContainRows() {
      return this.items && this.items.length;
    },

    isEntireRequestedQuantityAssigned() {
      return this.items.every((item) => {
        if (item.isService) {
          return true;
        }
        const totals = item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0);
        return totals >= item.quantity;
      });
    },

    isTooMuchQuantityAssigned() {
      return this.items.length && this.items.every((item) => {
        if (item.isService) {
          return false;
        }
        const totals = item.inventorySelectionEntries.reduce((acc, entry) => acc + entry.quantity, 0);
        return totals > item.quantity;
      });
    },

    pageTitle() {
      return this.isDeliveryNote ? 'Emitere aviz' : 'Emitere factură';
    },

    pageDetailsText() {
      return this.isDeliveryNote ? 'Detalii aviz' : 'Detalii facturare';
    },

    issueAction() {
      return this.isDeliveryNote ? this.issueDeliveryNoteForClientOrder : this.issueInvoiceForClientOrder;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.isLoaded = false;
      // eslint-disable-next-line no-param-reassign
      vm.clientOrderId = to.params.clientOrderId;
      // eslint-disable-next-line no-param-reassign
      vm.isDeliveryNote = to.query.isDeliveryNote;
      vm.loadInitialData();
    });
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    this.clientOrderId = to.params.clientOrderId;
    this.isDeliveryNote = to.query.isDeliveryNote;
    try {
      await this.loadInitialData();
    } finally {
      next();
    }
  },

  // Need to use this to allow Vue HMR to work
  created() {
    this.loadInitialData();

    // Debounce the async lookup so we don't swamp the server
    this.asyncLookupDispatchDelegate = _.debounce(this.asyncLookupDispatchDelegate, 450);
  },

  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
    displayAsPercentage(value, decimals = 0) {
      return `${new BigNumber(value).times(100).decimalPlaces(decimals).toString()} %`;
    },
  },

  methods: {
    ...mapActions([
      'performClientOrderLinesByIdQuery',
      'performClientOrderDetailsQuery',
      'performClientOrderInventorySelectionEntriesQuery',
      'performClientOrderLineSubmission',
      'performClientOrderLineDeletion',
      'performIssueInvoiceForClientOrder',
      'performIssueDeliveryNoteForClientOrder',
      'performClientOrderInvoicesQuery',
      'performDispatchDelegateLookup',
    ]),

    selectAll(evt) {
      evt.target.select();
    },

    dispatchDelegateCustomLabel({ numeDelegat }) {
      return numeDelegat;
    },

    async asyncLookupDispatchDelegate(lookupTerm) {
      this.dispatchDelegateOptionsLoading = true;
      this.dispatchDelegateOptions = [];

      try {
        this.dispatchDelegateOptions = await this.performDispatchDelegateLookup();
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }

      if (!!lookupTerm && lookupTerm.toLowerCase() !== this.numeDelegat.toLowerCase()
        && this.dispatchDelegateOptions.every((dd) => lookupTerm.toLowerCase() !== dd.numeDelegat.toLowerCase())) {
        this.dispatchDelegateOptions.push({
          numeDelegat: lookupTerm,
          tipActIdentitate: this.tipActIdentitate,
          serieActIdentitate: this.serieActIdentitate,
          numarActIdentitate: this.numarActIdentitate,
          emitentActIdentitate: this.emitentActIdentitate,
          observatiiFactura: this.observatiiFactura,
          mijlocTransport: this.mijlocTransport,
          numarMijlocTransport: this.numarMijlocTransport,
        });
      }

      if (!!this.numeDelegat && lookupTerm.toLowerCase() !== this.numeDelegat.toLowerCase()
        && this.dispatchDelegateOptions.every((dd) => this.numeDelegat.toLowerCase() !== dd.numeDelegat.toLowerCase())) {
        this.dispatchDelegateOptions.push({
          numeDelegat: this.numeDelegat,
          tipActIdentitate: this.tipActIdentitate,
          serieActIdentitate: this.serieActIdentitate,
          numarActIdentitate: this.numarActIdentitate,
          emitentActIdentitate: this.emitentActIdentitate,
          observatiiFactura: this.observatiiFactura,
          mijlocTransport: this.mijlocTransport,
          numarMijlocTransport: this.numarMijlocTransport,
        });
      }

      this.dispatchDelegateOptionsLoading = false;
    },

    dispatchDelegateSelected(selection) {
      this.asyncLookupDispatchDelegate.cancel();

      this.selectedDispatchDelegate = { ...selection };

      this.numeDelegat = this.selectedDispatchDelegate.numeDelegat;
      this.tipActIdentitate = this.selectedDispatchDelegate.tipActIdentitate;
      this.serieActIdentitate = this.selectedDispatchDelegate.serieActIdentitate;
      this.numarActIdentitate = this.selectedDispatchDelegate.numarActIdentitate;
      this.emitentActIdentitate = this.selectedDispatchDelegate.emitentActIdentitate;
      this.observatiiFactura = this.selectedDispatchDelegate.observatiiFactura;
      this.mijlocTransport = this.selectedDispatchDelegate.mijlocTransport;
      this.numarMijlocTransport = this.selectedDispatchDelegate.numarMijlocTransport;
    },

    limitText(count) {
      return `și ${count} alte înregistrări`;
    },

    async issueInvoiceForClientOrder() {
      // TODO: missing prepared values check

      if (!(await this.$bvModal.msgBoxConfirm('Confirmi emiterea facturii cu datele introduse?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      try {
        const result = await this.performIssueInvoiceForClientOrder({
          clientOrderId: this.clientOrderId,
          dispatchDetails: {
            numeDelegat: this.numeDelegat || '',
            tipActIdentitate: this.tipActIdentitate || '',
            serieActIdentitate: this.serieActIdentitate || '',
            numarActIdentitate: this.numarActIdentitate || '',
            emitentActIdentitate: this.emitentActIdentitate || '',
            observatiiFactura: this.observatiiFactura || '',
            mijlocTransport: this.mijlocTransport || '',
            numarMijlocTransport: this.numarMijlocTransport || '',

            // Additional fields
            clientReference: this.clientReference || '',
            clientContract: this.clientContract || '',
            clientOrder: this.clientOrder || '',
            despatchDocument: this.despatchDocument || '',
            clientReceiptDocument: this.clientReceiptDocument || '',
          },
        });

        if (!result) {
          toasts.error('Factura nu a fost emisă');
        } else {
          toasts.success('Factura a fost emisă');
        }
      } catch (ex) {
        toasts.error(ex.response.data);
      }

      this.navigateToViewClientOrder();
    },

    async issueDeliveryNoteForClientOrder() {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi emiterea avizului cu datele introduse?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      try {
        const result = await this.performIssueDeliveryNoteForClientOrder({
          clientOrderId: this.clientOrderId,
          dispatchDetails: {
            numeDelegat: this.numeDelegat || '',
            tipActIdentitate: this.tipActIdentitate || '',
            serieActIdentitate: this.serieActIdentitate || '',
            numarActIdentitate: this.numarActIdentitate || '',
            emitentActIdentitate: this.emitentActIdentitate || '',
            observatiiFactura: this.observatiiFactura || '',
            mijlocTransport: this.mijlocTransport || '',
            numarMijlocTransport: this.numarMijlocTransport || '',

            // Additional fields
            clientReference: this.clientReference || '',
            clientContract: this.clientContract || '',
            clientOrder: this.clientOrder || '',
          },
        });

        if (!result) {
          toasts.error('Avizul nu a fost emis');
        } else {
          toasts.success('Avizul a fost emis');
        }
      } catch (ex) {
        toasts.error(ex.response.data);
      }

      this.navigateToViewClientOrder();
    },

    async loadInitialData() {
      if (!this.clientOrderId) {
        return;
      }

      try {
        await this.asyncLookupDispatchDelegate();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      let result;
      let errorState = false;
      try {
        result = await Promise.all([
          this.performClientOrderDetailsQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderLinesByIdQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderInventorySelectionEntriesQuery({ clientOrderId: this.clientOrderId }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = [];
      }

      this.clientOrderDetails = result.length && result[0];
      this.items = result.length && result[1].map((item) => Object.assign(item, { _showDetails: true }));

      this.filterLinesIfDeliveryNote();

      // eslint-disable-next-line no-mixed-operators
      const inventorySelectionEntries = result.length && result[2] || [];
      this.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.inventorySelectionEntries = inventorySelectionEntries.filter((entry) => entry.clientOrderLineId === item.clientOrderLineId);
      });

      if (errorState) {
        return;
      }

      // TODO: what if order ID invalid

      if (result) {
        this.isLoaded = true;
      }

      this.prefillObservations();

      // If the order is not in the PREPARED state, navigate to the details route
      if (this.clientOrderDetails.statusCode !== 'PREPARED') {
        this.navigateToViewClientOrder();
      }
    },

    prefillObservations() {
      if (this.clientOrderDetails.clientSystemOrderIdentifier) {
        this.observatiiFactura += 'Comanda '.concat(String(this.clientOrderDetails.clientSystemOrderIdentifier));
      }
    },

    filterLinesIfDeliveryNote() {
      if (this.isDeliveryNote) {
        this.items = this.items.filter((item) => !item.isService);
      }
    },

    async navigateToViewClientOrder() {
      this.$router.push({
        name: 'clientOrderDetails',
        params: {
          clientOrderId: this.clientOrderId,
        },
      });
    },
  },
};
</script>
