<template>
  <div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ClientOrderDetailsByCode',
  components: {
  },
  data() {
    const freshData = {
      clientOrderCode: null,
      clientOrderId: null,
    };

    return freshData;
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.isLoaded = false;
      // eslint-disable-next-line no-param-reassign
      vm.clientOrderCode = to.params.clientOrderCode;
      vm.loadInitialData();
    });
  },

  methods: {
    ...mapActions([
      'performClientOrderDetailsByCodeQuery',
    ]),

    async loadInitialData() {
      if (!this.clientOrderCode) {
        return;
      }

      let result;
      this.errorState = false;
      try {
        result = await Promise.all([
          this.performClientOrderDetailsByCodeQuery({ clientOrderCode: this.clientOrderCode }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        result = [];

        this.$router.push({
          name: 'notFound',
        });
      }

      if (result.length !== 0) {
        this.clientOrderId = result.length && result[0];

        this.$router.replace({
          name: 'clientOrderDetails',
          params: {
            clientOrderId: String(this.clientOrderId),
          },
        });
      }
    },
  },
};
</script>
