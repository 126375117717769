<template>
  <div>
    <div v-if="isLoaded && clientOrderDetails">
      <div v-if="clientOrderDetails.statusCode == ClientOrderStatus.DRAFT">
        <edit-draft-client-order @statusChanged="onOrderStatusChanged" ref="editDraft" :provided-client-order-id="clientOrderId" :provided-client-order-details="clientOrderDetails"/>
      </div>
      <div v-else>
        <view-client-order @statusChanged="onOrderStatusChanged" :provided-client-order-id="clientOrderId" :provided-client-order-details="clientOrderDetails"/>
      </div>
    </div>
    <div v-if="errorState">
      <span>A apărut o eroare la încărcarea comenzii.</span> <br>
      <button class="btn btn-primary" v-on:click="reloadPage"> Reîncarcă </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import ViewClientOrder from './ViewClientOrder.vue';
import EditDraftClientOrder from './EditDraftClientOrder.vue';
import { ClientOrderStatus } from '../constants';

export default {
  name: 'ClientOrderDetails',
  components: {
    ViewClientOrder,
    EditDraftClientOrder,
  },
  data() {
    const freshData = {
      isLoaded: false,
      ClientOrderStatus,
      clientOrderId: null,
      clientOrderDetails: null,
      errorState: false,
    };

    return freshData;
  },
  computed: {
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.isLoaded = false;
      // eslint-disable-next-line no-param-reassign
      vm.clientOrderId = String(to.params.clientOrderId);
      vm.loadInitialData();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    this.clientOrderId = to.params.clientOrderId;
    try {
      await this.loadInitialData();
    } finally {
      next();
    }
  },

  created() {
    this.loadInitialData();
  },

  methods: {
    ...mapActions([
      'performClientOrderDetailsQuery',
    ]),

    onOrderStatusChanged() {
      this.loadInitialData();
    },

    reloadPage() {
      this.loadInitialData();
    },

    async loadInitialData() {
      if (!this.clientOrderId) {
        return;
      }

      let result;
      this.errorState = false;
      try {
        result = await Promise.all([
          this.performClientOrderDetailsQuery({ clientOrderId: this.clientOrderId }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.errorState = true;

        result = [];
      }

      this.clientOrderDetails = result.length && result[0];

      if (this.errorState) {
        return;
      }

      if (result) {
        this.isLoaded = true;
      }
    },
  },
};
</script>
