<template>
  <b-container fluid>
    <div v-if="isLoaded && clientOrderDetails">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>Editare comandă</h2>
        <b-button-toolbar class="pb-3">
          <super-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="lastRoute || { name: 'clientOrder' }"><i class="fas fa-caret-left"/><span class="d-none d-lg-inline ml-1">Înapoi</span></super-button>

          <b-button-group class="mx-1">
            <b-button class="p-2" id="downloadPdfToolbarButton" :href="pdfDownloadLink" v-if="clientOrderDetails.statusCode !== ClientOrderStatus.CANCELED"><i class="fas fa-file-pdf"/><span class="d-none d-lg-inline ml-1">Descarcă PDF</span></b-button>
            <b-button class="p-2" id="downloadXlsxToolbarButton" :href="xlsxDownloadLink" v-if="clientOrderDetails.statusCode !== ClientOrderStatus.CANCELED"><i class="fas fa-file-excel"/><span class="d-none d-lg-inline ml-1">Descarcă Excel</span></b-button>
          </b-button-group>

          <super-button class="mx-1 p-2" id="submitOrderToolbarButton" @click="submitClientOrder" variant="primary"><i class="fas fa-paper-plane"/><span class="d-none d-lg-inline ml-1">Trimite<span class="d-none d-xl-inline">&nbsp;comanda</span></span></super-button>
          <super-button class="mx-1 p-2" id="cancelOrderToolbarButton" @click="cancelClientOrder" variant="danger"><i class="fas fa-times"/><span class="d-none d-lg-inline ml-1">Anulează<span class="d-none d-xl-inline">&nbsp;comanda</span></span></super-button>
        </b-button-toolbar>

        <b-tooltip target="navigateBackToolbarButton" placement="topleft" custom-class="d-lg-none">Înapoi</b-tooltip>
        <b-tooltip target="downloadPdfToolbarButton" placement="topleft" custom-class="d-lg-none">Descarcă PDF</b-tooltip>
        <b-tooltip target="downloadXlsxToolbarButton" placement="topleft" custom-class="d-lg-none">Descarcă Excel</b-tooltip>
        <b-tooltip target="submitOrderToolbarButton" placement="topleft" custom-class="d-lg-none">Trimite comanda</b-tooltip>
        <b-tooltip target="cancelOrderToolbarButton" placement="topleft" custom-class="d-lg-none">Anulează comanda</b-tooltip>
      </div>

      <!-- Order details card -->
      <b-card>
        <div slot="header">
          Detalii comandă <strong>{{ clientOrderDetails.clientOrderCode }}</strong>&nbsp;<client-order-status-badge :status-code="clientOrderDetails.statusCode" /> din <strong>{{ clientOrderDetails.createdAt | displayAsDate }}</strong> creată de <user-name-badge :user-name="clientOrderDetails.createdByFullName" />
          <b-button class="mx-1" size="sm" @click="showEventLogModal">
            <i class="fas fa-history mr-1" /><span>Istoric</span>
          </b-button>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-6">
            <label class="col-12">Client</label>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientName">
              <strong>{{ clientOrderDetails.clientName }}</strong>
              <router-link :to="{ name: 'clientDetails', params: { clientCode: clientOrderDetails.clientCode } }">
                <i class="fas fa-link ml-1 text-smaller" v-b-tooltip.hover.bottom="'Vezi detalii partener'"/>
              </router-link>
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientCode">
              {{clientOrderDetails.clientCode}}
            </blockquote>
            <blockquote class="col-12 blockquote" v-if="clientOrderDetails.clientRegistrationNo">
              {{clientOrderDetails.clientRegistrationNo}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientStreetAddress">
              {{clientOrderDetails.clientStreetAddress}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="actualAddressLocationDisplay">
              {{actualAddressLocationDisplay}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientPostalCode">
              {{clientOrderDetails.clientPostalCode}}
            </blockquote>
            <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientCountryAddress">
              {{clientOrderDetails.clientCountryAddress}}
            </blockquote>
          </div>

          <div class="col-sm-12 col-md-6">
            <label class="col-12">Adresa livrare</label>
            <div v-if="clientOrderDetails.clientDeliveryAddressId">
              <blockquote class="col-12 blockquote mb-0">
                <strong>{{clientOrderDetails.clientDeliveryAddressName}}</strong>
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientDeliveryAddressPartnerInternalCode">
                Cod: {{clientOrderDetails.clientDeliveryAddressPartnerInternalCode}}
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientDeliveryAddress">
                {{clientOrderDetails.clientDeliveryAddress}}
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="actualDeliveryAddressLocationDisplay">
                {{actualDeliveryAddressLocationDisplay}}
              </blockquote>
              <blockquote class="col-12 blockquote mb-0" v-if="clientOrderDetails.clientDeliveryAddressCountryName">
                {{clientOrderDetails.clientDeliveryAddressCountryName}}
              </blockquote>
            </div>
            <div v-else>
              <blockquote class="col-12 blockquote mb-0"> - </blockquote>
            </div>
          </div>
        </div>

        <div class="row" v-if="clientOrderDetails.clientSystemOrderIdentifier">
          <div class="col-md-4 col-lg-4 offset-md-4 offset-lg-4">
            <b-form-group label="Cod comandă în sistemul clientului">
              <blockquote class="blockquote">{{ clientOrderDetails.clientSystemOrderIdentifier }}</blockquote>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <b-form-group label="Observații" class="col-md-12 col-lg-12" v-if="salesRemarksBeingEdited">
            <b-form-textarea v-model="salesRemarksUpdateText" @blur="submitSalesRemarks"></b-form-textarea>
          </b-form-group>
          <label class="col-md-12 col-lg-12" v-if="!salesRemarksBeingEdited">Observații</label>
          <blockquote class="col-md-12 col-lg-12 blockquote preserve-newlines" v-if="!salesRemarksBeingEdited && clientOrderDetails.salesRemarks">{{clientOrderDetails.salesRemarks}}</blockquote>
          <blockquote class="col-md-12 col-lg-12 blockquote" v-if="!salesRemarksBeingEdited && !clientOrderDetails.salesRemarks"><em>Fără observații</em></blockquote>
          <div class="col-md-12 col-lg-12 text-center">
            <super-button @click="editSalesRemarks" v-if="!salesRemarksBeingEdited && !clientOrderDetails.salesRemarks">Adaugă observații</super-button>
            <super-button @click="editSalesRemarks" v-if="!salesRemarksBeingEdited && clientOrderDetails.salesRemarks">Modifică observațiile</super-button>
          </div>
        </div>
      </b-card>

      <br>

      <event-log-modal
        ref="eventLogModal"/>

      <client-order-discount-rules-alert :discount-rules="discountRules" />

      <b-alert v-if="unappliedDiscountRules.length" variant="warning" show>
        <p>Există discount-uri neaplicate pe unele dintre poziții:</p>
        <ul class="mb-0">
          <li v-for="unappliedDiscountRule in unappliedDiscountRules" v-bind:key="unappliedDiscountRule.rule">
            Cod <strong>{{unappliedDiscountRule.rule}}</strong> - cantitate gratuită <strong>{{unappliedDiscountRule.numFreebies | displayAsDecimal(3) }}</strong> pentru codul de produs <strong>{{unappliedDiscountRule.catalogItemCode}}</strong> (poz. {{unappliedDiscountRule.rowIndexes.map(idx => idx + 1).join(', ')}})
          </li>
        </ul>
      </b-alert>

      <div class="mb-3">
        <super-button @click="showClientOrderLineCopyModal"><i class="fas fa-copy mr-1" />Copiază poziții din altă comandă</super-button>
        <super-button @click="showImportLinesFromExcelModal"><i class="fas fa-file-excel mr-1" />Importă poziții din fișier Excel</super-button>
      </div>

      <!-- Order rows table -->
      <b-table
        show-empty
        small
        no-footer-sorting
        ref="orderLinesTable"
        empty-text="Comanda nu are poziții"
        :foot-clone="!currentlyEditedLine"
        :items="items"
        :fields="tableFields"
        :tbody-tr-class="rowClass"
        :busy.sync="tableBusy"
        class="tweaked-table">
        <template v-slot:cell(rowIndex)="row">
          {{ row.index + 1 }}.
        </template>
        <template v-slot:cell(catalogItemCode)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">
            <span>
              <b-badge
                variant="info"
                class="badge-lg">
                <i class="fas fa-tag mr-1" />
                <samp>{{ row.value }}</samp>
              </b-badge>

              <span>&nbsp;</span>

              <b-button
                variant="link"
                size="sm"
                v-b-tooltip.hover="'Copiază codul de produs'"
                v-clipboard:success="clipboardSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
                v-clipboard="() => row.value"><i class="far fa-copy" />
              </b-button>

              <span v-if="row.item.isService">
                &nbsp;
              </span>

              <b-badge
                v-if="row.item.isService"
                variant="primary"
                class="badge-lg ml-1">
                <i class="fas fa-briefcase" />
                Serviciu
              </b-badge>

              <span v-if="row.item.catalogItemBarCode">
                &nbsp;
              </span>

              <b-badge variant="secondary" class="badge-lg" v-if="row.item.catalogItemBarCode">
                <i class="fas fa-barcode mr-1"/>
                <samp>{{row.item.catalogItemBarCode}}</samp>
              </b-badge>
            </span>
            <br>
            <span>{{row.item.lineDetails}}</span>
          </span>
          <multiselect
            v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId"
            ref="catalogItemCode"
            id="catalogItemCode"
            v-model="currentInventoryEntry"
            track-by="trackingKey"
            no-result="Niciun rezultat nu corespunde"
            no-options="Caută după cod sau denumire"
            placeholder="Caută după cod sau denumire"
            select-label=""
            selected-label=""
            deselect-label=""
            :show-labels="false"
            :searchable="true"
            :options="currentInventoryEntryLookupValues"
            :loading="currentInventoryEntryLookupValuesLoading"
            :internal-search="false"
            :allow-empty="false"
            :options-limit="20"
            :limit="5"
            :limit-text="limitText"
            :tabindex="1"
            @search-change="asyncLookupInventory"
            @select="currentInventoryEntrySelected"
          >
            <template
              slot="noResult"
            >
              Nicio înregistrare găsită. Modifică criteriul de căutare.
            </template>
            <template
              slot="noOptions"
            >
              Nicio înregistrare disponibilă. Modifică criteriul de căutare.
            </template>
            <template
              slot="beforeList">
                <li class="multiselect__element font-weight-bold w-100 pr-0">
                  <span class="multiselect__option">
                    <div class="option__desc row">
                      <div class="option__small col-1">Categorie:</div>
                      <div class="option__small col-2">Cod:</div>
                      <div class="option__small col-5">Denumire:</div>
                      <div class="option__small col-1 text-right">Preț:</div>
                      <div class="option__small col-1 text-right">Stoc:</div>
                      <div class="option__small col-1 text-right">Rez.:</div>
                      <div class="option__small col-1 text-right">Disp.:</div>
                    </div>
                  </span>
                </li>
            </template>
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <div class="option__desc">
                <span class="option__title">{{ props.option.catalogItemCode }}</span>
              </div>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <div class="option__desc row">
                <div class="option__small col-1 overflow-hidden">{{ props.option.productCategoryName }}</div>
                <div class="option__small col-2 text-wrap text-break">{{ props.option.catalogItemCode }}</div>
                <div class="option__small col-5 overflow-hidden text-wrap text-break">
                  <b-badge
                    v-if="props.option.isService"
                    variant="primary"
                    class="badge-lg mr-1">
                    <i class="fas fa-briefcase" />
                    Serviciu
                  </b-badge>
                  <b-badge
                    v-if="props.option.barCode"
                    variant="secondary"
                    class="badge-lg mr-1">
                    <i class="fas fa-barcode mr-1" />
                    <samp>{{props.option.barCode}}</samp>
                  </b-badge>
                  <b-badge
                    variant="warning"
                    v-if="props.option.quantity <= 0 && !props.option.isService"
                    class="badge-lg mr-1">
                    <i class="fas fa-exclamation" />
                    Fără stoc
                  </b-badge>
                  <b-badge
                    v-if="props.option.discountsAvailable"
                    variant="primary"
                    class="badge-lg mr-1">
                    <i class="fas fa-percent" />
                    Discount
                  </b-badge>
                  <span>{{ props.option.productName }}</span>
                </div>
                <div class="option__small col-1 text-right">{{ props.option.listPrice | displayAsDecimal(2) }}</div>
                <div class="option__small col-1 text-right"><span v-if="props.option.loadedFromServer && !props.option.isService">{{ props.option.quantity | displayAsDecimal(3) }}</span></div>
                <div class="option__small col-1 text-right"><span v-if="props.option.loadedFromServer && !props.option.isService">{{ props.option.reservedQuantity | displayAsDecimal(3) }}</span></div>
                <div class="option__small col-1 text-right"><span v-if="props.option.loadedFromServer && !props.option.isService">{{ props.option.availableQuantity | displayAsDecimal(3) }}</span></div>
              </div>
            </template>
          </multiselect>

          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId && currentInventoryEntry" class="d-block">
            {{ row.item.lineDetails }}
          </span>
        </template>

        <template v-slot:cell(unitPrice)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">{{ row.value | displayAsDecimal(2) }}</span>
          <b-form-input
            class="text-right"
            ref="unitPrice"
            @focus="selectAll"
            @keyup="focusRef('submitEditRowButton')"
            v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId"
            v-model="currentlyEditedLineUnitPrice"
            :readonly="!currentInventoryEntry"
          />
          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId && currentInventoryEntry" class="d-block mr-1">
              Preț listă: <strong>{{ currentInventoryEntry.listPrice }}</strong>
          </span>
        </template>

        <template v-slot:cell(unitDiscountPercentage)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">
            {{ row.value | displayAsPercentage(4) }}
            <br>
            {{ row.item.unitDiscountValue | displayAsDecimal(2) }}
          </span>
          <div v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId">
            <b-input-group
              prepend="%">
              <b-form-input
                class="text-right"
                ref="unitDiscountPercentage"
                @focus="selectAll"
                @keyup="focusRef('submitEditRowButton')"
                v-model="currentlyEditedLineUnitDiscountPercentageInputValue"
                :readonly="!currentInventoryEntry"
              />
            </b-input-group>
            <b-input-group
              prepend="RON">
              <b-form-input
                class="text-right"
                ref="unitDiscountValue"
                @focus="selectAll"
                @keyup="focusRef('submitEditRowButton')"
                v-model="currentlyEditedLineUnitDiscountValue"
                :readonly="!currentInventoryEntry"
              />
            </b-input-group>
          </div>
        </template>

        <template v-slot:cell(quantity)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">{{ row.value | displayAsDecimal(3) }}</span>
          <b-input-group v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId">
            <b-form-input
              class="text-right"
              ref="quantity"
              @focus="selectAll"
              @keyup="focusRef('submitEditRowButton')"
              v-model="currentlyEditedLineQuantity"
              :readonly="!currentInventoryEntry"
            />

            <template v-slot:prepend v-if="shouldShowQuantityWarning || (quantityPerBox && currentlyEditedLineQuantity % quantityPerBox !== 0)">
              <b-input-group-text>
                <strong class="text-danger" v-if="shouldShowQuantityWarning">
                  <i class="fas fa-exclamation-triangle" v-b-tooltip title="Cantitate incorectă!"/>
                </strong>
                <strong class="text-warning" v-if="(quantityPerBox && currentlyEditedLineQuantity % quantityPerBox !== 0)">
                  <i class="fas fa-exclamation-triangle" v-b-tooltip title="Cantitate nedivizibila in cutii!"/>
                </strong>
              </b-input-group-text>
            </template>
          </b-input-group>
          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId && currentInventoryEntry && !currentInventoryEntry.isService" class="d-block mr-2">
            Stoc: <strong>{{ quantityAvailableForOrder | displayAsDecimal(3) }}</strong>
          </span>
          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId && currentInventoryEntry && !currentInventoryEntry.isService && quantityPerBox" class="d-block mr-2">
            Cant./cutie: <strong>{{ quantityPerBox | displayAsDecimal(3) }}</strong>
          </span>
        </template>

        <template v-slot:cell(lineValue)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">{{ row.value | displayAsDecimal(2)  }}</span>
          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId">{{ currentlyEditedLineValue | displayAsDecimal(2)  }}</span>
        </template>

        <template v-slot:cell(lineDiscountValue)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">{{ row.value | displayAsDecimal(2) }}<br>{{ row.item.vatDiscountValue | displayAsDecimal(2) }}</span>
          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId">{{ currentlyEditedLineDiscountValue | displayAsDecimal(2)  }}<br>{{ currentlyEditedLineVatDiscountValue | displayAsDecimal(2) }}</span>
        </template>

        <template v-slot:cell(vatPercentage)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">{{ row.value | displayAsPercentage }}</span>
          <b-form-select
            class="text-right"
            ref="vatPercentage"
            v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId"
            v-model="currentlyEditedLineVatPercentage"
            :disabled="!currentInventoryEntry"
            :options="vatPercentages"
          />
          <br>
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">{{ row.item.vatValue | displayAsDecimal(2)  }}</span>
          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId">{{ currentlyEditedLineVatValue | displayAsDecimal(2)  }}</span>
        </template>

        <template v-slot:cell(totalValue)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.clientOrderLineId !== row.item.clientOrderLineId">{{ row.item.lineValue - row.item.lineDiscountValue + row.item.vatValue - row.item.vatDiscountValue | displayAsDecimal(2) }}</span>
          <span v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId">{{ currentlyEditedLineTotalValue | displayAsDecimal(2)  }}</span>
        </template>

        <template v-slot:cell(actions)="row">
          <!-- View mode buttons -->
          <b-button-group v-if="!currentlyEditedLine">
            <super-button
              variant="primary"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="beginEditLine(row)"
            >
              <i class="fas fa-edit" />
            </super-button>
            <b-button
              variant="danger"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="submitDeleteRow(row)"
            >
              <i class="fas fa-trash-alt" />
            </b-button>
          </b-button-group>
          <!-- Edit mode buttons -->
          <b-button-group v-if="currentlyEditedLine && currentlyEditedLine.clientOrderLineId === row.item.clientOrderLineId">
            <b-button
              ref="submitEditRowButtonX"
              variant="success"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="submitEditRow(row)"
            >
              <i class="fas fa-check" />
            </b-button>
            <b-button
              v-shortkey="['ctrl', 'alt', 'c']"
              variant="danger"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="cancelEditRow(row)"
              @shortkey="cancelEditRow(row)"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-button-group>
        </template>

        <template v-slot:row-details="row">
          <div v-if="row.item.unappliedDiscountRules.length">
            <b-alert class="thin" variant="warning" show v-for="unappliedDiscountRule in row.item.unappliedDiscountRules" v-bind:key="unappliedDiscountRule.rule">
              Discount <strong>{{unappliedDiscountRule.rule}}</strong>, cu o gratuitate totală de <strong>{{unappliedDiscountRule.numFreebies | displayAsDecimal(3) }}</strong> unități, nu este aplicat!
              <b-button-group class="pt-2 pb-1" size="md">
                <super-button id="performAutomaticFreebieRowCreation" @click="handleAutomaticFreebieRowCreation(unappliedDiscountRule, row.item)" variant="primary">
                  <span><i class="fas fa-magic"/>&nbsp;Aplică automat</span>
                </super-button>
              </b-button-group>
            </b-alert>
          </div>
        </template>

        <template v-slot:foot(catalogItemCode)>
          TOTAL
        </template>

        <template v-slot:foot(lineDiscountValue)>
          {{ items.reduce((s, i) => s + i.lineDiscountValue, 0) | displayAsDecimal(2) }}<br>
          {{ items.reduce((s, i) => s + i.vatDiscountValue, 0) | displayAsDecimal(2) }}<br>
        </template>

        <template v-slot:foot(lineValue)>
          {{ items.reduce((s, i) => s + i.lineValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(vatPercentage)>
          {{ items.reduce((s, i) => s + i.vatValue, 0) | displayAsDecimal(2) }}
        </template>

        <template v-slot:foot(totalValue)>
          {{ items.reduce((s, i) => s + i.lineValue - i.lineDiscountValue + i.vatValue - i.vatDiscountValue, 0) | displayAsDecimal(2) }}
        </template>

        <!-- The rest of the footer cells should be empty -->
        <template v-slot:foot()>&nbsp;</template>
        <!-- <template slot="FOOT_lineDetails">&nbsp;</template>
        <template slot="FOOT_unitPrice">&nbsp;</template>
        <template slot="FOOT_unitDiscountPercentage">&nbsp;</template>
        <template slot="FOOT_unitDiscountValue">&nbsp;</template>
        <template slot="FOOT_quantity">&nbsp;</template>
        <template slot="FOOT_vatPercentage">&nbsp;</template> -->

        <template v-slot:foot(actions)>
          <!-- Add row button -->
          <b-button
            id="addNewLine"
            variant="success"
            size="sm"
            @click="beginEditLine()"
            v-if="!currentlyEditedLine"
            :disabled="!!currentlyEditedLine || tableBusy || xhrRequestRunning"
            v-shortkey="['ctrl', 'alt', 'a']"
            @shortkey="beginEditLine()"
          >
            <span class="d-none d-lg-inline"><i class="fas fa-plus-square mr-1" /></span><span>Adaugă <b-badge variant="secondary" class="mt-1 d-block">Ctrl + Alt + A</b-badge></span>
          </b-button>
        </template>
      </b-table>
    </div>

    <b-modal
      ref="importLinesFromExcelModal"
      size="xl"
      title="Importă linii din Excel"
      scrollable
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :busy="importLinesFromExcelProcessing"
      @ok="handleImportLinesFromExcel">
      <b-overlay :show="importLinesFromExcelProcessing" no-wrap>
        <template #overlay>&nbsp;</template>
      </b-overlay>
      <b-container fluid>
        <b-form-group label="Alege fișier Excel">
        <b-form-file
          accept=".xls,.xlsx"
          :multiple="false"
          v-model="importLinesFromExcel.file"
          @input="importLinesFromExcelFileChanged"
        />
      </b-form-group>

        <div class="row my-2">
          <b-form-group label="Col. cod produs" class="col-4">
            <b-form-select
              v-model="importLinesFromExcel.productCodeColumn"
              :options="importLinesFromExcel.columnOptions"/>
          </b-form-group>
          <b-form-group label="Col. cod bare" class="col-4">
            <b-form-select
              v-model="importLinesFromExcel.productBarcodeColumn"
              :options="importLinesFromExcel.columnOptions"/>
          </b-form-group>
          <b-form-group label="Col. cod prod. la client" class="col-4">
            <b-form-select
              v-model="importLinesFromExcel.productClientCodeColumn"
              :options="importLinesFromExcel.columnOptions"/>
          </b-form-group>
          <b-form-group label="Col. cantitate" class="col-4">
            <b-form-select
              v-model="importLinesFromExcel.quantityColumn"
              :options="importLinesFromExcel.columnOptions"/>
          </b-form-group>
          <b-form-group label="Col. preț RON f. TVA" class="col-4">
            <b-form-select
              v-model="importLinesFromExcel.unitPriceColumn"
              :options="importLinesFromExcel.columnOptions"/>
          </b-form-group>
          <b-form-group label="Discount procentual" class="col-4">
            <b-form-input
              class="text-right"
              v-model="importLinesFromExcel.discountPercentage"/>
          </b-form-group>
        </div>

        <b-table
          show-empty
          small
          no-footer-sorting
          ref="importLinesFromExcelTable"
          empty-text="Documentul nu are poziții"
          :items="importLinesFromExcel.sheetData"
          :fields="importLinesFromExcel.sheetColumns"
          class="tweaked-table">
          <template #cell(selected)="data">
            <b-form-checkbox :checked="data.value" @change="handleImportLineSelection(data.item)"/>
          </template>
          <template #head()="props">
            {{ props.label }}
            <span v-if="props.column === importLinesFromExcel.productCodeColumn">
              <br>
              <b-badge>Cod produs</b-badge>
            </span>
            <span v-if="props.column === importLinesFromExcel.productBarcodeColumn">
              <br>
              <b-badge>Cod bare</b-badge>
            </span>
            <span v-if="props.column === importLinesFromExcel.productClientCodeColumn">
              <br>
              <b-badge>Cod la client</b-badge>
            </span>
            <span v-if="props.column === importLinesFromExcel.quantityColumn">
              <br>
              <b-badge>Cantitate</b-badge>
            </span>
            <span v-if="props.column === importLinesFromExcel.unitPriceColumn">
              <br>
              <b-badge>Preț</b-badge>
            </span>
          </template>
        </b-table>
      </b-container>
    </b-modal>

    <b-modal
      id="client-order-line-copy-modal"
      title="Copiază linii din altă comandă"
      size="xl"
      scrollable
      @ok="handleClientOrderLineCopy">
      <b-container fluid>
        <div class="row">
          <b-form @submit.stop.prevent="lookupClientOrderLinesByCode" class="col-12">
            <b-form-group label="Cod comandă sursă">
              <b-input-group>
                <b-input v-model="clientOrderLineCopy.sourceClientOrderCode"></b-input>
                <b-input-group-append>
                  <super-button type="submit" variant="primary"><i class="fas fa-search"/><span class="d-none d-lg-inline ml-1">Caută</span></super-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form>
        </div>
        <div class="row mb-3" v-if="clientOrderLineCopy.clientOrderLines.length">
          <div class="col-12 text-center">
            <b-button @click="selectAllClientOrderLineCopyLines">
              <span v-if="!clientOrderLineCopy.selectedClientOrderLines.length"><i class="fas fa-check-circle mr-1"/>Selectează tot</span>
              <span v-else><i class="fas fa-times-circle mr-1"/>Deselectează tot</span>
            </b-button>
          </div>
        </div>
        <b-alert variant="warning" show v-if="clientOrderLineCopy.clientOrderLines.length">
          Pozițiile vor fi copiate cu prețul inițial prezent în comanda aleasă, asupra căruia se vor aplica regulile de discount disponibile pentru această comandă iar pozițiile cu discount 100% nu vor fi disponibile pentru copiere!
        </b-alert>
        <b-table
          show-empty
          small
          no-footer-sorting
          ref="clientOrderLineCopyOrderLinesTable"
          empty-text="Comanda nu are poziții"
          selectable
          select-mode="multi"
          @row-selected="clientOrderLineCopyLinesOnSelected"
          :items="clientOrderLineCopy.clientOrderLines"
          :fields="clientOrderLineCopy.tableFields"
          :tbody-tr-class="rowClass"
          :busy.sync="tableBusy"
          class="tweaked-table">
          <template v-slot:cell(rowIndex)="row">
            {{ row.item.rowIndex + 1 }}.
          </template>
          <template v-slot:cell(catalogItemCode)="row">
            <span>{{ row.value }}</span>
            <br>
            <span>{{row.item.lineDetails}}</span>
          </template>

          <template v-slot:cell(unitPrice)="row">
            {{ row.value | displayAsDecimal(2) }}
          </template>

          <template v-slot:cell(unitDiscountPercentage)="row">
            {{ row.value | displayAsPercentage(4) }}
            <br>
            {{ row.item.unitDiscountValue | displayAsDecimal(2) }}
          </template>

          <template v-slot:cell(quantity)="row">
            {{ row.value | displayAsDecimal(3) }}
          </template>

          <template v-slot:cell(lineValue)="row">
            {{ row.value | displayAsDecimal(2)  }}
          </template>

          <template v-slot:cell(lineDiscountValue)="row">
            {{ row.value | displayAsDecimal(2) }}
            <br>
            {{ row.item.vatDiscountValue | displayAsDecimal(2) }}
          </template>

          <template v-slot:cell(vatPercentage)="row">
            {{ row.value | displayAsPercentage }}
            <br>
            {{ row.item.vatValue | displayAsDecimal(2)  }}
          </template>

          <template v-slot:cell(totalValue)="row">
            {{ row.item.lineValue - row.item.lineDiscountValue + row.item.vatValue - row.item.vatDiscountValue | displayAsDecimal(2) }}
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </b-container>
</template>

<style lang="scss">
.col-max-w-50px {
  max-width: 50px;
}

.col-min-w-250px {
  min-width: 250px;
}

.col-w-200px {
  width: 200px;
}

.col-numeric-value {
  width: 105px;
}
</style>

<script>
import jump from 'jump.js';
import BigNumber from 'bignumber.js';
import { mapState, mapActions } from 'vuex';
import * as XLSX from 'xlsx';

import toasts from '@/services/toasts';
import UserNameBadge from '../components/UserNameBadge.vue';
import ClientOrderDiscountRulesAlert from '../components/ClientOrderDiscountRulesAlert.vue';
import ClientOrderStatusBadge from '../components/ClientOrderStatusBadge.vue';
import EventLogModal from '../components/EventLogModal.vue';

import { ClientOrderStatus, EventLogSubjectType } from '../constants';

const { DateTime } = require('luxon');
const _ = require('lodash');

export default {
  name: 'EditDraftClientOrder',
  components: {
    UserNameBadge,
    ClientOrderDiscountRulesAlert,
    EventLogModal,
    ClientOrderStatusBadge,
  },
  props: {
    providedClientOrderId: String,
    providedClientOrderDetails: Object,
  },
  data() {
    return {
      importLinesFromExcelProcessing: false,
      importLinesFromExcel: {
        file: null,

        excelSheet: null,
        excelSheetName: null,

        sheetData: [],
        sheetColumns: [],

        productCodeColumn: null,
        productBarcodeColumn: null,
        productClientCodeColumn: null,
        quantityColumn: null,
        unitPriceColumn: null,

        discountPercentage: null,

        columnOptions: [],
      },

      ClientOrderStatus,
      clientOrderLineCopy: {
        sourceClientOrderCode: '',
        clientOrderLines: [],
        selectedClientOrderLines: [],
        tableFields: [
          {
            key: 'rowIndex', label: '#', class: 'col-max-w-50px',
          },
          {
            key: 'catalogItemCode', label: 'Produs', thClass: 'col-min-w-250px', class: 'col-min-w-250px text-left',
          },
          {
            key: 'unitPrice', label: 'Preț', thClass: 'col-numeric-value', class: 'text-right',
          },
          // {
          //   key: 'unitDiscountPercentage',
          //   label: 'Discount',
          //   thClass: 'col-numeric-value',
          //   class: 'text-right',
          //   tdClass: (val, key, item) => {
          //     if (item.unitDiscountPercentage === 1) {
          //       return 'table-secondary';
          //     }
          //     if (item.unitDiscountPercentage !== 0) {
          //       return 'table-info';
          //     }
          //     return null;
          //   },
          // },
          {
            key: 'quantity', label: 'Cantitate', thClass: 'col-numeric-value', class: 'text-right',
          },
          {
            key: 'lineValue', label: 'Valoare', thClass: 'col-numeric-value', class: 'text-right',
          },
          {
            key: 'vatPercentage', label: 'TVA', thClass: 'col-numeric-value', class: 'text-right',
          },
          // {
          // key: 'lineDiscountValue', label: 'Val. disc.', thClass: 'col-numeric-value', class: 'text-right',
          // },
          {
            key: 'totalValue', label: 'Val. cu TVA', thClass: 'col-numeric-value', class: 'text-right',
          },
        ],
      },

      discountRulesVisible: false,

      isLoaded: false,
      performRouteChange: false,
      clientOrderId: null,
      clientOrderDetails: null,

      salesRemarksBeingEdited: false,
      salesRemarksUpdateText: null,

      // Currently edited row inventory data
      currentInventoryEntry: null,
      currentInventoryEntryLookupValues: [],
      currentInventoryEntryLookupValuesLoading: false,

      // Currently edited row object
      currentlyEditedLine: null,

      // Current row edit fields
      currentlyEditedLineQuantity: 0,

      // "Computed" properties
      currentlyEditedLineDiscountValue: 0,
      currentlyEditedLineValue: 0,
      currentlyEditedLineUnitPrice: 0,
      currentlyEditedLineUnitDiscountPercentage: 0,
      currentlyEditedLineUnitDiscountValue: 0,
      currentlyEditedLineVatPercentage: 0,
      currentlyEditedLineVatValue: 0,
      currentlyEditedLineVatDiscountValue: 0,
      currentlyEditedLineTotalValue: 0,

      tableBusy: false,

      tableFields: [
        {
          key: 'rowIndex', label: '#', class: 'col-max-w-50px',
        },
        {
          key: 'catalogItemCode', label: 'Produs', thClass: 'col-min-w-250px', class: 'col-min-w-250px text-left',
        },
        {
          key: 'unitPrice', label: 'Preț', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'unitDiscountPercentage',
          label: 'Discount',
          thClass: 'col-numeric-value',
          class: 'text-right',
          tdClass: (val, key, item) => {
            if (item.unitDiscountPercentage === 1) {
              return 'table-secondary';
            }
            if (item.unitDiscountPercentage !== 0) {
              return 'table-info';
            }
            return null;
          },
        },
        {
          key: 'quantity', label: 'Cantitate', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'lineValue', label: 'Valoare', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'vatPercentage', label: 'TVA', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'lineDiscountValue', label: 'Val. disc.', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'totalValue', label: 'Val. cu TVA și discount', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'actions', label: '', thClass: 'col-numeric-value', class: '',
        },
      ],
      items: [],
      discountRules: [],
      unappliedDiscountRules: [],
      unappliedDiscountRuleCatalogItemCodeMappings: {},

      vatPercentages: [
        { value: 0, text: '0 %' },
        { value: 0.05, text: '5 %' },
        { value: 0.09, text: '9 %' },
        { value: 0.19, text: '19 %' },
      ],

      preventEnterJump: false,
    };
  },
  computed: {
    actualDeliveryAddressLocationDisplay() {
      const locality = this.clientOrderDetails.clientDeliveryAddressCity?.trim();
      const county = this.clientOrderDetails.clientDeliveryAddressCountyName?.trim();
      if (locality && county) return `${locality}, ${county}`;
      if (locality || county) return `${locality || county}`;
      return null;
    },
    actualAddressLocationDisplay() {
      const locality = this.clientOrderDetails.clientCityAddress?.trim();
      const county = this.clientOrderDetails.clientCountyAddress?.trim();
      if (locality && county) return `${locality}, ${county}`;
      if (locality || county) return `${locality || county}`;
      return null;
    },
    pdfDownloadLink() { return `/api/client-order/export/${this.clientOrderId}/pdf`; },
    xlsxDownloadLink() { return `/api/client-order/export/${this.clientOrderId}/xlsx`; },

    currentlyEditedLineUnitDiscountPercentageInputValue: {
      get() {
        return this.currentlyEditedLineUnitDiscountPercentage * 100;
      },
      set(newValue) {
        this.currentlyEditedLineUnitDiscountPercentage = new BigNumber(newValue).dividedBy(100).dp(5).toNumber();
      },
    },
    itemsAvailable() { return this.items.length; },
    quantityPerBox() {
      if (!this.currentInventoryEntry || !this.currentlyEditedLine) {
        return 0;
      }

      return this.currentInventoryEntry.quantityPerBox;
    },
    quantityAvailableForOrder() {
      if (!this.currentInventoryEntry || !this.currentlyEditedLine) {
        return 0;
      }

      if (this.currentInventoryEntry.trackingKey === this.currentlyEditedLine.initialInventoryTrackingKey) {
        return (this.currentInventoryEntry.availableQuantity + this.currentlyEditedLine.initialQuantity);
      }

      return this.currentInventoryEntry.availableQuantity;
    },
    shouldShowQuantityWarning() {
      if (!this.currentInventoryEntry || !this.currentlyEditedLine) {
        return false;
      }

      if (this.currentInventoryEntry.isService && this.currentlyEditedLineQuantity > 0) {
        return false;
      }

      return this.quantityAvailableForOrder < this.currentlyEditedLineQuantity || this.quantityAvailableForOrder <= 0;
    },
    ...mapState(['xhrRequestRunning', 'lastRoute']),
  },

  beforeRouteLeave(to, from, next) {
    if ((this.currentlyEditedLine || this.tableBusy)
      // eslint-disable-next-line no-alert
      && window.confirm('Modificările nu au fost finalizate, continuați navigarea?') === false) {
      return next(false);
    }
    return next();
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.isLoaded = false;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.performRouteChange = true;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.clientOrderId = to.params.clientOrderId;
      vm.loadInitialData();
    });
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    this.clientOrderId = to.params.clientOrderId;
    try {
      await this.loadInitialData();
    } finally {
      next();
    }
  },
  // Need to use this to allow Vue HMR to work
  created() {
    // Debounce the asunc lookup so we don't swamp the server
    this.asyncLookupInventory = _.debounce(this.asyncLookupInventory, 450);

    this.clientOrderId = this.providedClientOrderId;
    this.loadInitialData(this.providedClientOrderDetails);
  },
  watch: {
    // call again the data fetching method if the route changes
    $route: 'fetchData',
    currentlyEditedLineQuantity: 'updateComputedValues',
    currentlyEditedLineUnitPrice: 'updateComputedValues',
    currentlyEditedLineVatPercentage: 'updateComputedValues',
    currentInventoryEntry: 'updateComputedValues',
    currentlyEditedLineUnitDiscountPercentage: 'updateComputedValuesFromDiscountPercentage',
    currentlyEditedLineUnitDiscountValue: 'updateComputedValuesFromDiscountValue',
  },
  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
    displayAsPercentage(value, decimals = 0) {
      return `${new BigNumber(value).times(100).decimalPlaces(decimals).toString()} %`;
    },
  },
  methods: {
    ...mapActions([
      'performClientOrderLinesByIdQuery',
      'performClientOrderLinesByCodeQuery',
      'performClientOrderDetailsQuery',
      'performClientOrderDiscountRulesQuery',
      'performClientOrderCatalogItemSelectionLookup',
      'performClientOrderLineSubmission',
      'performClientOrderMultipleLinesSubmission',
      'performClientOrderLinesImport',
      'performClientOrderLineDeletion',
      'performClientOrderSubmission',
      'performClientOrderCancellation',
      'performClientOrderSalesRemarksSubmission',
    ]),
    selectAll(evt) {
      evt.target.select();
    },

    focusRef(refId) {
      // Add a bogus check to return early
      if (4 % 2 === 0) {
        return;
      }

      if (this.preventEnterJump) {
        this.preventEnterJump = false;
      }

      if (!this.$refs[refId]) {
        // eslint-disable-next-line no-console
        console.error(`Ref ${refId} not found!`);
      }

      const elem = this.$refs[refId].$el || this.$refs[refId];

      this.$nextTick(() => {
        elem.focus();
        if (typeof elem.select === 'function') {
          elem.select();
        }
      });
    },

    limitText(count) {
      return `și ${count} alte înregistrări`;
    },

    rowClass(item/* , type */) {
      if (!item || !this.currentlyEditedLine) return undefined;
      if (item.clientOrderLineId === this.currentlyEditedLine.clientOrderLineId) return 'table-primary';
      return undefined;
    },

    async handleAutomaticFreebieRowCreation(unappliedDiscountRule, orderItem) {
      if (!unappliedDiscountRule || !unappliedDiscountRule.numFreebies || !orderItem) {
        return;
      }

      const clonedOrderItem = { ...orderItem };

      clonedOrderItem.clientOrderLineId = undefined;
      clonedOrderItem.unitDiscountPercentage = 1;
      clonedOrderItem.unitDiscountValue = clonedOrderItem.unitPrice;

      clonedOrderItem.quantity = unappliedDiscountRule.numFreebies;
      clonedOrderItem.lineValue = new BigNumber(clonedOrderItem.quantity).times(clonedOrderItem.unitPrice).dp(2).toNumber();
      clonedOrderItem.lineDiscountValue = clonedOrderItem.lineValue;

      clonedOrderItem.vatValue = new BigNumber(clonedOrderItem.lineValue).times(clonedOrderItem.vatPercentage).dp(2).toNumber();
      clonedOrderItem.vatDiscountValue = clonedOrderItem.vatValue;

      clonedOrderItem.createdAt = undefined;

      clonedOrderItem.inventoryBatchIdPreference = null;
      clonedOrderItem.inventoryBatchCode = null;
      clonedOrderItem.inventoryBatchExpiryDate = null;

      this.tableBusy = true;

      try {
        const payload = { ...clonedOrderItem };
        if (!payload.clientOrderLineId) {
          delete payload.clientOrderLineId;
        }
        await this.performClientOrderLineSubmission(payload);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        toasts.error('Poziția nu a putut fi salvată');
        return;
      } finally {
        this.tableBusy = false;
      }

      await this.loadInitialData();

      // Guard against component being unmounted
      if (this.$refs.orderRowsTable) {
        this.$refs.orderRowsTable.refresh();
      }

      // If we've just added a new row, scroll to the bottom
      jump('#addNewLine');

      this.tableBusy = false;
      toasts.success('Poziția de discount a fost salvată');
    },

    editSalesRemarks() {
      if (this.salesRemarksBeingEdited) {
        return;
      }

      this.salesRemarksBeingEdited = true;
      this.salesRemarksUpdateText = this.clientOrderDetails.salesRemarks;
    },

    async submitSalesRemarks() {
      if (!this.salesRemarksBeingEdited) {
        return;
      }

      this.salesRemarksBeingEdited = false;

      await this.performClientOrderSalesRemarksSubmission({ clientOrderId: this.clientOrderId, remarks: this.salesRemarksUpdateText });
      this.clientOrderDetails.salesRemarks = this.salesRemarksUpdateText;

      toasts.success('Observațiile au fost actualizate');
    },

    filterDiscountRules(catalogItemCode, productCategoryId, clientCode) {
      function ruleScoringFn(rule) {
        let score = 0;

        if (rule.CodProdus === catalogItemCode && rule.CodClient === clientCode) {
          score += 400;
        } else if (rule.CategorieProdusID === productCategoryId && rule.CodClient === clientCode) {
          score += 300;
        } else if (rule.CodClient === clientCode) {
          score += 100;
        }

        if (rule.ValoareDiscount === 0) {
          score += 10;
        }

        if (rule.TipDiscount === 'PCT') {
          score += 3;
        } else if (rule.TipDiscount === 'VAL') {
          score += 2;
        }

        return score;
      }

      return this.discountRules.filter((r) => r.CodProdus === catalogItemCode
        || r.CategorieProdusID === productCategoryId
        || (r.CodProdus === null && r.CategorieProdusID === null))
        // Sort descending
        .sort((left, right) => ruleScoringFn(right) - ruleScoringFn(left));
    },

    tryApplyDiscountRule(item, suppressToast) {
      const matchingRules = this.filterDiscountRules(
        item.catalogItemCode,
        item.productCategoryId,
        this.clientOrderDetails.clientCode,
      ).filter((dr) => dr.TipDiscount === 'VAL' || dr.TipDiscount === 'PCT');

      // If no rules or first rule is a "zero discount rule", return
      if (!matchingRules.length || matchingRules[0].ValoareDiscount === 0) {
        return false;
      }

      let bestPercentageDiscount = null;
      let bestValueDiscount = null;

      matchingRules.forEach((ruleBeingTested) => {
        if (ruleBeingTested.TipPragAplicare === 'VAL' && item.lineValue < ruleBeingTested.ValoarePragAplicare) {
          return;
        }
        if (ruleBeingTested.TipPragAplicare === 'QTY' && item.quantity < ruleBeingTested.ValoarePragAplicare) {
          return;
        }

        // Try and apply the better rule
        if ((bestPercentageDiscount === null || bestPercentageDiscount.ValoareDiscount < ruleBeingTested.ValoareDiscount)
            && ruleBeingTested.TipDiscount === 'PCT'
            // Apply only if the current discount is not better
            && item.unitDiscountPercentage < ruleBeingTested.ValoareDiscount) {
          bestPercentageDiscount = ruleBeingTested;
        } else if ((bestValueDiscount === null || bestValueDiscount.ValoareDiscount < ruleBeingTested.ValoareDiscount)
            && ruleBeingTested.TipDiscount === 'VAL'
            // Apply only if the current discount is not better
            && item.unitDiscountValue < ruleBeingTested.ValoareDiscount) {
          bestValueDiscount = ruleBeingTested;
        }
      });

      const valueOfPercentageDiscount = bestPercentageDiscount === null
        ? 0
        : new BigNumber(item.unitPrice).times(bestPercentageDiscount.ValoareDiscount).dp(5).toNumber();
      const valueOfValueDiscount = bestValueDiscount === null
        ? 0
        : bestValueDiscount.ValoareDiscount;

      const bestApplicableDiscount = valueOfPercentageDiscount > valueOfValueDiscount
        ? bestPercentageDiscount
        : bestValueDiscount;

      if (bestApplicableDiscount !== null) {
        const discountDetails = bestApplicableDiscount.TipDiscount === 'PCT'
          ? `discount procentual ${new BigNumber(bestApplicableDiscount.ValoareDiscount).times(100).toFixed(3)} %`
          : `discount unitar ${new BigNumber(bestApplicableDiscount.ValoareDiscount).toFixed(5)}`;

        if (bestApplicableDiscount.TipDiscount === 'PCT') {
          // eslint-disable-next-line no-param-reassign
          item.unitDiscountPercentage = bestApplicableDiscount.ValoareDiscount;
          // eslint-disable-next-line no-param-reassign
          item.unitDiscountValue = valueOfPercentageDiscount;
        } else if (bestApplicableDiscount.TipDiscount === 'VAL') {
          // eslint-disable-next-line no-param-reassign
          item.unitDiscountValue = bestApplicableDiscount.ValoareDiscount;
          // eslint-disable-next-line no-param-reassign
          item.unitDiscountPercentage = new BigNumber(item.unitDiscountValue).div(item.unitPrice).dp(3)
            .toNumber();
        }

        // Recalculate other item fields
        // eslint-disable-next-line no-param-reassign
        item.lineDiscountValue = new BigNumber(item.unitDiscountValue || 0).times(item.quantity || 0)
          .decimalPlaces(2)
          .toNumber();
        // eslint-disable-next-line no-param-reassign
        item.vatValue = new BigNumber(item.lineValue).times(item.vatPercentage || 0)
          .decimalPlaces(2)
          .toNumber();
        // eslint-disable-next-line no-param-reassign
        item.vatDiscountValue = new BigNumber(item.vatValue).minus(
          new BigNumber(item.lineValue).minus(item.lineDiscountValue).times(item.vatPercentage || 0)
            .decimalPlaces(2),
        )
          .decimalPlaces(2)
          .toNumber();
        // eslint-disable-next-line no-param-reassign
        item.totalValue = new BigNumber(item.lineValue).minus(item.lineDiscountValue).plus(item.vatValue).minus(item.vatDiscountValue)
          .decimalPlaces(2)
          .toNumber();

        if (!suppressToast) {
          toasts.info(`A fost aplicată regula de discount ${bestApplicableDiscount.CodRegulaDiscount} (${discountDetails})`);
        }
        return true;
      }

      return false;
    },

    updateComputedValuesFromDiscountPercentage() {
      if (!this.currentInventoryEntry || Number.isNaN(this.currentlyEditedLineQuantity)) {
        return;
      }

      // Discount value has 2 decimals
      this.currentlyEditedLineUnitDiscountValue = new BigNumber(this.currentlyEditedLineUnitPrice).times(this.currentlyEditedLineUnitDiscountPercentage).decimalPlaces(5).toNumber();
      this.updateComputedValues();
    },

    updateComputedValuesFromDiscountValue() {
      if (!this.currentInventoryEntry || Number.isNaN(this.currentlyEditedLineQuantity)) {
        return;
      }

      // Discount percentage has 4 decimals
      this.currentlyEditedLineUnitDiscountPercentage = new BigNumber(this.currentlyEditedLineUnitDiscountValue).div(this.currentlyEditedLineUnitPrice).decimalPlaces(5).toNumber();
      this.updateComputedValues();
    },

    updateComputedValues() {
      if (!this.currentInventoryEntry || Number.isNaN(this.currentlyEditedLineQuantity)) {
        this.currentlyEditedLineValue = 0;
        this.currentlyEditedLineVatValue = 0;
        this.currentlyEditedLineVatDiscountValue = 0;
        this.currentlyEditedLineTotalValue = 0;
      } else {
        this.currentlyEditedLineValue = new BigNumber(this.currentlyEditedLineUnitPrice).times(this.currentlyEditedLineQuantity || 0)
          .decimalPlaces(2)
          .toNumber();
        this.currentlyEditedLineDiscountValue = new BigNumber(this.currentlyEditedLineUnitDiscountValue || 0).times(this.currentlyEditedLineQuantity || 0)
          .decimalPlaces(2)
          .toNumber();
        this.currentlyEditedLineVatValue = new BigNumber(this.currentlyEditedLineValue).times(this.currentlyEditedLineVatPercentage || 0)
          .decimalPlaces(2)
          .toNumber();
        this.currentlyEditedLineVatDiscountValue = new BigNumber(this.currentlyEditedLineVatValue).minus(
          new BigNumber(this.currentlyEditedLineValue).minus(this.currentlyEditedLineDiscountValue).times(this.currentlyEditedLineVatPercentage || 0)
            .decimalPlaces(2),
        )
          .decimalPlaces(2)
          .toNumber();
        this.currentlyEditedLineTotalValue = new BigNumber(this.currentlyEditedLineValue).minus(this.currentlyEditedLineDiscountValue).plus(this.currentlyEditedLineVatValue).minus(this.currentlyEditedLineVatDiscountValue)
          .decimalPlaces(2)
          .toNumber();
      }
    },

    currentInventoryEntrySelected(selection) {
      // Cancel any further lookups as we have selected something already
      this.asyncLookupInventory.cancel();

      // Update row if editing
      if (this.currentlyEditedLine) {
        this.currentlyEditedLine.catalogItemCode = selection ? selection.catalogItemCode : null;
        this.currentlyEditedLine.productCategoryId = selection ? selection.productCategoryId : null;
        this.currentlyEditedLine.lineDetails = selection ? selection.productName : null;
        this.currentlyEditedLine.unitPrice = selection ? selection.listPrice : 0;
        this.currentlyEditedLine.vatPercentage = selection ? selection.vatPercentage : 0;
        this.currentlyEditedLine.unitDiscountValue = 0;
        this.currentlyEditedLine.unitDiscountPercentage = 0;
        this.currentlyEditedLine.lineDiscountValue = 0;
        this.currentlyEditedLine.lineValue = 0;

        if (Number.isNaN(this.currentlyEditedLineQuantity)) {
          this.currentlyEditedLineQuantity = 0;
        }

        this.currentlyEditedLineUnitPrice = this.currentlyEditedLine.unitPrice;
        this.currentlyEditedLineUnitDiscountPercentage = 0;
        this.currentlyEditedLineUnitDiscountValue = 0;
        this.currentlyEditedLineVatPercentage = this.currentlyEditedLine.vatPercentage;

        this.updateComputedValues();

        if (this.currentlyEditedLineUnitPrice === 0) {
          this.$nextTick(() => {
            this.preventEnterJump = true;
            this.$refs.unitPrice.focus();
          });
        } else {
          this.$nextTick(() => {
            this.preventEnterJump = true;
            this.$refs.quantity.focus();
          });
        }
      }
    },

    async asyncLookupInventory(lookupTerm) {
      if (!lookupTerm || lookupTerm.length < 3) {
        return;
      }

      this.currentInventoryEntryLookupValuesLoading = true;
      this.currentInventoryEntryLookupValues = [];
      try {
        // TODO: retry logic (axios?)
        this.currentInventoryEntryLookupValues = (await this.performClientOrderCatalogItemSelectionLookup({
          lookupTerm,
          clientCode: this.clientOrderDetails.clientCode,
        })).map((item) => ({
          isService: item.EsteServiciu,
          productCategoryId: item.CategorieProdusID,
          productCategoryName: item.DenumireCategorieProdus,
          catalogItemCode: item.CodProdus,
          barCode: item.CodBare,
          productName: item.Denumire,
          quantity: item.EsteServiciu ? 999999 : item.CantitateInStoc,
          reservedQuantity: item.CantitateInComenzi,
          availableQuantity: item.CantitateDisponibila,
          listPrice: item.PretReferinta,
          vatPercentage: item.ProcentTvaCerut || 0,
          trackingKey: item.CodProdus,
          loadedFromServer: true,
          discountsAvailable: this.discountRules.some((dr) => dr.CodProdus === item.CodProdus || dr.CategorieProdusID === item.CategorieProdusID),
          quantityPerBox: item.CantitateCutie || 0,
        }));
      } catch (ex) {
        // TODO: show some notification to the user
        // eslint-disable-next-line no-console
        console.error(ex);
      }
      this.currentInventoryEntryLookupValuesLoading = false;
    },

    async loadInitialData(providedClientOrderDetails) {
      if (!this.clientOrderId) {
        return;
      }

      let result;
      let errorState = false;
      try {
        result = await Promise.all([
          providedClientOrderDetails
            ? Promise.resolve(providedClientOrderDetails)
            : this.performClientOrderDetailsQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderLinesByIdQuery({ clientOrderId: this.clientOrderId }),
          this.performClientOrderDiscountRulesQuery({ clientOrderId: this.clientOrderId }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = [];
      }

      this.clientOrderDetails = result.length && result[0];
      this.items = result.length && result[1];
      this.discountRules = result.length && result[2];

      this.identifyUnappliedDiscountRules();

      if (errorState) {
        return;
      }

      // TODO: what if order ID invalid

      if (result) {
        this.isLoaded = true;
      }
    },

    identifyUnappliedDiscountRules() {
      this.unappliedDiscountRules = [];
      this.unappliedDiscountRuleCatalogItemCodeMappings = {};

      this.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.unappliedDiscountRules = [];
        // eslint-disable-next-line no-param-reassign,no-underscore-dangle
        item._showDetails = false;
      });

      const invoicedProducts = _.map(_.groupBy(this.items, (item) => item.catalogItemCode), (pg, catalogItemCode) => ({
        catalogItemCode,
        productCategoryId: pg[0].productCategoryId,
        // We only care about not-fully-discounted items
        quantity: pg.filter((item) => item.lineValue - item.lineDiscountValue > 0).reduce((sum, item) => sum + item.quantity, 0),
        linesTotal: pg.filter((item) => item.lineValue - item.lineDiscountValue > 0).reduce((sum, item) => sum + item.lineValue, 0),
        discountedQuantity: pg.filter((item) => item.lineValue - item.lineDiscountValue <= 0).reduce((sum, item) => sum + item.quantity, 0),
        lineItems: pg,
      }));
      // var remainingDiscounts = new List<Tuple<string, string, decimal>>();

      // TODO: In the future handle other discount types besides QTY/QTY
      invoicedProducts.forEach((invoicedProduct) => {
        const qtyDiscountMatches = this.filterDiscountRules(
          invoicedProduct.catalogItemCode,
          invoicedProduct.productCategoryId,
          this.clientOrderDetails.clientCode,
        )
          .filter((m) => m.TipDiscount === 'QTY' && m.TipPragAplicare === 'QTY');

        const firstMatchRule = qtyDiscountMatches.length ? qtyDiscountMatches[0] : null;

        // Exit early if the highest-ranking rule is a zero-discount rule
        // or if we have no rule matches at all
        if (firstMatchRule === null || firstMatchRule.ValoareDiscount === 0) {
          return;
        }

        qtyDiscountMatches.filter((qtyDiscountMatch) => {
          const numApplications = Math.floor(invoicedProduct.quantity / qtyDiscountMatch.ValoarePragAplicare);
          if (numApplications <= 0) {
            return false;
          }

          const numFreebies = new BigNumber(numApplications).times(qtyDiscountMatch.ValoareDiscount).dp(3).toNumber();
          // If we already have enough freebies on the order, avoid adding more
          if (numFreebies <= invoicedProduct.discountedQuantity) {
            return false;
          }

          const unappliedDiscountRule = {
            rule: qtyDiscountMatch.CodRegulaDiscount,
            catalogItemCode: invoicedProduct.catalogItemCode,
            numFreebies: numFreebies - invoicedProduct.discountedQuantity,
            rowIndexes: invoicedProduct.lineItems.map((item, idx) => idx),
          };

          this.unappliedDiscountRules.push(unappliedDiscountRule);
          this.unappliedDiscountRuleCatalogItemCodeMappings[invoicedProduct.catalogItemCode] = unappliedDiscountRule;

          // Attach the notice to the last line that contains the catalog item code
          if (invoicedProduct.lineItems.length) {
            invoicedProduct.lineItems[invoicedProduct.lineItems.length - 1].unappliedDiscountRules.push(unappliedDiscountRule);
            // eslint-disable-next-line no-param-reassign,no-underscore-dangle
            invoicedProduct.lineItems[invoicedProduct.lineItems.length - 1]._showDetails = true;
          }

          // Break as soon as we find a matching rule
          return true;
        });
      });
    },

    async beginEditLine(row) {
      // Clean up
      this.currentlyEditedLine = null;
      this.currentInventoryEntry = null;
      this.currentInventoryEntryLookupValues = [];
      this.currentInventoryEntryLookupValuesLoading = false;

      // Set up current row based on the given row object
      if (row) {
        const rowItem = row.item;

        try {
          // TODO: retry logic (axios?)
          this.currentInventoryEntryLookupValues = (await this.performClientOrderCatalogItemSelectionLookup({
            clientCode: this.clientOrderDetails.clientCode,
            productCode: rowItem.catalogItemCode,
          })).map((item) => ({
            isService: item.EsteServiciu,
            productCategoryId: item.CategorieProdusID,
            productCategoryName: item.DenumireCategorieProdus,
            catalogItemCode: item.CodProdus,
            barCode: item.CodBare,
            productName: item.Denumire,
            quantity: item.CantitateInStoc,
            reservedQuantity: item.CantitateInComenzi,
            availableQuantity: item.EsteServiciu ? 999999 : item.CantitateDisponibila,
            listPrice: item.PretReferinta,
            vatPercentage: item.ProcentTvaCerut || 0,
            trackingKey: item.CodProdus,
            loadedFromServer: true,
            quantityPerBox: item.CantitateCutie || 0,
          }));
        } catch (ex) {
          // TODO: show some notification to the user
          // eslint-disable-next-line no-console
          console.error(ex);
        }

        this.currentInventoryEntry = this.currentInventoryEntryLookupValues.find(
          (entry) => entry.trackingKey === rowItem.catalogItemCode,
        );

        if (!this.currentInventoryEntry) {
          this.currentInventoryEntry = {
            isService: rowItem.isService,
            productCategoryId: rowItem.productCategoryId,
            productCategoryName: rowItem.productCategoryName,
            catalogItemCode: rowItem.catalogItemCode,
            barCode: rowItem.barCode,
            productName: rowItem.lineDetails,
            listPrice: rowItem.unitPrice,
            vatPercentage: rowItem.vatPercentage,
            trackingKey: rowItem.catalogItemCode,
          };

          this.currentInventoryEntryLookupValues.push(this.currentInventoryEntry);
        }

        this.currentlyEditedLine = {
          initialCatalogItemCode: this.currentInventoryEntry.catalogItemCode,
          initialInventoryTrackingKey: this.currentInventoryEntry.trackingKey,
          initialQuantity: rowItem.quantity,
          ...rowItem,
        };
      } else {
        this.currentlyEditedLine = {
          clientOrderLineId: null,
          clientOrderId: this.clientOrderId,
          createdAt: new Date(),
          lineIndex: null,
          catalogItemCode: null,
          lineDetails: null,
          quantity: 0,
          unitPrice: 0,
          unitDiscountPercentage: 0,
          unitDiscountValue: 0,
          lineDiscountValue: 0,
          lineValue: 0,
          vatPercentage: 0,
          vatValue: 0,
          vatDiscountValue: 0,
          extraLineDetails: '',
        };

        this.items.push(this.currentlyEditedLine);

        // Guard against component being unmounted
        if (this.$refs.orderLinesTable) {
          this.$refs.orderLinesTable.refresh();
        }
      }

      this.currentlyEditedLineQuantity = this.currentlyEditedLine.quantity;
      this.currentlyEditedLineUnitPrice = this.currentlyEditedLine.unitPrice;
      this.currentlyEditedLineUnitDiscountPercentage = this.currentlyEditedLine.unitDiscountPercentage;
      this.currentlyEditedLineUnitDiscountValue = this.currentlyEditedLine.unitDiscountValue;
      this.currentlyEditedLineVatPercentage = this.currentlyEditedLine.vatPercentage;
      this.currentlyEditedLineVatValue = this.currentlyEditedLine.vatValue;
      this.currentlyEditedLineVatDiscountValue = this.currentlyEditedLine.vatDiscountValue;

      this.updateComputedValues();

      // Focus on product code input if working on a new row
      if (!row) {
        this.$nextTick(() => {
          jump(this.$refs.catalogItemCode.$el);
          this.$refs.catalogItemCode.$el.focus();
        });
      }
    },

    async submitEditRow() {
      // Check if row is valid
      if (this.currentInventoryEntry === null) {
        toasts.error('Produsul nu a fost selectat');
        return;
      }

      if (parseFloat(this.currentlyEditedLineQuantity) <= 0) {
        toasts.error('Cantitatea trebuie să fie pozitivă');
        return;
      }

      if (parseFloat(this.currentlyEditedLineVatPercentage) === 0) {
        if (!(await this.$bvModal.msgBoxConfirm('Procentul de TVA selectat este de 0 %. Confirmi datele încărcate?', {
          okTitle: 'Da',
          cancelTitle: 'Nu',
        }).catch(() => false))) {
          return;
        }
      }

      if (parseFloat(this.currentlyEditedLineUnitPrice) <= 0) {
        if (!(await this.$bvModal.msgBoxConfirm('Prețul este mai mic sau egal cu zero. Confirmi datele încărcate?', {
          okTitle: 'Da',
          cancelTitle: 'Nu',
        }).catch(() => false))) {
          return;
        }
      }

      if (!this.currentInventoryEntry.isService && new BigNumber(this.currentlyEditedLineQuantity).gt(this.quantityAvailableForOrder)) {
        if (!(await this.$bvModal.msgBoxConfirm(`Cantitatea disponibilă (${this.quantityAvailableForOrder}) este insuficientă. Confirmi datele încărcate?`, {
          okTitle: 'Da',
          cancelTitle: 'Nu',
        }).catch(() => false))) {
          return;
        }
      }

      this.tableBusy = true;

      this.currentlyEditedLine.quantity = parseFloat(this.currentlyEditedLineQuantity);
      this.currentlyEditedLine.unitPrice = parseFloat(this.currentlyEditedLineUnitPrice);
      this.currentlyEditedLine.lineValue = parseFloat(this.currentlyEditedLineValue);
      this.currentlyEditedLine.vatPercentage = parseFloat(this.currentlyEditedLineVatPercentage);
      this.currentlyEditedLine.vatValue = parseFloat(this.currentlyEditedLineVatValue);
      // Get the discounts in as well
      this.currentlyEditedLine.unitDiscountPercentage = parseFloat(this.currentlyEditedLineUnitDiscountPercentage);
      this.currentlyEditedLine.unitDiscountValue = parseFloat(this.currentlyEditedLineUnitDiscountValue);
      this.currentlyEditedLine.lineDiscountValue = parseFloat(this.currentlyEditedLineDiscountValue);
      this.currentlyEditedLine.vatDiscountValue = parseFloat(this.currentlyEditedLineVatDiscountValue);

      this.tryApplyDiscountRule(this.currentlyEditedLine);

      const isNewLine = !this.currentlyEditedLine.clientOrderLineId;

      try {
        const payload = { ...this.currentlyEditedLine };
        if (!payload.clientOrderLineId) {
          delete payload.clientOrderLineId;
        }
        await this.performClientOrderLineSubmission(payload);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        toasts.error('Poziția nu a putut fi salvată');
        return;
      } finally {
        this.tableBusy = false;
      }

      // TODO: more logic
      this.currentlyEditedLine = null;
      this.currentInventoryEntry = null;

      await this.loadInitialData();

      // Guard against component being unmounted
      if (this.$refs.orderRowsTable) {
        this.$refs.orderRowsTable.refresh();
      }

      // If we've just added a new row, scroll to the bottom
      if (isNewLine) {
        jump('#addNewLine');
      }

      this.tableBusy = false;
      toasts.success('Poziția a fost salvată');
    },

    async submitDeleteRow(row) {
      this.tableBusy = true;

      try {
        await this.performClientOrderLineDeletion(row.item.clientOrderLineId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        toasts.error('Poziția nu a putut fi ștearsă');
        return;
      }

      await this.loadInitialData();

      // Guard against component being unmounted
      if (this.$refs.orderRowsTable) {
        this.$refs.orderRowsTable.refresh();
      }

      this.tableBusy = false;

      toasts.success('Poziția a fost ștearsă');
    },

    cancelEditRow() {
      const shouldRefreshTable = this.currentlyEditedLine.clientOrderLineId === null;
      if (shouldRefreshTable) {
        this.items.splice(this.items.findIndex((orderRow) => orderRow.clientOrderLineId === null), 1);
      }

      this.currentlyEditedLine = null;
      this.currentInventoryEntry = null;

      if (shouldRefreshTable) {
        // Guard against component being unmounted
        if (this.$refs.orderRowsTable) {
          this.$refs.orderRowsTable.refresh();
        }
      }
    },

    async submitClientOrder() {
      if (this.unappliedDiscountRules.length) {
        if (!(await this.$bvModal.msgBoxConfirm('Există discount-uri care nu au fost încă aplicate! Dorești să continui?', {
          okTitle: 'Da',
          cancelTitle: 'Nu',
        }).catch(() => false))) {
          return;
        }
      }

      if (!(await this.$bvModal.msgBoxConfirm('Confirmi trimiterea comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      await this.performClientOrderSubmission(this.clientOrderId);

      this.$emit('statusChanged', '');

      if (this.performRouteChange) {
        this.$router.push({
          name: 'clientOrderDetails',
          params: {
            clientOrderId: this.clientOrderId,
          },
        });
      }
    },

    async cancelClientOrder() {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi anularea comenzii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      await this.performClientOrderCancellation(this.clientOrderId);

      this.$emit('statusChanged', '');

      if (this.performRouteChange) {
        this.$router.push({
          name: 'clientOrderDetails',
          params: {
            clientOrderId: this.clientOrderId,
          },
        });
      }
    },

    clipboardSuccessHandler() {
      toasts.success('Textul a fost copiat');
    },

    clipboardErrorHandler() {
      toasts.error('Textul nu a putut fi copiat');
    },

    async lookupClientOrderLinesByCode() {
      this.clientOrderLineCopy.clientOrderLines = (await this.performClientOrderLinesByCodeQuery({
        clientOrderCode: this.clientOrderLineCopy.sourceClientOrderCode,
      }))
        .map((line, lineIdx) => Object.assign(line, { rowIndex: lineIdx }))
        // We do not display 100% discount lines
        .filter((line) => (line.unitDiscountPercentage < 1 && line.unitDiscountValue !== line.unitPrice))
        .map((line) => { /* eslint-disable no-param-reassign */
          delete line.clientOrderId;
          delete line.clientOrderLineId;

          // Remove all discounts
          line.unitDiscountPercentage = 0;
          line.unitDiscountValue = 0;
          line.lineDiscountValue = 0;
          line.vatDiscountValue = 0;

          return line;
        });
    },

    showClientOrderLineCopyModal() {
      Object.assign(this.clientOrderLineCopy, {
        sourceClientOrderCode: '',
        clientOrderLines: [],
        selectedClientOrderLines: [],
      });

      this.$bvModal.show('client-order-line-copy-modal');
    },

    showImportLinesFromExcelModal() {
      Object.assign(this.importLinesFromExcel, {
        file: null,
        excelSheet: null,
        excelSheetName: null,
        sheetData: [],
        sheetColumns: [],
        productCodeColumn: null,
        productBarcodeColumn: null,
        productClientCodeColumn: null,
        quantityColumn: null,
        unitPriceColumn: null,
        columnOptions: [],
        discountPercentage: null,
      });

      this.$refs.importLinesFromExcelModal.show();
    },

    async importLinesFromExcelFileChanged() {
      if (!this.importLinesFromExcel.file) {
        return;
      }

      const data = await this.importLinesFromExcel.file.arrayBuffer();
      const wb = XLSX.read(data);

      const sheetNames = [];
      const sheets = [];

      for (let idx = 0; idx < wb.SheetNames.length; idx += 1) {
        const ws = wb.Sheets[wb.SheetNames[idx]];
        const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 });

        if (rawData.length <= 1 || rawData[0].length < 2) {
          // eslint-disable-next-line no-continue
          continue;
        }

        sheetNames.push(wb.SheetNames[idx]);
        sheets.push(wb.Sheets[wb.SheetNames[idx]]);
      }

      if (sheetNames.length === 0) {
        this.$nextTick(() => this.$bvModal.msgBoxOk('Nicio pagină din fișier nu conține date accesibile'));
        return;
      }

      Object.assign(this.importLinesFromExcel, {
        excelSheet: null,
        excelSheetName: null,
        sheetData: [],
        sheetColumns: [],
        productCodeColumn: null,
        productBarcodeColumn: null,
        productClientCodeColumn: null,
        quantityColumn: null,
        unitPriceColumn: null,
        columnOptions: [],
        discountPercentage: null,
      });

      // eslint-disable-next-line prefer-destructuring
      this.importLinesFromExcel.excelSheet = sheets[0];
      // eslint-disable-next-line prefer-destructuring
      this.importLinesFromExcel.excelSheetName = sheetNames[0];

      const sheet = XLSX.utils.sheet_to_json(this.importLinesFromExcel.excelSheet, {
        header: 1,
      });

      const header = sheet.shift();
      const numCols = header.length;
      const rows = sheet.map((line) => {
        const acc = { selected: true };
        for (let idx = 0; idx < numCols; idx += 1) {
          Object.assign(acc, { [`col${idx}`]: line[idx] || null });
        }
        return acc;
      });

      this.importLinesFromExcel.sheetColumns = [{
        key: 'selected',
        label: 'Sel.',
      }, ...header.map((key, idx) => ({
        key: `col${idx}`, label: key,
      }))];

      this.importLinesFromExcel.columnOptions = header.map((key, idx) => ({
        value: `col${idx}`, text: key,
      }));

      this.importLinesFromExcel.sheetData = rows;

      toasts.info(`Au fost identificate ${this.importLinesFromExcel.sheetData.length} linii`);
    },

    async showEventLogModal() {
      this.$nextTick(() => this.$refs.eventLogModal.showModal(this.clientOrderId, EventLogSubjectType.Order));
    },

    handleImportLineSelection(item) {
      item.selected = !item.selected;
    },

    async handleImportLinesFromExcel($event) {
      try {
        this.importLinesFromExcelProcessing = true;
        $event.preventDefault();

        if (!this.importLinesFromExcel.sheetData || !this.importLinesFromExcel.sheetData.length) {
          toasts.info('Nu există linii pentru import');
          return;
        }

        if (!this.importLinesFromExcel.productCodeColumn && !this.importLinesFromExcel.productBarcodeColumn && !this.importLinesFromExcel.productClientCodeColumn) {
          toasts.info('Nu a fost aleasa nicio coloana de cod produs');
          return;
        }

        if (!this.importLinesFromExcel.quantityColumn) {
          toasts.info('Nu a fost aleasa coloana de cantitate');
          return;
        }

        console.log(this.importLinesFromExcel.productCodeColumn);
        console.log(this.importLinesFromExcel.productBarcodeColumn);
        console.log(this.importLinesFromExcel.productClientCodeColumn);
        console.log(this.importLinesFromExcel.quantityColumn);
        console.log(this.importLinesFromExcel.unitPriceColumn);

        const lines = [];

        for (let idx = 0; idx < this.importLinesFromExcel.sheetData.length; idx += 1) {
          if (!this.importLinesFromExcel.sheetData[idx].selected) {
            // eslint-disable-next-line no-continue
            continue;
          }

          try {
            const line = {
              catalogItemCode: null,
              clientItemCode: null,
              eanCode: null,
              quantity: this.importLinesFromExcel.sheetData[idx][this.importLinesFromExcel.quantityColumn],
              unitPrice: null,
              discountPercentage: this.importLinesFromExcel.discountPercentage,
            };

            if (this.importLinesFromExcel.productCodeColumn) {
              line.catalogItemCode = this.importLinesFromExcel.sheetData[idx][this.importLinesFromExcel.productCodeColumn];
            }
            if (this.importLinesFromExcel.productClientCodeColumn) {
              line.clientItemCode = this.importLinesFromExcel.sheetData[idx][this.importLinesFromExcel.productClientCodeColumn];
            }
            if (this.importLinesFromExcel.productBarcodeColumn) {
              line.eanCode = this.importLinesFromExcel.sheetData[idx][this.importLinesFromExcel.productBarcodeColumn];
            }
            if (this.importLinesFromExcel.unitPriceColumn) {
              line.unitPrice = this.importLinesFromExcel.sheetData[idx][this.importLinesFromExcel.unitPriceColumn];
            }

            if (!line.catalogItemCode && !line.clientItemCode && !line.eanCode) {
              toasts.warn(`Linia ${idx + 1} nu are niciun tip de cod de produs completat`);
              return;
            }

            if (!line.quantity) {
              toasts.warn(`Linia ${idx + 1} nu are o cantitate validă`);
              return;
            }

            if (line.unitPrice && BigNumber(line.unitPrice).lte(0)) {
              toasts.warn(`Linia ${idx + 1} are coloana de preț completată, dar nu are un preț valid`);
              return;
            }

            lines.push(line);
          } catch (ex) {
            console.error(ex);
            toasts.error(`Eroare la linia ${idx + 1}: ${ex}`);
            return;
          }
        }

        if (!lines.length) {
          toasts.info('Nu există linii selectate pentru import');
          return;
        }

        try {
          await this.performClientOrderLinesImport({ clientOrderId: this.clientOrderId, orderLines: lines });
        } catch (ex) {
          console.error(ex);
          toasts.error((ex && ex.response && ex.response.data) || ex);
          return;
        }

        await this.loadInitialData();

        const itemsToDiscount = [];
        for (let idx = 0; idx < this.items.length; idx += 1) {
          const target = { ...this.items[idx] };
          if (this.tryApplyDiscountRule(target, true)) {
            itemsToDiscount.push(target);
          }
        }

        if (itemsToDiscount.length) {
          try {
            for (let idx = 0; idx < itemsToDiscount.length; idx += 1) {
            // eslint-disable-next-line no-await-in-loop
              await this.performClientOrderLineSubmission(itemsToDiscount[idx]);
            }
          } catch (ex) {
            console.error(ex);
            toasts.error((ex && ex.response && ex.response.data) || ex);
            return;
          }

          await this.loadInitialData();
        }

        // Guard against component being unmounted
        if (this.$refs.orderRowsTable) {
          this.$refs.orderRowsTable.refresh();
        }

        this.$nextTick(() => {
          this.$refs.importLinesFromExcelModal.hide();
          toasts.success('Liniile au fost adăugate');
        });
      } finally {
        this.importLinesFromExcelProcessing = false;
      }
    },

    async handleClientOrderLineCopy() {
      const selectedLines = this.clientOrderLineCopy.selectedClientOrderLines.map((line) => ({ ...line }));

      if (!selectedLines.length) {
        toasts.info('Nu a fost selectată vreo poziție');
        return;
      }

      selectedLines.forEach((line) => {
        this.tryApplyDiscountRule(line, true);

        // We don't need the row index on the object
        delete line.rowIndex;

        line.clientOrderId = this.clientOrderId;
      });

      await this.performClientOrderMultipleLinesSubmission(selectedLines);
      await this.loadInitialData();

      // Guard against component being unmounted
      if (this.$refs.orderRowsTable) {
        this.$refs.orderRowsTable.refresh();
      }
    },

    selectAllClientOrderLineCopyLines() {
      if (this.$refs.clientOrderLineCopyOrderLinesTable) {
        for (let ii = 0; ii <= this.clientOrderLineCopy.clientOrderLines.length; ii += 1) {
          if (this.$refs.clientOrderLineCopyOrderLinesTable.isRowSelected(ii)) {
            this.$refs.clientOrderLineCopyOrderLinesTable.clearSelected();
            return;
          }
        }

        this.$refs.clientOrderLineCopyOrderLinesTable.selectAllRows();
      }
    },

    clientOrderLineCopyLinesOnSelected(items) {
      this.clientOrderLineCopy.selectedClientOrderLines = items;
    },
  },
};
</script>
