<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Comandă nouă</h2>
    </div>
    <b-container fluid v-if="!selectedClient">
      <b-row class="justify-content-center">
        <b-col col sm="8" md="6" lg="4">
          <b-form @submit.stop.prevent="onSubmitClientLookup">
            <b-form-group
              label="Alege clientul pentru comandă:"
              label-for="lookupTerm">
              <b-input-group>
                <b-form-input id="lookupTerm" v-model="lookupTerm" trim autofocus placeholder="Cod CIF sau nume client"></b-form-input>
                <b-input-group-append>
                  <b-button type="submit" variant="primary" :disabled="xhrRequestRunning"><i class="fas fa-search"/><span class="d-none d-lg-inline">&nbsp;Caută</span></b-button>
                  <b-button type="button" variant="info" :disabled="xhrRequestRunning" @click="showClientCreateModal"><i class="fas fa-plus"/><span class="d-none d-lg-inline">&nbsp;Client nou</span></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <div>
        <b-alert :show="!lookupPerformed" variant="info">Introdu un termen de căutare (cod CIF sau nume client)</b-alert>
        <b-alert :show="!itemsAvailable && lookupPerformed && !lookupInProgress" variant="warning">Nu a fost gasit niciun rezultat pentru termenul "{{lastLookupTerm}}"</b-alert>
        <b-alert :show="lookupInProgress" variant="info">Se cauta termenul "{{lookupInProgressTerm}}"</b-alert>
        <b-alert :show="lookupFailed" variant="danger">A intervent o eroare la cautare pentru termenul "{{lastLookupTerm}}"</b-alert>
        <b-alert :show="itemsAvailable && lookupPerformed && !lookupInProgress" variant="success">Au fost gasite {{numItems}} rezultate pentru termenul "{{lastLookupTerm}}"</b-alert>
      </div>
      <b-pagination :disabled="lookupInProgress" align="center" v-if="numPages > 1" :per-page="perPage" :total-rows="numItems" v-model="currentPage"></b-pagination>
      <b-table
        small
        striped
        hover
        thead-tr-class="row"
        tbody-tr-class="row"
        :busy.sync="lookupInProgress"
        :items="items"
        :fields="tableFields">
        <template v-slot:cell(clientCode)="data">
          <Highlighter class="my-highlight"
            highlightClassName="highlight"
            :searchWords="highlightValue"
            :autoEscape="true"
            :textToHighlight="data.value"/>
        </template>
        <template v-slot:cell(clientName)="data">
          <Highlighter class="my-highlight"
            highlightClassName="highlight"
            :searchWords="highlightValue"
            :autoEscape="true"
            :textToHighlight="data.value"/>
        </template>
        <template v-slot:cell(clientDeliveryAddressName)="data">
          <Highlighter class="my-highlight"
            highlightClassName="highlight"
            :searchWords="highlightValue"
            :autoEscape="true"
            :textToHighlight="data.value"/>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button variant="primary" size="sm" @click="selectClient(row.item)">
            <i class="fas fa-chevron-circle-right"/><span class="d-none d-lg-inline">&nbsp;Alege</span>
          </b-button>
        </template>
      </b-table>
      <b-pagination :disabled="lookupInProgress" align="center" v-if="numPages > 1" :per-page="perPage" :total-rows="numItems" v-model="currentPage"></b-pagination>
    </b-container>
    <!-- Client selected from list, continue with confirmation -->
    <b-container fluid v-else>
      <!-- Order details card -->
      <b-card>
        <div slot="header">
          Detalii client selectat
        </div>
        <div class="row">
          <b-form-group label="Client (nume)" class="col-md-4">
            <b-input v-model="selectedClient.clientName" readonly="readonly"></b-input>
          </b-form-group>

          <b-form-group label="Client (cod)" class="col-md-4 col-lg-4">
            <b-input v-model="selectedClient.clientCode" readonly="readonly"></b-input>
          </b-form-group>

          <b-form-group label="Adresa livrare" class="col-md-12 col-lg-4" v-if="selectedClient.clientDeliveryAddressId">
            <b-input v-model="selectedClient.clientDeliveryAddressName" readonly="readonly"></b-input>
          </b-form-group>
        </div>
        <div slot="footer">
            <b-button type="button" variant="success" @click="confirmClientSelection" :disabled="xhrRequestRunning" class="mx-1 p-2"><i class="fas fa-check"/><span class="d-none d-sm-inline">&nbsp;Continuă</span></b-button>
            <b-button type="button" variant="danger" @click="clearClientSelection" :disabled="xhrRequestRunning" class="mx-1 p-2"><i class="fas fa-times"/><span class="d-none d-sm-inline">&nbsp;Anulează</span></b-button>
        </div>
      </b-card>
    </b-container>
    <client-details-modal :usage="'CREATE'" ref="clientDetailsModal" :callback="clientCreateModalCallback"/>
  </b-container>
</template>

<script>
import Highlighter from 'vue-highlight-words';
import { mapState, mapActions } from 'vuex';
import ClientDetailsModal from '../components/ClientDetailsModal.vue';

export default {
  name: 'CreateClientOrder',
  components: {
    Highlighter,
    ClientDetailsModal,
  },
  data() {
    return {
      currentPage: 1,
      numItems: 0,
      numPages: 1,
      perPage: 20,

      selectedClient: null,

      lookupTerm: null,
      lastLookupTerm: null,
      lookupInProgress: false,
      lookupInProgressTerm: null,
      lookupPerformed: false,
      lookupFailed: false,
      tableFields: [
        {
          key: 'clientCode',
          class: 'col-2',
          label: 'Cod client',
        },
        {
          key: 'clientName',
          class: 'col-4',
          label: 'Denumire client',
        },
        {
          key: 'clientDeliveryAddressName',
          class: 'col-5',
          label: 'Adresa livrare',
        },
        {
          key: 'actions',
          class: 'col-1',
          label: '#',
        },
      ],
      items: [],
    };
  },
  watch: {
    currentPage: 'onSubmitClientLookup',
  },
  beforeRouterUpdate(to, from, next) {
    this.lookupTerm = to.query.clientCode;
    if (this.lookupTerm) {
      this.$nextTick(() => {
        this.onSubmitClientLookup();
      });
    }
    next();
  },
  created() {
    this.lookupTerm = this.$route.query.clientCode;
    if (this.lookupTerm) {
      this.$nextTick(() => {
        this.onSubmitClientLookup();
      });
    }
  },
  computed: {
    itemsAvailable() { return this.items.length; },
    // eslint-disable-next-line no-param-reassign,no-mixed-operators
    highlightValue() { return this.lastLookupTerm && [this.lastLookupTerm] || []; },
    ...mapState(['xhrRequestRunning']),
  },
  methods: {
    ...mapActions([
      'performClientWithDeliveryAddressLookup',
      'performClientOrderCreation',
    ]),
    async confirmClientSelection() {
      if (!this.selectedClient) {
        return;
      }

      let result;
      let errorState = false;
      try {
        result = await this.performClientOrderCreation({
          clientCode: this.selectedClient.clientCode,
          clientDeliveryAddressId: this.selectedClient.clientDeliveryAddressId,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = null;
      }

      if (errorState || !result) {
        // TODO: TOAST ERROR
        return;
      }

      // TODO: TOAST SUCCESS
      this.$router.push({
        name: 'clientOrderDetails',
        params: {
          clientOrderId: result,
        },
      });
    },
    async onSubmitClientLookup() {
      if (!this.lookupTerm || this.lookupTerm.length < 2) return;

      this.lookupPerformed = true;
      this.lookupInProgress = true;
      this.lookupInProgressTerm = this.lookupTerm;

      let result;
      let errorState = false;
      try {
        result = await this.performClientWithDeliveryAddressLookup({
          lookupTerm: this.lookupInProgressTerm,
          page: this.currentPage,
          perPage: this.perPage,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = {
          items: [],
          count: 0,
          limit: this.perPage,
          pages: 0,
        };
      }

      if (!errorState) {
        this.lastLookupTerm = this.lookupInProgressTerm;
      }

      this.lookupInProgressTerm = null;
      this.lookupFailed = errorState;
      this.lookupInProgress = false;

      this.items = result.items;
      this.numItems = result.count;
      this.perPage = result.limit;
      // Avoid a validation error on the number of pages if we get back zero pages (no results)
      this.numPages = result.pages || 1;
    },
    selectClient(item) {
      this.selectedClient = { ...item };
    },
    clearClientSelection() {
      this.selectedClient = null;
    },
    showClientCreateModal(evt) {
      evt.preventDefault();

      this.$refs.clientDetailsModal.showModal();
    },
    clientCreateModalCallback(client) {
      this.$refs.clientDetailsModal.hideModal();

      if (client) {
        this.selectClient({
          clientCode: client.id,
          clientName: client.name,
          ClientDeliveryAddressId: null,
          ClientDeliveryAddressName: null,
        });
      }
    },
  },
};
</script>
