<template>
  <div class="d-flex flex-column justify-content-center">
    <div v-if="files.length > 0">
      <label>Fișiere atașate</label>
      <div v-for="item in files" v-bind:key="item.clientOrderFileAttachmentId">
        <div class="d-flex mb-3 justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <div class="d-flex flex-wrap mb-0">
              <div class="d-inline-block">
                <div class="d-inline-block attachment-file-name">
                  <strong>{{ displayOnlyFilename(item.originalFileName) }}</strong>
                </div>
              </div>
              <span v-if="hasFileExtension(item)">
                <strong>.{{ displayOnlyExtension(item.originalFileName) }}</strong>
              </span>
              <span class="ml-1">({{ displayPrettyBytes(item.sizeBytes) }})</span>
              <span class="ml-1">încărcat la {{ displayAsDate(item.uploadedAt) }} de</span>
            </div>
            <div v-if="item.comment">
              <span>{{item.comment}}</span>
            </div>
          </div>
          <div>
            <user-name-badge :user-name="item.uploadedByWebAppUserName" />
          </div>
          <div class="ml-3">
            <b-button-group>
              <b-button id="downloadAttachmentFilesButton"
                :disabled="xhrRequestRunning"
                :href="getDownloadUrl(item.clientOrderFileAttachmentId)"
                variant="secondary"
                target="_blank"
                v-b-tooltip.hover="'Descarcă'">
                <i class="fas fa-cloud-download-alt mr-1"/>
                <span class="d-none d-md-inline ml-1">Descarcă</span>
              </b-button>
            </b-button-group>
            <b-button-group class="ml-3">
              <b-button id="deleteAttachmentFilesButton"
                :disabled="xhrRequestRunning"
                @click="handleDeleteAttachmentFile(item.clientOrderFileAttachmentId)"
                variant="secondary"
                v-b-tooltip.hover="'Elimină'">
                <i class="fas fa-trash-alt mr-1"/>
                <span class="d-none d-md-inline ml-1">Elimină</span>
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <span>Fără fișiere atașate</span>
    </div>
    <div>
      <b-button-group class="mt-3">
        <b-button id="addAttachmentFilesButton"
          :disabled="xhrRequestRunning"
          @click="showFileAttachmentsModal"
          variant="primary">
          <i class="fas fa-cloud-upload-alt mr-1"/>
          <span class="d-none d-md-inline ml-1">Adaugă fișiere</span>
        </b-button>
      </b-button-group>
    </div>
    <b-modal
      id="file-attachments-add-modal"
      ref="fileAttachmentsAddModal"
      title="Adaugă fișiere"
      cancel-title="Renunță"
      ok-title="Adaugă"
      :ok-disabled="attachFileBusy"
      :cancel-disabled="attachFileBusy"
      :header-close-disabled="attachFileBusy"
      @ok="handleAddAttachmentFile"
      @hide="handleFileAttachmentsModalClose">
      <b-overlay :show="attachFileBusy" no-wrap>
        <template #overlay>&nbsp;</template>
      </b-overlay>
      <b-form-group label="Alege sau trage fișiere">
        <b-form-file
          accept="*"
          multiple
          v-model="fileAttachment.file"
          :state="Boolean(fileAttachment.file) && fileAttachment.file.length > 0"
        />
      </b-form-group>
      <b-form-group label="Detalii (opțional)">
        <b-form-textarea v-model="fileAttachment.comment" no-resize rows="3"></b-form-textarea>
      </b-form-group>
    </b-modal>
  </div>
</template>

<style>
.attachment-file-name {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import { mapState } from 'vuex';
import prettyBytes from 'pretty-bytes';
import { DateTime } from 'luxon';

import toasts from '@/services/toasts';
import UserNameBadge from './UserNameBadge.vue';

export default {
  name: 'FileAttachmentsList',
  components: {
    UserNameBadge,
  },
  props: {
    files: { type: Array, required: true },
  },
  data() {
    const freshData = {
      attachFileBusy: false,
      fileAttachment: {
        file: [],
        comment: null,
      },
    };

    return freshData;
  },
  methods: {
    hasFileExtension(value) {
      if (!value || !value.originalFileName) {
        return false;
      }
      return value.originalFileName.split('.').length > 1;
    },

    displayOnlyFilename(value) {
      if (!value) {
        return null;
      }
      return value.split('.').shift();
    },

    getDownloadUrl(value) {
      return `/api/client-order/file-attachment/download/${value}`;
    },

    displayOnlyExtension(value) {
      if (!value) {
        return null;
      }

      const parts = value.split('.');
      if (parts.length >= 2) {
        return parts.pop();
      }
      return null;
    },

    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
    },

    displayPrettyBytes(value) {
      return prettyBytes(value, { locale: 'ro' });
    },

    showFileAttachmentsModal() {
      this.$nextTick(() => {
        this.fileAttachment = {
          file: null,
          comment: null,
        };
        this.$refs.fileAttachmentsAddModal.show();
      });
    },

    handleAddAttachmentFile() {
      if (this.attachFileBusy) {
        toasts.error('Cererea este deja în curs de executare');
      }

      this.attachFileBusy = true;
      this.$emit('addAttachmentFile', this.fileAttachment, this.closeFileAttachmentsModal);
    },

    handleDeleteAttachmentFile(fileAttachmentId) {
      this.$emit('deleteAttachmentFile', fileAttachmentId);
    },

    closeFileAttachmentsModal(success) {
      this.attachFileBusy = false;
      if (success) {
        this.$nextTick(() => this.$refs.fileAttachmentsAddModal.hide());
      }
    },

    handleFileAttachmentsModalClose(event) {
      if (this.attachFileBusy) {
        event.preventDefault();
      }
    },
  },
  computed: {
    ...mapState(['xhrRequestRunning']),
  },
};
</script>
