<template>
  <b-container fluid>
    <div>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>Comenzi</h2>
        <b-button-toolbar class="pb-3">
          <b-button class="mx-1 p-2" id="createOrderToolbarButton" :to="{ name: 'clientOrderCreate' }" variant="primary" v-b-tooltip.hover>
            <i class="fas fa-plus-square" />
            <span class="d-none d-md-inline ml-1">Comandă nouă</span>
          </b-button>
          <b-dropdown right class="mx-1">
            <template #button-content>
              <i class="fas fa-file-upload mr-1" />
              <span class="d-none d-md-inline">Importă</span>
            </template>
            <b-dropdown-item @click="showExcelImportModal">Import din Excel</b-dropdown-item>
            <b-dropdown-item @click="showXmlImportModal">Import din XML DocProcess</b-dropdown-item>
          </b-dropdown>
          <b-dropdown right class="mx-1">
            <template #button-content>
              <i class="fas fa-file-excel mr-1" />
              <span class="d-none d-md-inline">Exportă comenzi</span>
            </template>
            <b-dropdown-item @click="handleExcelOrdersExport">
              Exportă
            </b-dropdown-item>
          </b-dropdown>

          <b-tooltip target="createOrderToolbarButton" placement="topleft" custom-class="d-md-none">Comandă nouă</b-tooltip>
          <b-tooltip target="importOrdersToolbarButton" placement="topleft" custom-class="d-md-none">Importă</b-tooltip>
          <b-tooltip target="exportOrdersToolbarButton" placement="topleft" custom-class="d-md-none">Exportă</b-tooltip>
        </b-button-toolbar>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm">
          <b-form @submit.stop.prevent="resetPageAndLookup">
            <b-form-group>
              <b-input-group>
                <b-form-input id="queryTerm" v-model="queryTerm" trim placeholder="Caută"></b-form-input>
                <b-input-group-append>
                  <b-button type="submit" variant="primary" :disabled="xhrRequestRunning"><i class="fas fa-search"/><span class="d-none d-lg-inline ml-1">Caută</span></b-button>
                  <b-button @click="clearQueryTerm" variant="secondary" :disabled="xhrRequestRunning"><i class="fas fa-times"/><span class="d-none d-lg-inline ml-1">Curăță</span></b-button>
                </b-input-group-append>
              </b-input-group>
              <date-picker v-model="dateRangeFilter"
                :shortcuts="datePickerShortcuts"
                @input="onDateRangeInput"
                range
                input-class="form-control mt-1"
                class="w-100"
                value-type="YYYY-MM-DD"
                :disabled-date="(date, currentValue) => date > new Date()"
                :clearable="false">
              </date-picker>
            </b-form-group>
          </b-form>
          <b-form-checkbox
            :checked="prefersInvoiceValueWithVat"
            @change="performInvoiceValueWithVatPreferenceChange">
            Afișează valoarea cu TVA pentru facturi
          </b-form-checkbox>
        </div>
        <div class="col-sm">
          <b-button @click="performLookupInternal(false)" :disabled="tableBusy"><i class="fas fa-sync"/><span class="d-none d-lg-inline ml-1">Reîncarcă</span></b-button>
          <div v-if="numPages > 1" class="mt-4">
            <b-pagination-nav :disabled="tableBusy" align="center" v-if="numPages > 1" :link-gen="paginationLinkGen" :number-of-pages="numPages" v-model="currentPage" use-router></b-pagination-nav>
          </div>
        </div>
        <div class="col-sm">
          <multiselect
            v-model="filters.statusCode.val"
            placeholder="Filtre stare"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="statusCodeFilterOptions"
            :allow-empty="true"
            :multiple="true"
            :custom-label="statusCodeFilterOptionsCustomLabel"
            @input="resetPageAndLookup"
            class="status-code-filter">
            <template
              slot="option"
              slot-scope="props"
            >
              <client-order-status-badge :status-code="props.option"/>
            </template><template
              slot="singleLabel"
              slot-scope="props"
            >
              <client-order-status-badge :status-code="props.option"/>
            </template>
            <template
              slot="tag"
              slot-scope="props">
              <client-order-status-badge :status-code="props.option" class="mr-1" />
            </template>
            <template
              slot="clear">
              <transition name="fade">
                <div class="multiselect__clear" v-if="filters.statusCode.val.length" @mousedown.prevent.stop="clearStatusCodeFilter()">
                  <i class="fas fa-times"/>
                </div>
              </transition>
            </template>
          </multiselect>
          <multiselect
            v-model="hasIssuedInvoicesFilterSelection"
            placeholder="Filtre facturat"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="hasIssuedInvoicesFilterOptions"
            :allow-empty="true"
            :multiple="false"
            :custom-label="hasIssuedInvoicesCustomLabel"
            @input="hasIssuedInvoicesInputHandler"
            class="has-issued-invoices-filter">
            <template
              slot="option"
              slot-scope="props"
            >
              <b-badge :variant="props.option.variant" class="text-uppercase">{{props.option.label}}</b-badge>
            </template><template
              slot="singleLabel"
              slot-scope="props"
            >
              <b-badge :variant="props.option.variant" class="text-uppercase">{{props.option.label}}</b-badge>
            </template>
            <template
              slot="tag"
              slot-scope="props">
              <b-badge :variant="props.option.variant" class="mr-1 text-uppercase">{{props.option.label}}</b-badge>
            </template>
            <template
              slot="clear">
              <transition name="fade">
                <div class="multiselect__clear" v-if="!!hasIssuedInvoicesFilterSelection" @mousedown.prevent.stop="clearHasIssuedInvoicesFilter()">
                  <i class="fas fa-times"/>
                </div>
              </transition>
            </template>
          </multiselect>

          <multiselect
            v-model="clientFilterSelection"
            track-by="clientCode"
            placeholder="Filtre client"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="clientFilterOptions"
            :loading="isClientLookupRunning"
            :allow-empty="true"
            :multiple="false"
            :hide-selected="false"
            :custom-label="clientCustomLabel"
            @search-change="asyncLookupClient"
            @input="clientFilterInputHandler"
            class="status-code-filter">
            <template
              slot="option"
              slot-scope="props"
            >
              <b-container>
                <div class="d-flex" align-v="center">
                  <span class="mt-1 mr-2">{{props.option.clientName}}</span>
                  <b-badge variant="secondary" class="badge-lg">
                    <i class="fas fa-briefcase mr-1" />
                    {{props.option.clientCode}}
                  </b-badge>
                </div>
              </b-container>
            </template>
            <template
              slot="noResult"
              slot-scope="props">
              {{ searchPlaceholder(props.search, 2) }}
            </template>
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <div class="d-flex" align-v="center">
                <span class="mr-2">{{props.option.clientName}}</span>
                <b-badge variant="secondary" class="badge-lg">
                  <i class="fas fa-briefcase mr-1" />
                  {{props.option.clientCode}}
                </b-badge>
              </div>
            </template>
            <template
              slot="clear">
              <transition name="fade">
                <div class="multiselect__clear" v-if="filters.client.val && filters.client.val.length" @mousedown.prevent.stop="clearClientFilter()">
                  <i class="fas fa-times"/>
                </div>
              </transition>
            </template>
          </multiselect>

          <b-form-select v-model="filters.createdByGuid.op" @change="createdByInputOpHandler">
            <b-form-select-option value="IN">agent egal cu</b-form-select-option>
            <b-form-select-option value="NOT IN">agent diferit de</b-form-select-option>
          </b-form-select>

          <multiselect
            id="created-by-guid-multiselect"
            :value="createdByFilterSelection"
            track-by="webAppUserGuid"
            placeholder="Filtru agent (selecție multiplă)"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="createdByFilterOptions"
            :multiple="true"
            :loading="isCreatedByLookupRunning"
            :allow-empty="true"
            :custom-label="createdByCustomLabel"
            @input="createdByInputHandler"
            @search-change="asyncLookUpCreatedBy"
            class="created-by-guid-filter">
            <template
              slot="option"
              slot-scope="props"
            >
              <user-name-badge :user-name="props.option.fullName" />
            </template>
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <user-name-badge :user-name="props.option.fullName" />
            </template>
            <template
              slot="tag"
              slot-scope="props">
              <user-name-badge :user-name="props.option.fullName" class="mr-1"/>
            </template>
            <template
              slot="clear">
              <transition name="fade">
                <div class="multiselect__clear" v-if="filters.createdByGuid.val && filters.createdByGuid.val.length" @mousedown.prevent.stop="clearCreatedByFilter()">
                  <i class="fas fa-times"/>
                </div>
              </transition>
            </template>
          </multiselect>
          <div class="container-fluid">
            <div class="row">
              <div class="col text-right">
                <b-form-checkbox
                  :disabled="xhrRequestRunning"
                  :checked="prefersClientOrdersListFiltersSaving"
                  @change="performClientOrdersListFiltersSavingPreferenceChange">
                  Salvează<span  class="d-none d-l-inline">&nbsp;filtrele</span>
                </b-form-checkbox>
              </div>
              <div class="col text-left">
                <b-button
                  variant="secondary"
                  :disabled="xhrRequestRunning"
                  @click="clearFilters">
                  <i class="fas fa-times"/>
                  <span class="d-none d-lg-inline ml-1">Curăță</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-table
      :show-empty="!tableBusy"
      small
      striped
      hover
      empty-text="Nu este înregistrată nicio comandă"
      :busy.sync="tableBusy"
      :items="items"
      :tbody-tr-class="rowClass"
      :fields="tableFields"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortDesc"
      :no-local-sorting="true">
      <template v-slot:cell(clientOrderCode)="row">
        {{row.value}}
        <span v-if="row.item.clientSystemOrderIdentifier">
          <br>
          <b-badge
           variant="secondary"
           class="badge-lg"
           v-b-tooltip.hover.bottom="'Cod comandă în sistemul clientului'">
           <i class="fas fa-hashtag" />
           {{row.item.clientSystemOrderIdentifier}}
          </b-badge>
        </span>
      </template>
      <template v-slot:cell(createdByFullName)="row">
        <user-name-badge :user-name="row.value" />
      </template>
      <template v-slot:cell(statusCode)="row">
        <client-order-status-badge :status-code="row.value" />
        <span class="text-uppercase ml-1" v-if="row.item.hasIssuedInvoices">
          <b-badge variant="success">
            <span>Facturată</span>
            <i class="fas fa-file-download pl-1" v-if="row.item.hasExportedInvoices" />
          </b-badge>
        </span>
        <span class="text-uppercase ml-1" v-if="row.item.hasIssuedDeliveryNotes">
          <b-badge variant="success">
            <span>Avizată</span>
            <i class="fas fa-file-download pl-1" v-if="row.item.hasExportedDeliveryNotes" />
          </b-badge>
        </span>
      </template>
      <template v-slot:cell(invoiceLinesValue)="row">
        <span v-if="prefersInvoiceValueWithVat">
          {{ row.item.invoiceLinesValue + row.item.invoiceLinesVatValue | displayAsDecimal(2) }}
        </span>
        <span v-else>
          {{ row.item.invoiceLinesValue | displayAsDecimal(2) }}
        </span>
      </template>
      <template v-slot:cell(clientName)="row">
        <span>{{row.value}}</span>
        <a href="#"
            class="ml-1"
            v-if="row.item.clientCode"
            @click="showClientDetails(row.item.clientCode)"
            v-b-tooltip.hover.bottom="'Detalii client'">
            <i class="fas fa-info-circle" />
        </a>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          :to="{name: 'clientOrderDetails', params: { clientOrderId: row.item.clientOrderId } }"
          v-if="row.item.statusCode === ClientOrderStatus.DRAFT"
          v-b-tooltip.hover title="Editează comanda">
         <i class="fas fa-edit" />
        </b-button>
        <b-button
          size="sm"
          :to="{name: 'clientOrderDetails', params: { clientOrderId: row.item.clientOrderId } }"
          v-if="row.item.statusCode !== ClientOrderStatus.DRAFT"
          v-b-tooltip.hover title="Vezi comanda">
         <i class="fas fa-eye" />
        </b-button>
      </template>
    </b-table>
    <b-pagination-nav :disabled="tableBusy" align="center" v-if="numPages > 1" :link-gen="paginationLinkGen" :number-of-pages="numPages" v-model="currentPage" use-router></b-pagination-nav>

    <b-modal
      id="order-excel-import-modal"
      title="Import comenzi din Excel"
      cancel-title="Renunță"
      @ok="handleExcelOrderImport"
      :no-close-on-backdrop="orderImport.running"
      :no-close-on-esc="orderImport.running"
      :hide-header-close="orderImport.running"
      :cancel-disabled="orderImport.running"
      :ok-disabled="orderImport.running">
      <b-overlay :show="orderImport.running" no-wrap>
        <template #overlay><b-spinner label="Lucreaza..."></b-spinner></template>
      </b-overlay>
      <b-form-group label="Client">
        <multiselect
          id="order-excel-client-code-multiselect"
          v-model="importOrderClientCodeSelection"
          track-by="clientCode"
          placeholder="Filtru client"
          selected-label="Selectat"
          select-label="Alege cu Enter"
          deselect-label="Elimină cu Enter"
          :options="importOrderClientFilterOptions"
          :multiple="false"
          :internal-search="false"
          :allow-empty="false"
          :loading="importOrderClientLookupRunning"
          @search-change="asyncImportOrderLookupClient"
          @input="importOrderClientInputHandler"
          class="multiselect-modal">
          <template
            slot="option"
            slot-scope="props"
          >
            <b-container>
              <div class="d-flex" align-v="center">
                <span class="mt-1 mr-2">{{props.option.clientName}}</span>
                <b-badge variant="secondary" class="badge-lg">
                  <i class="fas fa-briefcase mr-1" />
                  {{props.option.clientCode}}
                </b-badge>
              </div>
            </b-container>
          </template>
          <template
            slot="noResult"
            slot-scope="props">
            {{ searchPlaceholder(props.search, 2) }}
          </template>
          <template
            slot="singleLabel"
            slot-scope="props"
          >
            <div class="d-flex" align-v="center">
              <span class="mr-2">{{props.option.clientName}}</span>
              <b-badge variant="secondary" class="badge-lg">
                <i class="fas fa-briefcase mr-1" />
                {{props.option.clientCode}}
              </b-badge>
            </div>
          </template>
          <template
            slot="clear">
            <transition name="fade">
              <div class="multiselect__clear" v-if="filters.client.val && filters.client.val.length" @mousedown.prevent.stop="clearClientFilter()">
                <i class="fas fa-times"/>
              </div>
            </transition>
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="orderImport.splitStandardVatProductsInSeparateOrders"
          :value="true"
          :unchecked-value="false"
        >
          Separă produsele cu cota standard de cele cu alte cote de TVA
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Fisier import (.xlsx)">
        <b-form-file
          accept=".xlsx"
          v-model="orderImport.file"
          :state="Boolean(orderImport.file)"
          placeholder="Alege un fișier sau trage-l aici..."
          drop-placeholder="Trage fișierul aici..."
        />
      </b-form-group>
    </b-modal>

    <b-modal
      id="order-xml-import-modal"
      title="Import din XML DocProcess"
      cancel-title="Renunță"
      :ok-disabled="importFileBusy"
      :cancel-disabled="importFileBusy"
      :header-close-disabled="importFileBusy"
      @ok="handleDocProcessXmlOrderImport"
      @hide="handleDocProcessXmlModalClose">
      <b-overlay :show="importFileBusy" no-wrap>
        <template #overlay>&nbsp;</template>
      </b-overlay>
      <b-form-group label="Fisier import (.xml)">
        <b-form-file
          accept=".xml"
          v-model="orderImport.file"
          :state="Boolean(orderImport.file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        />
      </b-form-group>
    </b-modal>

  <client-details-modal :usage="'DETAILS'" :initialClientCode="modalInitialClientCode" ref="clientDetailsModal" :callback="clientCreateModalCallback"/>
  </b-container>
</template>

<style lang="scss">

div.multiselect.status-code-filter, div.multiselect.created-by-guid-filter, div.multiselect.has-issued-invoices-filter {
  .multiselect__content-wrapper {
    width: 100%;
  }

  .multiselect__clear {
    position: absolute;
    right: 26px;
    height: 24px;
    width: 24px;
    display: block;
    cursor: pointer;
    z-index: 2;
    padding: 4px 8px;
    color: #999;
  }
}

div.multiselect.created-by-guid-filter {
  .multiselect__single {
    padding-left: 0px;
  }

  .multiselect__tags {
    padding-left: 4px;
    padding-top: 1px;
  }
}

div.multiselect.has-issued-invoices-filter {
  .multiselect__single {
    padding-left: 4px;
  }

  .multiselect__tags {
    padding-left: 4px;
    padding-top: 1px;
  }
}

.mx-datepicker-sidebar {
  width: 130px;
}

.mx-datepicker-sidebar+.mx-datepicker-content {
  margin-left: 130px;
}

div.multiselect.status-code-filter {
  .multiselect__option {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
  }

  multiselect__tags {
    span.badge {
      padding-bottom: 0 !important;
    }
  }
}
</style>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import BigNumber from 'bignumber.js';
import { mapState, mapActions } from 'vuex';
import toasts from '@/services/toasts';

import UserNameBadge from '../components/UserNameBadge.vue';
import ClientOrderStatusBadge from '../components/ClientOrderStatusBadge.vue';
import ClientDetailsModal from '../components/ClientDetailsModal.vue';

import { WebAppRole, ClientOrderStatus } from '../constants';

const { DateTime } = require('luxon');
const qs = require('qs');
const _ = require('lodash');

function deserializeAndMergeFilters(currentFilters, ...serializedFilters) {
  // Cheap clone of our current filters hierarchy using JSON roundtrip
  const duplicatedFilters = JSON.parse(JSON.stringify(currentFilters));

  try {
    // Make one big array of all the serialized filters and then build a filters object out of it
    const deserialized = serializedFilters.reduce((acc, x) => [...acc, ...JSON.parse(x)], [])
      .reduce((acc, x) => ({
        [x.field]: {
          op: x.op,
          val: x.val,
        },
        ...acc,
      }), {});

    const allKeys = _.uniq([...Object.keys(duplicatedFilters), ...Object.keys(deserialized)]);

    // Replace all existing filters with data from the serialied filter arrays
    allKeys.forEach((key) => {
      if (typeof deserialized[key] !== 'object') {
        return;
      }

      // Add some code to deserialize some of the old "op" values
      if (typeof deserialized[key].op === 'string'
        && typeof deserialized[key].val !== 'undefined'
        && ['createdByGuid'].indexOf(key) !== -1) {
        let { op } = deserialized[key];
        if (op === '=') {
          op = 'IN';
        } else if (op === '<>') {
          op = 'NOT IN';
        }
        duplicatedFilters[key].op = op;
        duplicatedFilters[key].val = deserialized[key].val;
      } else {
        duplicatedFilters[key] = deserialized[key];
      }
    });

    return duplicatedFilters;
  } catch (ex) {
    return duplicatedFilters;
  }
}

export default {
  name: 'ClientOrder',
  components: {
    ClientOrderStatusBadge,
    UserNameBadge,
    ClientDetailsModal,
    DatePicker,
  },
  data() {
    // We only filter on the current user if the user's roles don't include
    // Administrator or Invoice Issuer or Storekeeper
    let createdByGuidVal = [];
    let createdByFilterInitialSelection = [];
    if (this.$store.state.userRoles.findIndex((r) => r === WebAppRole.Administrator
      || r === WebAppRole.InvoiceIssuer
      || r === WebAppRole.Storekeeper) === -1) {
      createdByGuidVal = [this.$store.state.userGuid];

      createdByFilterInitialSelection = [{
        webAppUserGuid: this.$store.state.userGuid,
        username: this.$store.state.username,
        fullName: this.$store.state.fullName,
      }];
    }

    return {
      currentPage: 1,
      numItems: 0,
      numPages: 1,
      perPage: 20,
      orderBy: 'createdAt',
      sortDesc: true,
      queryTerm: null,
      modalInitialClientCode: null,
      importOrderClientFilterOptions: [],
      importOrderClientCodeSelection: null,
      importFileBusy: false,
      ClientOrderStatus,
      dateRangeFilter: [
        DateTime.fromJSDate(this.getFirstDayOfGivenMonthsAgo(1)).toISODate(),
        DateTime.now().toISODate(),
      ],
      datePickerShortcuts: [
        {
          text: 'Ultimele 2 luni',
          onClick: () => [
            this.getFirstDayOfGivenMonthsAgo(1),
            new Date(),
          ],
        },
        {
          text: 'Ultimele 3 luni',
          onClick: () => [
            this.getFirstDayOfGivenMonthsAgo(2),
            new Date(),
          ],
        },
        {
          text: 'Doar luna curenta',
          onClick: () => [
            this.getFirstDayOfGivenMonthsAgo(0),
            new Date(),
          ],
        },
        {
          text: 'Anul curent',
          onClick: () => [
            new Date(new Date().getFullYear(), 0, 1),
            new Date(),
          ],
        },
        {
          text: 'Ultimii 2 ani',
          onClick: () => [
            new Date(new Date().getFullYear() - 1, 0, 1),
            new Date(),
          ],
        },
        {
          text: 'Ultimii 10 ani',
          onClick: () => [
            new Date(new Date().getFullYear() - 9, 0, 1),
            new Date(),
          ],
        },
      ],
      defaultFilters: {
        statusCode: {
          val: [],
          op: 'IN',
        },
        client: {
          val: null,
          op: '=',
        },
        createdByGuid: {
          val: createdByGuidVal,
          op: 'IN',
        },
        hasIssuedInvoices: {
          val: null,
          op: '=',
        },
        lowerBoundDate: {
          val: DateTime.fromJSDate(this.getFirstDayOfGivenMonthsAgo(1)).toISODate(),
          op: '>=',
        },
        upperBoundDate: {
          val: DateTime.now().toISODate(),
          op: '<=',
        },
      },
      filters: {
        statusCode: {
          val: [],
          op: 'IN',
        },
        client: {
          val: null,
          op: '=',
        },
        createdByGuid: {
          val: createdByGuidVal,
          op: 'IN',
        },
        hasIssuedInvoices: {
          val: null,
          op: '=',
        },
        lowerBoundDate: {
          val: DateTime.fromJSDate(this.getFirstDayOfGivenMonthsAgo(1)).toISODate(),
          op: '>=',
        },
        upperBoundDate: {
          val: DateTime.now().toISODate(),
          op: '<=',
        },
      },
      tableFields: [
        {
          key: 'clientOrderCode',
          label: 'Cod comandă',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Creat',
          formatter(val) {
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
          sortable: true,
          orderByDirection: 'desc',
        },
        {
          key: 'statusCode',
          label: 'Stare',
        },
        {
          key: 'orderLinesValue',
          label: 'Val. produse',
        },
        {
          key: 'invoiceLinesValue',
          label: 'Val. facturi',
        },
        {
          key: 'clientName',
          label: 'Client',
          sortable: true,
        },
        {
          key: 'clientDeliveryAddressName',
          label: 'Adr. livrare',
          sortable: true,
        },
        {
          key: 'createdByFullName',
          label: 'Creat de',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      items: [],
      tableBusy: false,
      statusCodeFilterOptions: [
        ClientOrderStatus.DRAFT,
        ClientOrderStatus.SUBMITTED,
        ClientOrderStatus.PREPARING,
        ClientOrderStatus.PREPARED,
        ClientOrderStatus.COMPLETED,
        ClientOrderStatus.CANCELED,
      ],
      hasIssuedInvoicesFilterOptions: [
        {
          label: 'Facturată',
          variant: 'success',
          value: true,
        },
        {
          label: 'Nefacturată',
          variant: 'secondary',
          value: false,
        },
      ],

      clientFilterOptions: [],
      clientFilterSelection: null,
      isClientLookupRunning: false,

      // hasIssuedInvoicesFilterSelection: null,
      createdByFilterOptions: createdByFilterInitialSelection,
      createdByFilterSelection: createdByFilterInitialSelection,
      isCreatedByLookupRunning: false,
      importOrderClientLookupRunning: false,
      orderImport: {
        clientCode: null,
        splitStandardVatProductsInSeparateOrders: false,
        file: null,
        running: false,
      },
      performingLookup: false,
    };
  },
  watch: {
    orderBy() {
      this.resetPageAndLookup();
    },
    sortDesc() {
      this.resetPageAndLookup();
    },
  },
  computed: {
    itemsAvailable() { return this.items.length; },
    hasIssuedInvoicesFilterSelection: {
      get() {
        return this.filters.hasIssuedInvoices.val === null
          ? null
          : this.hasIssuedInvoicesFilterOptions.find((x) => x.value === !!this.filters.hasIssuedInvoices.val);
      },
      set(val) {
        if (val === null) {
          this.filters.hasIssuedInvoices.val = null;
        } else {
          this.filters.hasIssuedInvoices.val = (val.value ? 1 : 0);
        }
      },
    },
    ...mapState(['xhrRequestRunning', 'userGuid', 'prefersInvoiceValueWithVat', 'prefersClientOrdersListFiltersSaving', 'clientOrdersListFilters']),
  },
  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.currentPage = to.query.page || 1;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.perPage = to.query.perPage || 20;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.orderBy = to.query.orderBy || 'createdAt';
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.sortDesc = !to.query.orderByDirection || to.query.orderByDirection === 'd';
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.queryTerm = to.query.queryTerm || null;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.filters = deserializeAndMergeFilters(
        vm.defaultFilters,
        // The received filters override any saved filters
        to.query.filters || vm.clientOrdersListFilters || '',
      );
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.dateRangeFilter = vm.populateDateRangeFilter(vm.dateRangeFilter, vm.filters);
      // We don't want to save the filters as we've just loaded some
      vm.performLookupInternal(false);
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentPage = to.query.page || 1;
    this.perPage = to.query.perPage || this.perPage || 20;
    this.orderBy = to.query.orderBy || 'createdAt';
    this.sortDesc = !to.query.orderByDirection || to.query.orderByDirection === 'd';
    this.queryTerm = to.query.queryTerm || null;
    this.filters = deserializeAndMergeFilters(
      this.defaultFilters,
      to.query.filters || '',
    );
    this.dateRangeFilter = this.populateDateRangeFilter(this.dateRangeFilter, this.filters);
    try {
      await this.performLookupInternal();
    } finally {
      next();
    }
  },
  created() {
    this.asyncLookUpCreatedBy = _.debounce(this.asyncLookUpCreatedBy, 450);
    this.asyncLookupClient = _.debounce(this.asyncLookupClient, 450);
  },
  methods: {
    searchPlaceholder(value, minChars) {
      return value && value.length < minChars ? 'Introdu măcar 2 caractere' : 'N-am găsit niciun rezultat';
    },

    statusCodeFilterOptionsCustomLabel(label) {
      if (label === ClientOrderStatus.DRAFT) return 'ciornă';
      if (label === ClientOrderStatus.SUBMITTED) return 'trimisă';
      if (label === ClientOrderStatus.PREPARING) return 'în pregătire';
      if (label === ClientOrderStatus.PREPARED) return 'pregătită';
      if (label === ClientOrderStatus.COMPLETED) return 'finalizată';
      if (label === ClientOrderStatus.CANCELED) return 'anulată';
      return label;
    },

    clientCustomLabel(label) {
      if (label && label.clientCode) {
        return `${label.clientName} - ${label.clientCode}`;
      }

      return null;
    },

    hasIssuedInvoicesCustomLabel(label) {
      if (label && label.label) {
        return label.label;
      }

      return null;
    },

    clientCreateModalCallback() {
      this.$refs.clientDetailsModal.hideModal();
    },

    populateDateRangeFilter(dateRangeFilter, filters) {
      if ((filters.lowerBoundDate.val && filters.upperBoundDate.val)) {
        return [
          filters.lowerBoundDate.val,
          filters.upperBoundDate.val,
        ];
      }
      return dateRangeFilter;
    },

    showClientDetails(clientCode) {
      this.modalInitialClientCode = clientCode;
      this.$nextTick(() => this.$refs.clientDetailsModal.showModal());
    },

    createdByCustomLabel(label) {
      if (label && label.username) {
        return `${label.username} - ${label.fullName}`;
      }

      return null;
    },

    ...mapActions([
      'performClientOrdersQuery',
      'performClientLookup',
      'performUsersListLookup',
      'performInvoiceValueWithVatPreferenceChange',
      'performClientOrdersListFiltersSavingStorePreferenceChange',
      'performClientOrdersListFiltersChange',
      'performExcelOrderImport',
      'performDocProcessXmlOrderImport',
      'performAgentVanzariQuery',
    ]),

    performClientOrdersListFiltersSavingPreferenceChange(isSaveChecked) {
      this.performClientOrdersListFiltersSavingStorePreferenceChange(!!isSaveChecked);

      if (isSaveChecked) {
        this.performClientOrdersListFiltersChange(this.serializeFilters());
      }
    },

    onDateRangeInput(newInput) {
      [this.filters.lowerBoundDate.val, this.filters.upperBoundDate.val] = newInput;
      this.resetPageAndLookup();
    },

    showExcelImportModal() {
      this.$nextTick(() => {
        this.orderImport = {
          clientCode: null,
          splitStandardVatProductsInSeparateOrders: false,
          file: null,
          running: false,
        };
        this.importOrderClientCodeSelection = null;
        this.$bvModal.show('order-excel-import-modal');
      });
    },

    showXmlImportModal() {
      this.$nextTick(() => {
        this.orderImport = {
          file: null,
          running: false,
        };
        this.$bvModal.show('order-xml-import-modal');
      });
    },

    async handleExcelOrderImport(evt) {
      evt.preventDefault();

      if (!this.orderImport.clientCode || !this.orderImport.file) {
        toasts.info('Alege un client si un fisier de import');
        return;
      }

      try {
        this.orderImport.running = true;
        const result = await this.performExcelOrderImport(this.orderImport);
        console.log(result);

        toasts.success('Comenzile au fost importate');
      } catch (error) {
        toasts.error(`${error}`);

        if (error.response && error.response.data) {
          this.$nextTick(() => this.$bvModal.msgBoxOk(error.response.data));
        }
      } finally {
        this.orderImport.running = false;
        this.$nextTick(() => this.$bvModal.hide('order-excel-import-modal'));
        this.$nextTick(() => this.performLookupInternal().catch(() => {
          toasts.error('Lista de comenzi nu a putut fi actualizată');
        }));
      }
    },

    async handleDocProcessXmlOrderImport() {
      if (!this.orderImport.file) {
        toasts.error('Nu ai ales încă un fișier');
        return;
      }

      if (this.importFileBusy) {
        toasts.error('Cererea este deja în curs de executare');
        return;
      }

      this.importFileBusy = true;
      const response = await this.performDocProcessXmlOrderImport(this.orderImport);
      this.importFileBusy = false;

      if (response) {
        toasts.success('Comanda a fost importată cu succes');
        this.$nextTick(() => this.$bvModal.hide('order-xml-import-modal'));
        this.$router.push({
          name: 'clientOrderDetails',
          params: {
            clientOrderId: response,
          },
        });
      } else {
        toasts.error('Eroare la importarea comenzii');
      }
    },

    handleDocProcessXmlModalClose(event) {
      if (this.importFileBusy) {
        event.preventDefault();
      }
    },

    async asyncUpdateCreatedByFilterOptions(createdByGuids) {
      this.createdByFilterOptions = await this.performAgentVanzariQuery(createdByGuids);
    },

    async asyncLookUpCreatedBy(query) {
      this.isCreatedByLookupRunning = true;

      this.createdByFilterOptions = await this.performUsersListLookup(query);

      this.isCreatedByLookupRunning = false;
    },

    async asyncImportOrderLookupClient(query) {
      if (query.length < 2) {
        return;
      }
      this.importOrderClientLookupRunning = true;

      this.importOrderClientFilterOptions = await this.performClientLookup({ lookupTerm: query });

      this.importOrderClientLookupRunning = false;
    },

    async asyncUpdateClientFilterOptions(query) {
      if (query.length >= 2) {
        this.clientFilterOptions = await this.performClientLookup({ lookupTerm: query });
      }
    },

    async asyncLookupClient(query) {
      try {
        this.isClientLookupRunning = true;
        await this.asyncUpdateClientFilterOptions(query);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        this.isClientLookupRunning = false;
      }
    },

    clearClientFilter() {
      this.clientFilterInputHandler(null);
    },

    clientFilterInputHandler(value) {
      // Cancel any further lookups as we have selected something already
      this.asyncLookupClient.cancel();

      this.clientFilterSelection = value;
      this.filters.client.val = value && value.clientCode ? value.clientCode : null;

      if (!this.performingLookup) {
        this.resetPageAndLookup();
      }
    },

    importOrderClientInputHandler(value) {
      this.orderImport.clientCode = value.clientCode;
    },

    serializeFilters() {
      return JSON.stringify(Object.keys(this.filters).map((key) => ({
        field: key,
        op: this.filters[key].op,
        val: this.filters[key].val,
      })).filter((filter) => typeof filter.val !== 'undefined' && filter.val !== null && (!Array.isArray(filter.val) || filter.val.length > 0)));
    },

    buildQueryObject(page) {
      return {
        page,
        perPage: this.perPage,
        orderBy: this.orderBy,
        orderByDirection: this.sortDesc ? 'd' : 'a',
        queryTerm: this.queryTerm ? this.queryTerm : undefined,
        filters: this.serializeFilters(),
      };
    },

    paginationLinkGen(page) {
      return {
        name: 'clientOrder',
        query: this.buildQueryObject(page),
      };
    },

    rowClass(/* item, type */) {
    },

    clearFilters() {
      this.filters = JSON.parse(JSON.stringify(this.defaultFilters));
      if (this.prefersClientOrdersListFiltersSaving) {
        this.performClientOrdersListFiltersChange(this.serializeFilters());
      }
      this.resetPageAndLookup();
    },

    clearHasIssuedInvoicesFilter() {
      this.hasIssuedInvoicesInputHandler(null);
    },

    clearCreatedByFilter() {
      this.createdByInputHandler(null);
    },

    clearStatusCodeFilter() {
      this.filters.statusCode.val = [];
      this.resetPageAndLookup();
    },

    clearQueryTerm() {
      this.queryTerm = null;
      this.resetPageAndLookup();
    },

    hasIssuedInvoicesInputHandler(value) {
      // This is a computed that sets the undelying filter
      this.hasIssuedInvoicesFilterSelection = value;
      this.resetPageAndLookup();
    },

    createdByInputOpHandler() {
      // We only do a reset if there is a value selected for the "created by" filter
      if (this.filters.createdByGuid.val && this.filters.createdByGuid.val.length) {
        this.resetPageAndLookup();
      }
    },

    getFirstDayOfGivenMonthsAgo(monthsAgo) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - monthsAgo);

      return date;
    },

    createdByInputHandler(value) {
      // Cancel any further lookups as we have selected something already
      this.asyncLookUpCreatedBy.cancel();

      this.createdByFilterSelection = value;
      const isArray = Array.isArray(value);
      let sanitizedValue = value;
      if (!isArray) {
        sanitizedValue = (sanitizedValue && sanitizedValue.webAppUserGuid)
          ? [sanitizedValue.webAppUserGuid]
          : [];
      }
      this.filters.createdByGuid.val = (sanitizedValue || []).map((v) => v.webAppUserGuid);

      if (!this.performingLookup) {
        this.resetPageAndLookup();
      }
    },

    resetPageAndLookup() {
      this.currentPage = 1;

      this.performLookup();
    },

    async performLookup() {
      try {
        this.$router.push(this.paginationLinkGen(this.page));
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    },

    async handleExcelOrdersExport() {
      const serializedFilters = this.serializeFilters();

      // We also need to make sure that we can populate the "created by" filter if we aleady have a value
      // and it's not within the values that we have cached
      if (this.filters.createdByGuid.val) {
        if (!Array.isArray(this.filters.createdByGuid.val)) {
          this.filters.createdByGuid.val = [this.filters.createdByGuid.val];
        }

        // If at least one of the filter GUIDs is not present in the options...
        if (this.filters.createdByGuid.val.find((x) => !this.createdByFilterOptions.find((o) => o.webAppUserGuid === x))) {
          // ...refresh the options...
          await this.asyncUpdateCreatedByFilterOptions(this.filters.createdByGuid.val);
          // ...and update the selection
          this.createdByFilterSelection = this.createdByFilterOptions.filter((x) => this.filters.createdByGuid.val.find((o) => o === x.webAppUserGuid));
        }
      } else {
        this.createdByFilterSelection = [];
      }

      // We also need to make sure that we can populate the "client" filter if we aleady have a value
      // and it's not within the values that we have cached
      if (this.filters.client.val) {
        if (!this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val)) {
          await this.asyncUpdateClientFilterOptions(this.filters.client.val);
          this.clientFilterSelection = this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val);
        }
      } else {
        this.clientFilterSelection = null;
      }

      const params = {
        orderBy: this.orderBy,
        orderByDirection: this.sortDesc ? 'd' : 'a',
        queryTerm: this.queryTerm ? this.queryTerm : undefined,
        filters: serializedFilters,
      };

      window.location.href = `/api/client-order/excel-export/list?${qs.stringify(params)}`;
    },

    async performLookupInternal(saveFilters = true) {
      this.tableBusy = true;
      this.performingLookup = true;

      const serializedFilters = this.serializeFilters();

      // We also need to make sure that we can populate the "created by" filter if we aleady have a value
      // and it's not within the values that we have cached
      if (this.filters.createdByGuid.val) {
        if (!Array.isArray(this.filters.createdByGuid.val)) {
          this.filters.createdByGuid.val = [this.filters.createdByGuid.val];
        }

        // If at least one of the filter GUIDs is not present in the options...
        if (this.filters.createdByGuid.val.find((x) => !this.createdByFilterOptions.find((o) => o.webAppUserGuid === x))) {
          // ...refresh the options...
          await this.asyncUpdateCreatedByFilterOptions(this.filters.createdByGuid.val);
          // ...and update the selection
          this.createdByFilterSelection = this.createdByFilterOptions.filter((x) => this.filters.createdByGuid.val.find((o) => o === x.webAppUserGuid));
        }
      } else {
        this.createdByFilterSelection = [];
      }

      // We also need to make sure that we can populate the "client" filter if we aleady have a value
      // and it's not within the values that we have cached
      if (this.filters.client.val) {
        if (!this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val)) {
          await this.asyncUpdateClientFilterOptions(this.filters.client.val);
          this.clientFilterSelection = this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val);
        }
      } else {
        this.clientFilterSelection = null;
      }

      let result;
      try {
        result = await this.performClientOrdersQuery({
          page: this.currentPage,
          perPage: this.perPage,
          orderBy: this.orderBy,
          orderByDirection: this.sortDesc ? 'd' : 'a',
          queryTerm: this.queryTerm ? this.queryTerm : undefined,
          filters: serializedFilters,
        });

        if (this.prefersClientOrdersListFiltersSaving && saveFilters) {
          this.performClientOrdersListFiltersChange(serializedFilters);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        result = {
          items: [],
          count: 0,
          limit: this.perPage,
          pages: 0,
        };
      }

      this.items = result.items;
      this.numItems = result.count;
      this.perPage = result.limit;
      // Avoid a validation error on the number of pages if we get back zero pages (no results)
      this.numPages = result.pages || 1;

      this.tableBusy = false;
      this.performingLookup = false;
    },
  },
};
</script>
